import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { useAmountSchema } from '@trader/hooks';

export const useClosePositionFormValidation = (maxValue?: number) => {
  const { currentLng } = useI18next();

  const { amountSchema } = useAmountSchema(maxValue);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      amount: amountSchema,
    });
  }, [currentLng]);

  return { validationSchema };
};
