import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Icon, Select } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import {
  EChallenge,
  IChangeFieldEvent,
  IOption,
  TOptions,
} from '@trader/types';

import * as Styled from './styled';

export const ConnectMaster: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const [selectedOption, setSelectedOption] = useState<IOption>({
    title: '',
    value: '',
  });

  const slaveId = store.ui.modal.options.get('slaveId');
  const slaveAmount = store.ui.modal.options.get('selectedSlaveAmount');

  const masterChallenges = store.pages.copier.getChallengesByType(
    EChallenge.Master
  );

  const options: Array<IOption> = masterChallenges
    ?.filter(ch => ch.challengeAmount === slaveAmount)
    ?.map(challenge => ({
      title: `ID: ${challenge.id} - ${store.user.portfolio.currencySymbol}${challenge.challengeAmount}`,
      value: challenge.id.toString(),
      id: challenge.id.toString(),
    }));

  const handleChange = (event: IChangeFieldEvent<TOptions>) => {
    const [option] = event.target.value;
    setSelectedOption(option);
  };

  return (
    <Styled.Root>
      <Styled.Title>
        <Icon iconType='accountTypeSlave' />
        <Icon iconType='longRight' />
        <Icon iconType='accountTypeMaster' />
      </Styled.Title>
      <Styled.Content>{translate('COPIER.CHOOSE_MASTER_ACC')}</Styled.Content>
      <Select
        name='masterAccount'
        fullWidth
        label={translate('COPIER.SELECT_MASTER_ACC')}
        value={[selectedOption]}
        options={options}
        onChange={handleChange}
      />
      <Styled.Body>
        <Styled.Cta
          size='medium'
          $isFailed
          onClick={() => store.ui.modal.close()}
        >
          {translate('COPIER.CANCEL')}
        </Styled.Cta>
        <Styled.Cta
          size='medium'
          disabled={!selectedOption.value}
          onClick={() =>
            store.pages.copier.assignSlaveAsync.run({
              slaveId: Number(slaveId),
              masterId: Number(selectedOption.id),
            })
          }
        >
          {translate('COPIER.CONNECT')}
        </Styled.Cta>
      </Styled.Body>
    </Styled.Root>
  );
});
