import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from '@trader/components';
import { useMst } from '@trader/store';

import * as Styled from '../../styled';

export const OrderSection: FC<{ openedSectionsCount: number }> = observer(
  ({ openedSectionsCount }) => {
    const store = useMst();

    if (!store.ui.sidebar.rightBar.orderSection.isOpen) {
      return null;
    }

    return (
      <Styled.Section
        $openedSectionsCount={openedSectionsCount}
        $withBottomSpacing
      >
        <Styled.CloseIcon>
          <IconButton
            iconType='remove'
            onClick={() => store.ui.sidebar.closeRightBarOrderSection()}
          />
        </Styled.CloseIcon>
        Order Section will be here.
      </Styled.Section>
    );
  }
);
