export enum EGeneralTabContainerId {
  Alerts = 'alerts',
  Assets = 'assets',
  DataBox = 'dataBox',
  OrdersAndPositions = 'ordersAndPositions',
}

export enum EGeneralTabContainerType {
  Floating,
  FullChart,
}

export enum EIndicators {
  MACD = 'MACD',
  VWAP_DIFF = 'VWAP_DIFF',
  VWAP_PRESSER = 'VWAP_PRESSER',
}

export enum EBandType {
  lower,
  upper,
}

export enum EMultiplier {
  base,
  us,
}

export enum EOrderSide {
  Buy,
  Sell,
}

export enum EStripName {
  Strip1Low,
  Strip2Low,
  Strip3Low,
  Strip4Low,
  Strip5Low,
  Strip0HalfLow,
  Strip025Low,
  Strip075Low,
  Strip1HalfLow,
  Strip2HalfLow,
  Strip3HalfLow,
  Strip4HalfLow,
  Strip1High,
  Strip2High,
  Strip3High,
  Strip4High,
  Strip5High,
  Strip0HalfHigh,
  Strip025High,
  Strip075High,
  Strip1HalfHigh,
  Strip2HalfHigh,
  Strip3HalfHigh,
  Strip4HalfHigh,
  RoundedVwap,
}
