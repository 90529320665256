import { defaultCategories } from '@trader/constants';
import { devLoggerService } from '@trader/services';
import { api } from '@trader/api';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { categoriesSchema } from './schemas';
import { TCategoryEntity } from '@trader/store';

export const getCategoriesAsync = createThunk<void, string>(
  () =>
    async function getCategories(this: unknown, _options, _flow) {
      const root = getRootInstance();
      const response = await api.Category.getCategories(_options);
      try {
        root.entities.normalizeMerge(
          [...defaultCategories, ...(response || [])],
          categoriesSchema,
          true
        );

        const selectedCategory = root.pages.trading.selectedCategory;
        const newCategory =
          root.entities.categories.getAll<TCategoryEntity>()[2];

        root.pages.trading.runInAction(() => {
          root.pages.trading.selectedCategory = selectedCategory || newCategory;
        });
      } catch (e) {
        devLoggerService.error('Error in getCategoriesAsync', e);
      }
      return response.length ? response[0]?.name : defaultCategories[0].name; // preselectedCategoryId
    }
);
