import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { Controller, TradingIsClosed, Wrapper } from '@trader/components';
import { calculateSpread, formatByPipSize } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { defaultIcon, small } from '@trader/constants';
import { useI18next } from '@trader/services';

import { BuyButton } from '../../../components/buyButton';
import { PlaceOrderButton } from '../PlaceOrderButton';
import { PurchaseDetails } from '../PurchaseDetails';
import { CostAndCharges } from '../CostAndCharges';
import { Protection } from '../Protection';

import * as Styled from './styled';

export const MarketOrder: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { control, register, formState } = useFormContext();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;
  const freeMargin = store.user.portfolio.freeMargin;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const spread = calculateSpread({
    ask: Number(instrument.ask),
    bid: Number(instrument.bid),
    pipSize: instrument.pipSize,
  });

  if (!instrument.isOpenTrading) {
    return (
      <TradingIsClosed
        title={translate('COMMON.LABELS.MARKETS_CLOSED')}
        iconTypeName='warning'
        content={`${translate('COMMON.LABELS.MARKETS_ARE_CURRENTLY_CLOSED')} ${
          !isInvestmentAccProduct
            ? translate('COMMON.LABELS.YOU_CAN_PLACE_A_LIMIT_STOP_ORDER')
            : ''
        }`}
      />
    );
  }

  return (
    <React.Fragment>
      {isInvestmentAccProduct && (
        <Styled.DmaHeader>
          <Wrapper>
            <img
              src={instrument.iconUrl || defaultIcon}
              alt='instrument icon'
              width={small}
              height={small}
            />
            <Styled.Info>{translate('COMMON.LABELS.BUY_PRICE')}</Styled.Info>
          </Wrapper>
          <Styled.Price>
            {instrument.currencySymbol}
            {formatByPipSize(Number(instrument.ask), instrument.pipSize)}
          </Styled.Price>
        </Styled.DmaHeader>
      )}
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && (
            <Styled.ActionButtons>
              <BuyButton isAsk side='Sell' />
              <Styled.Spread>{spread}</Styled.Spread>
              <BuyButton side='Buy' />
            </Styled.ActionButtons>
          )}
          <Styled.Main>
            <Controller
              type='orderInput'
              shouldCheckActivityOfButtons
              placeholder={translate('COMMON.LABELS.AMOUNT')}
              disabled={!instrument.isOpenTrading}
              minValue={instrument.minOrderSizeIncrement}
              maxValue={instrument.maxOrderSize}
              fixDigitAfterDot={trading.amountDigitsAfterDot()}
              step={instrument.minOrderSizeIncrement}
              control={control}
              {...register('amount')}
            />
          </Styled.Main>
          <PurchaseDetails />
        </Styled.Root>
        <Protection />
        {isInvestmentAccProduct && (
          <Styled.FreMargin>
            {translate('COMMON.LABELS.FREE_BALANCE')}:{' '}
            {instrument.currencySymbol}
            {freeMargin}
          </Styled.FreMargin>
        )}
        <PlaceOrderButton
          orderType='Market'
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
        />
        {import.meta.env.VITE_INSTRUMENT_HAS_COST_AND_CHARGES && (
          <CostAndCharges />
        )}
      </React.Fragment>
    </React.Fragment>
  );
});
