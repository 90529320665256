import { useEffect } from 'react';
import i18next from 'i18next';

import { useMst } from '@trader/store';
import {
  getAccountTypeForConnection,
  MODAL_TYPES,
  productId,
} from '@trader/constants';

import { useHub } from './core/useHub';
import {
  EConnectionHub,
  EConnectionHubSubscriber,
  ESocketUpdateAction,
} from './core/types';
import { EOrderSide, TPlaceOrderSide } from '@trader/types';

interface IMessage {
  id: number;
  symbol: string;
  comment: string;
  brand: number;
  platformLogin: number;
  openPrice: number;
  side: number;
  price: number;
  profit: number;
  stopLoss: number;
  takeProfit: number;
  updateAction: ESocketUpdateAction;
  volume: number;
}

/**
 * Custom hook that manages position updates by subscribing to a hub and handling incoming messages to update the store.
 */
export const usePositions = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const getStaticModalOptions = (message: IMessage) => {
    const billType: TPlaceOrderSide =
      EOrderSide.Buy === message.side ? 'Buy' : 'Sell';

    return {
      orderId: message.id,
      symbol: message.symbol,
      price: message.openPrice,
      billType,
    };
  };

  const handlePositionPlaced = (message: IMessage) => {
    const isPositionPlaced = store.ui.modal.options.get('isPositionPlaced');

    if (isPositionPlaced) {
      store.ui.modal.open(MODAL_TYPES.orderResponse, {
        ...getStaticModalOptions(message),
        volume: message.volume,
        isClose: false,
      });
    }
    store.entities.positionsMetrics.getPositionsMetricsAsync.run();
    store.user.getPortfolioDemoAsync.run();
  };

  const handlePositionClosed = (message: IMessage) => {
    const positionId = store.ui.modal.options.get('positionId');
    const isPositionClosed = store.ui.modal.options.get('isPositionClosed');

    if (Number(positionId) === Number(message.id) && isPositionClosed) {
      store.ui.modal.open(MODAL_TYPES.orderResponse, {
        ...getStaticModalOptions(message),
        volume: message.volume,
        isClose: true,
      });
    }
    store.entities.positionsMetrics.delete(message.id);
    store.user.getPortfolioDemoAsync.run();
  };

  const handlePositionPartiallyClosed = (message: IMessage) => {
    const positionId = store.ui.modal.options.get('positionId');
    const isPartiallyClosed = store.ui.modal.options.get('isPartiallyClosed');

    if (Number(positionId) === Number(message.id) && isPartiallyClosed) {
      store.ui.modal.open(MODAL_TYPES.orderResponse, {
        ...getStaticModalOptions(message),
        volume: +store.ui.modal.options.get('quantityToClose'),
        isClose: true,
      });
      store.user.getPortfolioDemoAsync.run();
    }
  };

  const handlePositionUpdated = (message: IMessage) => {
    store.entities.positionsMetrics.update(message.id, {
      quantity: message.volume,
      takeProfit: message.takeProfit
        ? { type: 'TakeProfit', limitPrice: message.takeProfit }
        : { type: 'TakeProfit', limitPrice: 0 },
      stopLoss: message.stopLoss
        ? { type: 'StopLoss', stopPrice: message.stopLoss }
        : { type: 'StopLoss', stopPrice: 0 },
    });
  };

  const handlePositionProtectionAdded = (message: IMessage) => {
    const positionId = store.ui.modal.options.get('positionId');
    const isProtectionAdded = store.ui.modal.options.get('isProtectionAdded');

    if (Number(positionId) === Number(message.id) && isProtectionAdded) {
      store.ui.modal.close();
      store.ui.modal.update({});
      store.notifications.add({
        message: i18next.t('NOTIFICATIONS.POSITION_HAS_BEEN_EDITED'),
        options: {
          variant: 'success',
        },
      });
    }
  };

  const handleUpdate = (message: IMessage) => {
    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        handlePositionPlaced(message);
        break;
      case ESocketUpdateAction.Delete:
        handlePositionClosed(message);
        break;
      case ESocketUpdateAction.Update:
        handlePositionUpdated(message);
        handlePositionPartiallyClosed(message);
        handlePositionProtectionAdded(message);
        break;
      default:
        break;
    }
  };

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/v2/account',
    hub: EConnectionHub.Account,
    subscriber: EConnectionHubSubscriber.Positions,
    onMessage: handleUpdate,
    invokedName: 'position',
  });

  useEffect(() => {
    if (platformLogin && accountType && product) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnPositions',
          platformLogin || null,
          productId[product],
          getAccountTypeForConnection[accountType]
        );
      });
    }
    return () => {
      if (platformLogin && accountType && product) {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromPositions',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken]);
};
