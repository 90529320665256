import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import {
  layoutChildrenRadius,
  layoutMargin,
  rightBarWidth,
} from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    height: inherit;
    width: ${rightBarWidth}px;
    margin-left: ${layoutMargin}px;
    z-index: 1;
    position: relative;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
  `}
`;

const hundred = 100;
export const Section = styled(Wrapper)<{
  $openedSectionsCount: number;
  $withBottomSpacing?: boolean;
}>`
  ${({ theme, $openedSectionsCount, $withBottomSpacing }) => css`
    position: relative;
    width: ${rightBarWidth}px;
    height: ${hundred / $openedSectionsCount}%;
    margin-bottom: ${$withBottomSpacing ? layoutMargin : 0}px;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
  `}
`;

export const CloseIcon = styled(Wrapper)`
  ${() => css`
    position: absolute;
    right: 0;

    svg {
      width: 12px;
      height: 12px;
    }
  `}
`;
