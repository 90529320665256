import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledCompThemeProvider } from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';

import { EThemes, getTheme, GlobalStyles, IThemeOptions } from '@trader/themes';
import { NotificationContainer } from '@trader/containers';
import { rootStore, RootStoreProvider, useMst } from '@trader/store';
import {
  EConditionType,
  useAuth,
  useAuthTokensAutoUpdate,
  useBeforeUnload,
  useBusinessLogic,
  useCustomerAccount,
  useInAppNotifications,
  usePendingOrders,
  usePortfolio,
  usePositions,
  useTradeResults,
  useTradingAccounts,
  useAutoLogoutWithNoActivity,
  useAppVersion,
  useInternetStatus,
  useInstrumentsTradingUpdater,
  usePositionsExchangeRate,
  usePositionsCurrentPrice,
} from '@trader/hooks';
import { ICustomRouteObject, RenderRoutes } from '@trader/navigation';
import { disableConsoleErrors, setMobileHeight } from '@trader/utils';
import { initSentry, SmartLookService } from '@trader/services';

interface IProps {
  darkTheme: IThemeOptions;
  lightTheme: IThemeOptions;
  routes: ICustomRouteObject[];
  hasSmartLook?: boolean;
}

const AppContainer = observer(
  ({ darkTheme, lightTheme, routes, hasSmartLook }: IProps) => {
    const { applyCondition } = useBusinessLogic(EConditionType.ScoreLevel);

    /* Socket subscriptions */
    useAuth();
    useCustomerAccount();
    usePendingOrders();
    usePortfolio();
    usePositions();
    usePositionsCurrentPrice();
    usePositionsExchangeRate();
    useTradeResults();
    useTradingAccounts();

    useBeforeUnload();
    useAuthTokensAutoUpdate();
    useInAppNotifications();
    useInstrumentsTradingUpdater();
    useAutoLogoutWithNoActivity();

    useEffect(() => {
      initSentry();
      applyCondition();

      if (hasSmartLook) {
        SmartLookService.init();
      }
    }, []);

    const store = useMst();
    const themeMode = store.app.themeMode;
    const themeOptions = themeMode === EThemes.Dark ? darkTheme : lightTheme;
    const theme = getTheme(themeMode, themeOptions);

    return (
      <MuiThemeProvider theme={theme}>
        <StyledCompThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <GlobalStyles />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider maxSnack={4}>
                <RenderRoutes routes={routes} />
                <NotificationContainer />
              </SnackbarProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </StyledCompThemeProvider>
      </MuiThemeProvider>
    );
  }
);

export const AppInitializer = observer((props: IProps) => {
  setMobileHeight();
  disableConsoleErrors();
  useAppVersion();
  useInternetStatus();

  return (
    <BrowserRouter>
      <RootStoreProvider value={rootStore}>
        <AppContainer {...props} />
      </RootStoreProvider>
    </BrowserRouter>
  );
});
