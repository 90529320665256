import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  TradingIsClosed,
  ModalTitle,
  Progress,
  Form,
  Controller,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { calculateSpread } from '@trader/utils';
import { IInitialTradingFormValues } from '@trader/types';
import { EConditionType, useBusinessLogic } from '@trader/hooks';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { BuyButton } from '../components/buyButton';
import { ConfirmButton } from '../components/confirmButton';
import { useClosePositionFormValidation } from './formValidation';

import * as Styled from './styled';

const ClosePosition: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const modal = store.ui.modal;
  const positionId = modal.options.get('positionId');

  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const { validationSchema } = useClosePositionFormValidation(metric?.quantity);

  useEffect(() => {
    return () => store.trading.clear();
  }, []);

  const handleClosePosition = (data: IInitialTradingFormValues) => {
    if (metric) {
      const body = {
        positionId,
        quantity: Number(data.amount),
      };
      metric.closePositionAsync.run(body)?.then(() => {
        setIsOrderProcessing(true);
      });

      // The values will be used in positions socket
      // to show the order response modal correctly.
      modal.update({
        isOrderCanceled: isInvestmentAccProduct,
        isPositionClosed: !isInvestmentAccProduct,
        isPartiallyClosed: metric.quantity > +Number(data.amount),
        skipOrderIdCheck: isInvestmentAccProduct,
        positionId,
        quantityToClose: data.amount,
      });
    }
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  return (
    <Styled.Root>
      <React.Fragment>
        <ModalTitle title={translate('COMMON.LABELS.CLOSE_POSITION')} />
        <Form<IInitialTradingFormValues>
          onSubmit={(_reset, data) => {
            handleClosePosition(data);
          }}
          mode='all'
          defaultValues={{
            amount: metric?.quantity?.toString(),
          }}
          validationSchema={validationSchema}
        >
          {({ formState, control, register }) => (
            <React.Fragment>
              {instrument.isOpenTrading ? (
                <Styled.Position>
                  <TradingModalHeader<IPositionMetricStore>
                    type='position'
                    metric={metric}
                  />
                  <Styled.ActionButtons>
                    <BuyButton isDisabled isAsk side='Sell' />
                    <Styled.Spread>
                      {calculateSpread({
                        ask: Number(instrument.ask),
                        bid: Number(instrument.bid),
                        pipSize: instrument.pipSize,
                      })}
                    </Styled.Spread>
                    <BuyButton isDisabled side='Buy' />
                  </Styled.ActionButtons>
                  <Styled.Main>
                    <Controller
                      type='orderInput'
                      shouldCheckActivityOfButtons
                      placeholder={translate('COMMON.LABELS.QUANTITY')}
                      maxValue={metric?.quantity || 0}
                      disabled={!instrument.isOpenTrading}
                      minValue={instrument.minOrderSize}
                      fixDigitAfterDot={trading.amountDigitsAfterDot()}
                      step={instrument.minOrderSizeIncrement}
                      control={control}
                      {...register('amount')}
                    />
                  </Styled.Main>
                  <ConfirmButton
                    isDisabled={!!formState.errors.amount}
                    isLoading={metric?.closePositionAsync.inProgress}
                    title={
                      isOrderProcessing
                        ? translate('COMMON.LABELS.PROCESSING')
                        : translate('COMMON.LABELS.CLOSE_POSITION')
                    }
                  />
                </Styled.Position>
              ) : (
                <TradingIsClosed
                  iconTypeName='warning'
                  title={translate('WARNINGS.CURRENTLY_OUT_OF_TRADING_HOURS')}
                />
              )}
            </React.Fragment>
          )}
        </Form>
      </React.Fragment>
    </Styled.Root>
  );
});

export const ClosePositionWrapper: React.FC = observer(() => {
  const store = useMst();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return <ClosePosition />;
});
