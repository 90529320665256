import styled, { css } from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Icon } from '@trader/components';

export const CalendarIcon = styled(Icon)`
  ${({ theme }) => css`
    path {
      stroke: ${theme.palette.primary.main};
    }
  `};
`;

export const Datepicker = styled(DatePicker)`
  ${({ theme }) => css`
    fieldset {
      border: none;
    }

    input {
      font-size: 11px;
      color: ${theme.palette.primary.dark};
      box-sizing: border-box;
    }
  `};
`;
