import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { EChartLayouts, EChartLayoutTypes } from '@trader/types';
import { InstrumentDetailsHeader } from '@trader/containers';
import { Progress, Tabs } from '@trader/components';
import { useI18next } from '@trader/services';
import { useLayouts } from '@trader/hooks';
import { useMst } from '@trader/store';

import { Chart } from './components/chart';

import * as Styled from './styled';

const KeyStatisticsLazy = React.lazy(() =>
  import('@trader/containers').then(({ KeyStatistics }) => ({
    default: KeyStatistics,
  }))
);
const RelatedInstrumentsLazy = React.lazy(() =>
  import('@trader/containers').then(({ RelatedInstruments }) => ({
    default: RelatedInstruments,
  }))
);
const InstrumentPositionsLazy = React.lazy(() =>
  import('@trader/containers').then(({ InstrumentPositions }) => ({
    default: InstrumentPositions,
  }))
);
const InstrumentOrdersLazy = React.lazy(() =>
  import('@trader/containers').then(({ InstrumentOrders }) => ({
    default: InstrumentOrders,
  }))
);

export const SingleLayout: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  useLayouts(symbol);

  useEffect(() => {
    symbol &&
      store.entities.instruments.getInstrumentSpecificationAsync.run(symbol);
  }, [symbol]);

  const tabs = [
    {
      label: translate('COMMON.LABELS.CHART'),
      value: 'chart',
      children: <Chart />,
    },
    {
      label: translate('COMMON.LABELS.KEY_STATISTICS'),
      value: 'key-statistics',
      children: (
        <React.Suspense fallback={<Progress />}>
          <KeyStatisticsLazy />
        </React.Suspense>
      ),
    },
    {
      label: translate('COMMON.LABELS.RELATED_INSTRUMENTS'),
      value: 'related-instruments',
      children: (
        <React.Suspense fallback={<Progress />}>
          <RelatedInstrumentsLazy />
        </React.Suspense>
      ),
    },
    {
      label: translate('COMMON.LABELS.OPEN_POSITIONS'),
      value: 'open-positions',
      children: (
        <React.Suspense fallback={<Progress />}>
          <InstrumentPositionsLazy />
        </React.Suspense>
      ),
    },
    {
      label: translate('COMMON.LABELS.ORDERS'),
      value: 'orders',
      children: (
        <React.Suspense fallback={<Progress />}>
          <InstrumentOrdersLazy />
        </React.Suspense>
      ),
    },
  ];

  return (
    <Styled.Root>
      <InstrumentDetailsHeader
        layout={EChartLayoutTypes.Single}
        layoutNumber={EChartLayouts.FirstLayout}
      />
      <Tabs navigationKey='layoutView' tabs={tabs} hasNavigationBar />
    </Styled.Root>
  );
});
