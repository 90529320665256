import { types } from 'mobx-state-tree';

import { localStorageService } from '@trader/services';
import { isLiveEnv, LOCAL_STORAGE_KEYS } from '@trader/constants';
import { getSystemTheme, runInAction } from '@trader/utils';
import { EApiType, EBrand, EDesignType, ETradingView } from '@trader/types';
import { EThemes } from '@trader/themes';
import { TIconsName } from '@trader/assets';

const AppStore = types
  .model('AppStore', {
    themeMode: types.union(
      types.literal(EThemes.Light),
      types.literal(EThemes.Dark)
    ),
    chartType: types.union(
      types.literal(ETradingView.Advanced),
      types.literal(ETradingView.Classic)
    ),
    brand: types.optional(
      types.union(
        types.literal(EBrand.Markets77),
        types.literal(EBrand.FrontBroker),
        types.literal(EBrand.ChallengesTrades),
        types.literal(EBrand.TradeGlobal),
        types.literal(EBrand.Finexo),
        types.literal(EBrand.HeroMarkets),
        types.literal(EBrand.PipsTrades),
        types.literal(EBrand.TrdFx),
        types.literal(EBrand.TradeItaly)
      ),
      import.meta.env.VITE_BRAND
    ),
    apiType: types.optional(
      types.union(
        types.literal(EApiType.DEVELOP),
        types.literal(EApiType.STAGING),
        types.literal(EApiType.PRODUCTION)
      ),
      isLiveEnv ? EApiType.PRODUCTION : EApiType.STAGING
    ),
    designType: types.union(
      types.literal(EDesignType.BETA),
      types.literal(EDesignType.STABLE)
    ),
  })
  .views(store => ({
    getAppIconBasedOnTheme: (): TIconsName => {
      return store.themeMode === EThemes.Light
        ? import.meta.env.VITE_LIGHT_LOGO
        : import.meta.env.VITE_DARK_LOGO;
    },
    getLoginIconBasedOnTheme: (): TIconsName => {
      return store.themeMode === EThemes.Light
        ? import.meta.env.VITE_LOGIN_LIGHT_LOGO
        : import.meta.env.VITE_LOGIN_DARK_LOGO;
    },
    isBetaDesignEnabled: () => {
      return store.designType === EDesignType.BETA;
    },
  }))
  .actions(store => ({
    runInAction,
    toggleThemeMode: (value?: EThemes) => {
      const theme =
        store.themeMode === EThemes.Light ? EThemes.Light : EThemes.Dark;

      store.themeMode = value || theme;
      localStorageService.set(LOCAL_STORAGE_KEYS.theme, value || theme);
    },
    changeChartType: (value: ETradingView) => {
      store.chartType = value;
    },
    toggleDesignType: () => {
      store.designType =
        store.designType === EDesignType.BETA
          ? EDesignType.STABLE
          : EDesignType.BETA;
    },
  }));

export const app = types.optional(AppStore, {
  themeMode: getSystemTheme(),
  chartType: ETradingView.Advanced,
  // Don't forget to change the default value
  // of leftBarWidth when change design type.
  designType: EDesignType.STABLE,
});
