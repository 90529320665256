import { SnackbarProvider, OptionsObject } from 'notistack';
import { INotificationOut } from '@trader/store';

interface IBaseCreateNotification {
  closeSnackbar: SnackbarProvider['closeSnackbar'];
  notification: INotificationOut;
  removeNotificationFromStore: (uid: string | number) => void;
}

export const createNotification = ({
  notification,
  removeNotificationFromStore,
}: // closeSnackbar,
IBaseCreateNotification): OptionsObject => {
  // const createOnClose = (key: SnackbarKey | undefined) => () => {
  //   closeSnackbar(key);
  // };
  return {
    key: notification.uid,
    ...notification?.options,
    onExited: (_event, uid) => {
      removeNotificationFromStore(uid);
    },
    // eslint-disable-next-line react/display-name
    // action: key => {
    //   return (
    //     <div>
    //       <Button size='small' onClick={createOnClose(key)}>
    //         Hide
    //       </Button>
    //     </div>
    //   );
    // },
  };
};
