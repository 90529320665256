import React from 'react';
import styled, { css } from 'styled-components';

import { Switch, Box, BoxProps, SwitchProps } from '@mui/material';
import { conditionApply } from '@trader/utils';
import { ICommonStyledProps } from '@trader/types';

import { ContainerControlInfo } from '../controlInfo/styled';

const opacity = 0.3;

export const Switcher = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))`
  ${({ theme }) => css`
    width: 32px;
    height: 20px;
    padding: 0;
    display: flex;

    & .MuiSwitch-switchBase {
      padding: 2.5px 4px 2px 4px;

      &.Mui-checked {
        transform: translateX(10px);
        color: ${theme.palette.white.main};

        & + .MuiSwitch-track {
          opacity: 1;
          background-color: ${theme.palette.green.dark};
        }
      }
    }

    & .MuiSwitch-thumb {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transition: width ${theme.transitions.duration.standard}ms;
    }

    & .MuiSwitch-track {
      border-radius: 30px;
      opacity: 1;
      background-color: ${theme.palette.gray.main};
      box-sizing: border-box;
    }
  `}
`;

interface IContainerProps extends BoxProps, ICommonStyledProps {
  checked?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Container = styled<React.FC<IContainerProps>>(
  ({ checked, $isDisabled, $isError, ...rest }) => <Box {...rest} />
)`
  ${({ checked, $isDisabled, $isError, theme }) =>
    css`
      ${Switcher} {
        ${conditionApply(
          [$isError],
          `
          .MuiSwitch-switchBase {
            color: ${theme.palette.error.main};
          }
          .MuiSwitch-track {
            background-color: ${theme.palette.error.main};
          }
        `
        )}
        ${conditionApply(
          [$isDisabled],
          `
          .MuiSwitch-switchBase {
              color: ${theme.palette.grey['300']};
          }
        `
        )}
        ${conditionApply(
          [checked && $isDisabled],
          `
          .MuiSwitch-switchBase {
            color: ${theme.palette.primary.main};
            opacity: ${$isDisabled ? opacity : 1};
          }
        `
        )}
      }

      ${ContainerControlInfo} {
        margin-left: 15px;
        margin-top: -9px;
      }
    `}
`;

export const LeftLabel = styled('span')`
  ${() => css`
    padding-right: 7px;
    font-size: 13px;
    font-weight: 700;
  `}
`;
