import { types, Instance, getRoot, IAnyStateTreeNode } from 'mobx-state-tree';

import { defaultSubCategoryId } from '@trader/constants';
import { EChartLayouts } from '@trader/types';
import { runInAction } from '@trader/utils';

import { IRootStoreInstance } from '../../configureStore/configureStore';
import { TInstrumentEntity } from '../../entities/instruments';
import { categoryModel } from '../../entities/categories';
import { Layout } from './layout';
import {
  placeOrderAsync,
  getInstrumentSpecificationAsync,
} from '../../entities/instruments/actions';
import {
  getPrefetchInformationAsync,
  selectCategoryAsync,
  getAccountStatementAsync,
} from './actions';

export const tradingModel = types
  .model('tradingModel', {
    selectedCategory: types.maybeNull(
      types.reference(categoryModel, {
        get(identifier: string, parent) {
          const rootStore: IAnyStateTreeNode = getRoot(parent);
          const category = rootStore.entities.categories.get(identifier);
          const firstCategory = rootStore.entities.categories.getAll()[0];
          return category ? category : firstCategory ? firstCategory : null;
        },
        set(value) {
          return value.id;
        },
      })
    ),
    selectedSubCategory: types.optional(types.string, defaultSubCategoryId),
    layoutView: types.optional(types.string, 'chart'),
    assetsView: types.optional(types.string, 'positions'),
    instrumentsAmount: types.number,
    layout: Layout,
    getPrefetchInformationAsync,
    selectCategoryAsync,
    getAccountStatementAsync,
    getInstrumentSpecificationAsync,
    placeOrderAsync,
  })
  .views(store => ({
    getInstrumentsByCategory: (
      category: string,
      subCategory: string
    ): Array<TInstrumentEntity> => {
      const root: IRootStoreInstance = getRoot(store);
      const subCategoryId = subCategory || defaultSubCategoryId;
      const instruments = root.entities.instruments.getAll<TInstrumentEntity>();

      const filteredByCategory = instruments.filter(i =>
        category === 'Watchlist'
          ? i.isFavorite
          : category === 'Popular'
          ? i.isPopular
          : i.category === category
      );
      const filteredBySubCategory = instruments.filter(
        i => i.subCategoryId === subCategoryId
      );

      return subCategoryId === defaultSubCategoryId
        ? filteredByCategory
        : filteredBySubCategory;
    },
    getInstrumentSymbolByLayout: (layout?: EChartLayouts) => {
      return (
        store.layout.layouts[layout || EChartLayouts.FirstLayout].symbol || ''
      );
    },
  }))
  .actions(() => ({
    runInAction,
  }));

export const trading = types.optional(tradingModel, {
  selectedCategory: null,
  instrumentsAmount: 1,
});

export type TTradingStore = Instance<typeof tradingModel>;
