import styled, { css } from 'styled-components';

interface IValue {
  $isEqualZero: boolean;
  $isAboveZero: boolean;
}

export const Value = styled('span')<IValue>`
  ${({ theme, $isAboveZero, $isEqualZero }) => css`
    color: ${$isEqualZero
      ? 'initial'
      : $isAboveZero
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
  `};
`;
