import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { useProtectionSchema } from '@trader/hooks';

export const useAddProtectionFormValidation = () => {
  const { currentLng } = useI18next();

  const { protectionSchema } = useProtectionSchema();

  const validationSchema = useMemo(() => {
    return yup
      .object()
      .shape({
        ask: yup.number(),
        bid: yup.number(),
        side: yup.string(),
        orderType: yup.string(),
      })
      .concat(protectionSchema);
  }, [currentLng]);

  return { validationSchema };
};
