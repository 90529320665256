import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${modalHorizontalPadding}px;
  `}
`;

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
    align-items: center;
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: 15px;
    font-weight: 500;
    min-width: max-content;
    color: ${theme.palette.gray.dark};
  `}
`;

export const Main = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-bottom: 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 14px;
    font-weight: 500;
    width: 50%;

    ${theme.breakpoints.down('sm')} {
      width: 35%;
    }
  `}
`;
