import { useEffect } from 'react';

import { notificationService, INotificationMessage } from '@trader/services';
import { useMst } from '@trader/store';

/**
 * Custom hook that sets up in-app notifications.
 */
export const useInAppNotifications = () => {
  const store = useMst();

  const handleMessage = (message: INotificationMessage) => {
    store.notifications.add({
      message: message.body,
      options: {
        variant: 'info',
      },
    });
  };

  useEffect(() => {
    notificationService.handleMessaging(handleMessage);
  }, []);
};
