import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { dateFormats } from '@trader/utils';
import { Icon } from '@trader/components';
import { useI18next } from '@trader/services';
import { IAccountStatementBE } from '@trader/api';
import { useMst } from '@trader/store';

import {
  balanceHistoryPositionsActions,
  formatDecimal,
  getAccountStatementActionDescription,
  getChartData,
} from '../../utils';
import { DownloadReportButton } from '../downloadReportButton';

import * as Styled from './styled';

export interface IAccountStatementHtmlReport {
  from: string;
  to: string;
  platformLogin: string;
  currency: string;
  product: string;
  accountType: string;
  accountStatement: IAccountStatementBE;
  balanceChart;
}

export const HtmlReport: React.FC<IAccountStatementHtmlReport> = observer(
  ({
    from,
    to,
    platformLogin,
    currency,
    product,
    accountType,
    accountStatement,
    balanceChart,
  }) => {
    const { translate } = useI18next();
    const store = useMst();

    return (
      <Styled.Root>
        <Styled.Header>
          <div id='logo'>
            <Icon iconType={store.app.getAppIconBasedOnTheme()} />
          </div>
          <DownloadReportButton
            from={from}
            to={to}
            platformLogin={platformLogin}
            currency={currency}
            product={product}
            accountType={accountType}
            accountStatement={accountStatement}
            balanceChart={balanceChart}
          />
        </Styled.Header>
        <Styled.Divider />
        <div>
          <Styled.Title>
            {translate('COMMON.LABELS.TRADE_HISTORY_REPORT')}
          </Styled.Title>
          <Styled.Subtitle>
            {dateFormats(new Date(from), 'dd.mm.yyyy')} -{' '}
            {dateFormats(new Date(to), 'dd.mm.yyyy')}
          </Styled.Subtitle>
        </div>
        <Styled.Divider />
        <table style={{ width: '380px' }}>
          <tbody>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.NAME')}</Styled.Label>
              <Styled.Value>
                {store.user.profile.firstName} {store.user.profile.lastName}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.ACCOUNT')}</Styled.Label>
              <Styled.Value>
                {platformLogin} ({currency}, {product}, {accountType})
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.COMPANY')}</Styled.Label>
              <Styled.Value>{store.app.brand}</Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.DATE')}</Styled.Label>
              <Styled.Value>
                {dateFormats(new Date(), 'dd.mm.yyyy hh:mm:ss')}
              </Styled.Value>
            </tr>
          </tbody>
        </table>
        <Styled.Divider />
        <Styled.SectionTitle>
          {translate('COMMON.LABELS.SUMMARY')}
        </Styled.SectionTitle>
        <table style={{ width: '550px' }}>
          <tbody>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.BALANCE')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.balance)}
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.USED_MARGIN')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.usedMargin)}
              </Styled.Value>
              <Styled.Label>{translate('COMMON.LABELS.DEPOSIT')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.deposit)}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.EQUITY')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.equity)}
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.USABLE_MARGIN')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.marginFree?.value)}
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.WITHDRAWAL')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.withdrawal)}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.NET_PL')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.grossPl)}
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.USABLE_MARGIN')}, %
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.marginFree?.percent)}%
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.ADJUSTMENT')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.adjustment)}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.REALIZED_TOTAL_PL')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.realizedPl)}
              </Styled.Value>
              <Styled.Label>
                {translate('COMMON.LABELS.EQUITY_MC_LEVEL')}, %
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.mcLevel)}%
              </Styled.Value>
              <Styled.Label>{translate('COMMON.LABELS.NET')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.summary.net)}
              </Styled.Value>
            </tr>
          </tbody>
        </table>
        <Styled.Divider />
        <Styled.SectionTitle>
          {translate('COMMON.LABELS.COSTS_OVERVIEW')}
        </Styled.SectionTitle>
        <Styled.Subtitle style={{ marginTop: '12px' }}>
          {translate('COMMON.LABELS.TRADING_COSTS')}
        </Styled.Subtitle>
        <table style={{ width: '650px' }}>
          <thead>
            <tr>
              <th></th>
              <Styled.Label>
                {translate('COMMON.LABELS.ACTIVE_TRADING_COSTS')}
              </Styled.Label>
              <Styled.Label>
                {translate('COMMON.LABELS.CLOSED_TRADING_COSTS')}
              </Styled.Label>
              <Styled.Label>
                {translate('COMMON.LABELS.TOTAL_TRADING_COSTS')}
              </Styled.Label>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.OPEN_COMMISSIONS')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.openCommissions
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.openCommissions
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.openCommissions
                )}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.CLOSE_COMMISSIONS')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading
                    ?.closeCommissions
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.closeCommissions
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.closeCommissions
                )}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.SPREAD')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.spread
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.spread
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.spread
                )}
              </Styled.Value>
            </tr>
          </tbody>
        </table>
        <Styled.Subtitle style={{ marginTop: '12px' }}>
          {translate('COMMON.LABELS.OTHER_ADJUSTMENTS')}
        </Styled.Subtitle>
        <table style={{ width: '750px' }}>
          <thead>
            <tr>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.ACTIVE_OTHER_ADJUSTMENTS')}
              </Styled.Label>
              <Styled.Label>
                {translate('COMMON.LABELS.CLOSED_OTHER_ADJUSTMENTS')}
              </Styled.Label>
              <Styled.Label>
                {translate('COMMON.LABELS.TOTAL_OTHER_ADJUSTMENTS')}
              </Styled.Label>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.OVERNIGHT_SWAP')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.swap
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.swap
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.swap
                )}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.DIVIDENDS')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.dividends
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.dividends
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.dividends
                )}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>{translate('COMMON.LABELS.WHT')}</Styled.Label>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.wht
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.wht
                )}
              </Styled.Value>
              <Styled.Value>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.wht
                )}
              </Styled.Value>
            </tr>
          </tbody>
        </table>
        <Styled.Divider />
        <div>
          {accountStatement.balanceChart.length > 0 && (
            <Styled.Chart>
              <Styled.SectionTitle>
                {translate('COMMON.LABELS.BALANCE_CHART')}
              </Styled.SectionTitle>
              <ResponsiveContainer>
                <LineChart
                  id='balanceChart'
                  data={getChartData(accountStatement)}
                  margin={{ top: 10, right: 20, left: 20, bottom: 30 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name'>
                    <Label position='insideBottom' />
                  </XAxis>
                  <YAxis
                    padding={{ top: 20 }}
                    label={{
                      value: 'Balance',
                      position: 'insideLeft',
                      angle: -90,
                      offset: 0,
                    }}
                  ></YAxis>
                  <Legend />
                  <Line
                    strokeWidth={2}
                    dot={false}
                    type='monotone'
                    dataKey='value'
                    name='Transactions (#)'
                    stroke='rgb(1, 120, 250)'
                  />
                </LineChart>
              </ResponsiveContainer>
            </Styled.Chart>
          )}
        </div>
        <Styled.Divider />
        <Styled.SectionTitle>
          {translate('COMMON.LABELS.POSITIONS')}
        </Styled.SectionTitle>
        <>
          {accountStatement.openPositions.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <Styled.Label>
                    {translate('COMMON.LABELS.POSITION_ID')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.ENTRY_TIME')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.SYMBOL')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.DIRECTION')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.QUANTITY')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.ENTRY_PRICE')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.STOP_LOSS')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.TAKE_PROFIT')}
                  </Styled.Label>
                  <Styled.Label>{translate('COMMON.LABELS.SWAP')}</Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.OPEN_COMMISSION')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.CLOSE_COMMISSION')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.PRICE')}
                  </Styled.Label>
                  <Styled.Label>{translate('COMMON.LABELS.FEE')}</Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.SPREAD')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.NET_PL')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.COMMENT')}
                  </Styled.Label>
                </tr>
              </thead>
              <tbody>
                {accountStatement.openPositions.map(t => (
                  <tr key={t.positionId}>
                    <Styled.Value>{t.positionId}</Styled.Value>
                    <Styled.Value>
                      {dateFormats(
                        new Date(t.entryTime as unknown as string),
                        'dd.mm.yyyy hh:mm'
                      )}
                    </Styled.Value>
                    <Styled.Value>{t.symbol}</Styled.Value>
                    <Styled.Value>{t.direction}</Styled.Value>
                    <Styled.Value>{t.quantity}</Styled.Value>
                    <Styled.Value>{t.entryPrice}</Styled.Value>
                    <Styled.Value>{t.stopLoss}</Styled.Value>
                    <Styled.Value>{t.takeProfit}</Styled.Value>
                    <Styled.Value>{t.swap}</Styled.Value>
                    <Styled.Value>{t.openCommission}</Styled.Value>
                    <Styled.Value>{t.closeCommission}</Styled.Value>
                    <Styled.Value>{t.price}</Styled.Value>
                    <Styled.Value>{t.fee}</Styled.Value>
                    <Styled.Value>{t.spread}</Styled.Value>
                    <Styled.Value>{t.pl}</Styled.Value>
                    <Styled.Value>{t.comment}</Styled.Value>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{translate('COMMON.LABELS.NO_TRANSACTIONS')}</p>
          )}
        </>
        <Styled.Divider />
        <Styled.SectionTitle>
          {translate('COMMON.LABELS.DEALS')}
        </Styled.SectionTitle>
        <>
          {accountStatement.balanceHistory.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <Styled.Label>
                    {translate('COMMON.LABELS.POSITION_ID')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.ENTRY_TIME')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.EXIT_TRANSFER_TIME')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.SYMBOL')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.DIRECTION')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.QUANTITY')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.ENTRY_PRICE')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.EXIT_PRICE')}
                  </Styled.Label>
                  <Styled.Label>{translate('COMMON.LABELS.FEE')}</Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.COMMISSION')}
                  </Styled.Label>
                  <Styled.Label>{translate('COMMON.LABELS.SWAP')}</Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.SPREAD')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.PROFIT_LOSS_BALANCE')}
                  </Styled.Label>
                  <Styled.Label>
                    {translate('COMMON.LABELS.COMMENT')}
                  </Styled.Label>
                </tr>
              </thead>
              <tbody>
                {accountStatement.balanceHistory.map(t => (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {balanceHistoryPositionsActions.includes(t.action) ? (
                      <tr key={t.positionId}>
                        <Styled.Value>{t.positionId}</Styled.Value>
                        <Styled.Value>
                          {dateFormats(
                            new Date(t.entryTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Styled.Value>
                        <Styled.Value>
                          {dateFormats(
                            new Date(t.exitTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Styled.Value>
                        <Styled.Value>{t.symbol}</Styled.Value>
                        <Styled.Value>
                          {getAccountStatementActionDescription(t.action)}
                        </Styled.Value>
                        <Styled.Value>{t.quantity}</Styled.Value>
                        <Styled.Value>{t.entryPrice}</Styled.Value>
                        <Styled.Value>{t.exitPrice}</Styled.Value>
                        <Styled.Value>{t.fee}</Styled.Value>
                        <Styled.Value>{t.commission}</Styled.Value>
                        <Styled.Value>{t.swap}</Styled.Value>
                        <Styled.Value>{t.spread}</Styled.Value>
                        <Styled.Value>{t.pl}</Styled.Value>
                        <Styled.Value>{t.comment}</Styled.Value>
                      </tr>
                    ) : (
                      <tr key={t.positionId}>
                        <td></td>
                        <Styled.Value>
                          {dateFormats(
                            new Date(t.entryTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Styled.Value>
                        <Styled.Value>
                          {dateFormats(
                            new Date(t.exitTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Styled.Value>
                        <td></td>
                        <Styled.Value>
                          {getAccountStatementActionDescription(t.action)}
                        </Styled.Value>
                        <td></td>
                        <td></td>
                        <td></td>
                        <Styled.Value>{t.spread}</Styled.Value>
                        <Styled.Value>{t.pl}</Styled.Value>
                        <Styled.Value>{t.comment}</Styled.Value>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{translate('COMMON.LABELS.NO_TRANSACTIONS')}</p>
          )}
        </>
        <Styled.Divider />
        <Styled.SectionTitle style={{ textTransform: 'capitalize' }}>
          {translate('COMMON.LABELS.RESULTS')}
        </Styled.SectionTitle>
        <table>
          <tbody>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.TOTAL_NET_PROFIT')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.netProfit)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.GROSS_PROFIT')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.grossProfit)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.GROSS_LOSS')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.grossLoss)}
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.PROFIT_FACTOR')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.profitFactor)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.EXPECTED_PAYOFF')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.expectedPayoff)}
              </Styled.Value>
              <td></td>
              <td>
                <br />
              </td>
              <td></td>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.RECOVERY_FACTOR')}
              </Styled.Label>
              <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.SHARPE_RATIO')}
              </Styled.Label>
              <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
              <td></td>
              <td>
                <br />
              </td>
              <td></td>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.BALANCE_DRAWDOWN')}
              </Styled.Label>
              <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <br />
              </td>
              <td></td>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.BALANCE_DRAWDOWN_ABSOLUTE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.absoluteDrawdown)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.BALANCE_DRAWDOWN_MAXIMAL')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.maxDrawdown.value)} (
                {formatDecimal(accountStatement.maxDrawdown.percent)}
                %)
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.BALANCE_DRAWDOWN_RELATIVE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.relativeDrawdown.value)} (
                {formatDecimal(accountStatement.relativeDrawdown.percent)}
                %)
              </Styled.Value>
            </tr>
            <tr>
              <Styled.Label>
                {translate('COMMON.LABELS.TOTAL_TRADES')}
              </Styled.Label>
              <Styled.Value>{accountStatement.totalTrades}</Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.SHORT_TRADES_WON')}
              </Styled.Label>
              <Styled.Value>
                {accountStatement.shortTrades.value} (
                {formatDecimal(accountStatement.shortTrades.percent)}
                %)
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.LONG_TRADES_WON')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.longTrades.value)} (
                {formatDecimal(accountStatement.longTrades.percent)}
                %)
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.PROFIT_TRADES_OF_TOTAL')}
              </Styled.Label>
              <Styled.Value>
                {accountStatement.profitTrades.value} (
                {formatDecimal(accountStatement.profitTrades.percent)}
                %)
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.LOSS_TRADES_OF_TOTAL')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.lossTrades.value)} (
                {formatDecimal(accountStatement.lossTrades.percent)}
                %)
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.LARGEST_PROFIT_TRADE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.largestTrade)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.LARGEST_LOSS_TRADE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.largestLossTrade)}
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.AVERAGE_PROFIT_TRADE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.avgProfitTrade)}
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.AVERAGE_LOSS_TRADE')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.avgLossTrade)}
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_WINS', {
                  currency: currency,
                })}
              </Styled.Label>
              <Styled.Value>
                {accountStatement.maxConsecutiveProfit} (
                {formatDecimal(accountStatement.maxConsecutiveWins)})
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES', {
                  currency: currency,
                })}
              </Styled.Label>
              <Styled.Value>
                {accountStatement.maxConsecutiveLoss} (
                {accountStatement.maxConsecutiveLosses})
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.MAXIMAL_CONSECUTIVE_WIN_COUNT')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.maxConsecutiveWins)} (
                {accountStatement.maxConsecutiveProfit})
              </Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES_COUNT')}
              </Styled.Label>
              <Styled.Value>
                {formatDecimal(accountStatement.maxConsecutiveLosses)} (
                {accountStatement.maxConsecutiveLoss})
              </Styled.Value>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_WINS')}
              </Styled.Label>
              <Styled.Value>{accountStatement.avgConsecutiveWins}</Styled.Value>
              <td></td>
              <Styled.Label>
                {translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_LOSSES')}
              </Styled.Label>
              <Styled.Value>
                {accountStatement.avgConsecutiveLosses}
              </Styled.Value>
            </tr>
          </tbody>
        </table>
      </Styled.Root>
    );
  }
);
