import { Instruments } from '@trader/components';
import { useI18next } from '@trader/services';
import {
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';

import * as Styled from './styled';

export const InstrumentsTable = () => {
  const { translate } = useI18next();

  return (
    <Styled.Root>
      <Styled.Header>
        <Styled.Title>
          <Styled.Instrument>
            {translate('COMMON.LABELS.INSTRUMENT')}
          </Styled.Instrument>
          <Styled.Sell>{translate('COMMON.LABELS.SELL')}</Styled.Sell>
          <Styled.Buy>{translate('COMMON.LABELS.BUY')}</Styled.Buy>
          {shouldDisplaySpreadColumnOnInstrumentList && (
            <Styled.Spread>{translate('COMMON.LABELS.SPREAD')}</Styled.Spread>
          )}
          {shouldDisplayChangeColumnOnInstrumentList && (
            <Styled.Spread>{translate('COMMON.LABELS.CHANGE')}</Styled.Spread>
          )}
          <Styled.HTrend>{translate('COMMON.LABELS.24HTREND')}</Styled.HTrend>
          <Styled.Favourite />
          <Styled.Action />
        </Styled.Title>
      </Styled.Header>
      <Instruments />
    </Styled.Root>
  );
};
