import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import { calcPositionPl, formatTableRowValueByPipSize } from '@trader/utils';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { MODAL_TYPES } from '@trader/constants';
import { useI18next } from '@trader/services';

import { IconButton } from '../iconButton';
import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  ScrollingList,
  PurchaseType,
  SymbolWithIcon,
  ChangePercentage,
  ProfitValue,
} from '../scrollingList';

import * as Styled from './styled';

interface IPositions {
  symbol?: string;
}

export const PositionsList: React.FC<IPositions> = observer(({ symbol }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { getExtendedWidth } = useExtendItemWidthDependsLng();

  const positions =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const accounts =
    store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

  const allMetrics = symbol
    ? positions.filter(p => p.symbol === symbol)
    : positions;

  useEffect(() => {
    accounts.length &&
      store.entities.positionsMetrics.getPositionsMetricsAsync.run();
  }, [accounts.length]);

  const cells: Array<IHeadCell> = [
    {
      id: 'symbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 120,
      padding: '0 10px 0 30px',
      align: 'start',
    },
    {
      id: 'positionId',
      label: translate('COMMON.LABELS.POSITION_ID'),
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'side',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: getExtendedWidth('60'),
      align: 'center',
    },
    {
      id: 'quantity',
      label: translate('COMMON.LABELS.VOLUME'),
      minWidth: 150,
      align: 'center',
    },
    {
      id: 'pl',
      label: translate('COMMON.LABELS.NET_PL'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'openPrice',
      label: translate('COMMON.LABELS.OPEN_PRICE'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'price',
      label: translate('COMMON.LABELS.CURRENT_PRICE'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'swap',
      label: translate('COMMON.LABELS.SWAP'),
      minWidth: 80,
      align: 'center',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.VALUE'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'stopLoss.stopPrice',
      label: translate('COMMON.LABELS.SL'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'takeProfit.limitPrice',
      label: translate('COMMON.LABELS.TP'),
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'change',
      label: translate('COMMON.LABELS.CHANGE'),
      minWidth: 120,
      align: 'start',
    },
    {
      id: 'actions',
      label: allMetrics.length ? (
        <span
          onClick={() => {
            store.ui.modal.open(MODAL_TYPES.removeAllMetrics, {
              shouldHideCloseButton: true,
              removingType: 'positions',
            });
          }}
        >
          {translate('COMMON.LABELS.CLOSE_ALL')}
        </span>
      ) : (
        ''
      ),
      minWidth: 80,
      align: 'end',
    },
  ];

  const renderActions = item => {
    const position = item.row;

    const openModal = (modalType: keyof typeof MODAL_TYPES) => {
      store.ui.modal.open(modalType, {
        symbol: position.symbol,
        positionId: position.positionId,
        side: position.side,
      });
    };

    return (
      <Styled.Actions>
        <IconButton
          iconType='edit'
          onClick={event => {
            event.stopPropagation();
            openModal('addProtection');
          }}
        />
        <IconButton
          iconType='remove'
          onClick={event => {
            event.stopPropagation();
            openModal('closePosition');
          }}
        />
      </Styled.Actions>
    );
  };

  const renderRowItemsCb: IRenderRowItemsCallBacks<TPositionMetricEntity> =
    useMemo(
      () => ({
        symbol: item => (
          <SymbolWithIcon symbol={item.row.symbol} iconUrl={item.row.iconUrl} />
        ),
        positionId: item => item.value,
        side: item => <PurchaseType value={item.value as string} />,
        quantity: item => item.value,
        pl: item => {
          const value = calcPositionPl(
            item.row.price,
            item.row.openPrice,
            item.row.quantity,
            item.row.exchangeRate,
            item.row.side
          );
          return <ProfitValue value={+value.toFixed(2)} />;
        },
        openPrice: item =>
          formatTableRowValueByPipSize(item.value, item.row.pipSize),
        swap: item => item.value,
        value: item => {
          return Number((item.row.price * item.row.quantity).toFixed(1));
        },
        price: item =>
          formatTableRowValueByPipSize(item.value, item.row.pipSize),
        'stopLoss.stopPrice': item =>
          formatTableRowValueByPipSize(item.value, item.row.pipSize),
        'takeProfit.limitPrice': item =>
          formatTableRowValueByPipSize(item.value, item.row.pipSize),
        change: item => (
          <ChangePercentage
            entryValue={item.row.openPrice}
            exitValue={item.row.price}
            side={item.row.side}
          />
        ),
        actions: renderActions,
      }),
      []
    );

  return (
    <Styled.Root>
      <ScrollingList<TPositionMetricEntity>
        rows={allMetrics}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
        totalCount={allMetrics.length}
      />
    </Styled.Root>
  );
});
