import React, { useEffect } from 'react';
import { Slide } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { TransitionProps } from '@mui/material/transitions';

import { MODAL_TYPES } from '@trader/constants';
import { TInstrumentEntity, useMst } from '@trader/store';
import {
  AddFunds,
  AccountStatement,
  CreateAlert,
  DeleteAlert,
  MyHistory,
  Settings,
  Statistics,
  ResultChallenge,
  ChangingMuliBandsMultiplier,
  OrderResponse,
  LogOutWithNoActivity,
  ConnectMaster,
  RemoveMasterAccount,
  RemoveAllMetrics,
  DailyTargetProfitReached,
  TradingDisabledDueToMarketNews,
  AddProtectionWrapper,
  PurchaseChallenge,
  CreateOrderWrapper,
  ClosePositionWrapper,
  EditOrderWrapper,
  CancelOrderWrapper,
} from '@trader/modals';

import * as Styled from './styled';
import { useInstrument } from '@trader/hooks';
import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from '@trader/utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction='right' ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const RealTimeUpdating = observer(
  ({ component }: { component: React.ReactNode }) => {
    const store = useMst();

    const modal = store.ui.modal;
    const symbol = modal.options.get('symbol');
    const side: TPlaceOrderSide = modal.options.get('side');

    const entity = store.entities.instruments.get<TInstrumentEntity>(symbol);

    const handleUpdate = (askValue: number, bidValue: number) => {
      const ask = +formatByPipSize(askValue, entity?.pipSize);
      const bid = +formatByPipSize(bidValue, entity?.pipSize);

      store.entities.instruments.update(symbol, { ask, bid });
    };

    useEffect(() => {
      (async () => {
        await store.trading.getInstrumentReferenceAsync.run({
          symbol,
          side: side || 'Buy',
        });
      })();
    }, [symbol]);

    useInstrument(symbol, entity?.spreadDiff, handleUpdate);

    return component;
  }
);

export const Modals: React.FC = observer(() => {
  const store = useMst();
  const modal = store.ui.modal;

  const shouldHideCloseButton = modal.options.get('shouldHideCloseButton');
  const borderRadius = modal.options.get('borderRadius');

  const isDisablePadding = modal.id === MODAL_TYPES.myHistory;

  useEffect(() => {
    if (
      modal.isOpen &&
      (modal.id === MODAL_TYPES.placeOrder ||
        modal.id === MODAL_TYPES.addProtection ||
        modal.id === MODAL_TYPES.closePosition ||
        modal.id === MODAL_TYPES.cancelOrder ||
        modal.id === MODAL_TYPES.editOrder)
    ) {
      (!store.user.profile.isRegistrationCompleted ||
        !store.user.profile.isTradingAllowed) &&
        store.user.getProfileDemoAsync.run();
    }

    if (modal.isOpen && modal.id === MODAL_TYPES.addFunds) {
      !store.user.profile.isDepositAllowed &&
        store.user.getProfileDemoAsync.run();
    }
  }, [modal.isOpen, store.user.profile]);

  return (
    <Styled.Modal
      $isDisablePadding={isDisablePadding}
      $borderRadius={borderRadius}
      isOpen={modal.isOpen}
      shouldHideCloseButton={shouldHideCloseButton}
      TransitionComponent={Transition}
    >
      {modal.id === MODAL_TYPES.placeOrder && (
        <RealTimeUpdating component={<CreateOrderWrapper />} />
      )}
      {modal.id === MODAL_TYPES.addProtection && (
        <RealTimeUpdating component={<AddProtectionWrapper />} />
      )}
      {modal.id === MODAL_TYPES.closePosition && (
        <RealTimeUpdating component={<ClosePositionWrapper />} />
      )}
      {modal.id === MODAL_TYPES.editOrder && (
        <RealTimeUpdating component={<EditOrderWrapper />} />
      )}
      {modal.id === MODAL_TYPES.cancelOrder && (
        <RealTimeUpdating component={<CancelOrderWrapper />} />
      )}
      {modal.id === MODAL_TYPES.createPriceAlert && (
        <RealTimeUpdating component={<CreateAlert />} />
      )}
      {modal.id === MODAL_TYPES.addFunds && <AddFunds />}
      {modal.id === MODAL_TYPES.accountStatement && <AccountStatement />}
      {modal.id === MODAL_TYPES.deleteAlert && <DeleteAlert />}
      {modal.id === MODAL_TYPES.settings && <Settings />}
      {modal.id === MODAL_TYPES.statistics && <Statistics />}
      {modal.id === MODAL_TYPES.myHistory && <MyHistory />}
      {modal.id === MODAL_TYPES.resultChallenge && <ResultChallenge />}
      {modal.id === MODAL_TYPES.orderResponse && <OrderResponse />}
      {modal.id === MODAL_TYPES.connectMaster && <ConnectMaster />}
      {modal.id === MODAL_TYPES.removeMasterAccount && <RemoveMasterAccount />}
      {modal.id === MODAL_TYPES.dailyTargetProfitReached && (
        <DailyTargetProfitReached />
      )}
      {modal.id === MODAL_TYPES.logOutWithNoActivity && (
        <LogOutWithNoActivity />
      )}
      {modal.id === MODAL_TYPES.changingMuliBandsMultiplier && (
        <ChangingMuliBandsMultiplier />
      )}
      {modal.id === MODAL_TYPES.removeAllMetrics && <RemoveAllMetrics />}
      {modal.id === MODAL_TYPES.tradingDisabledDueToMarketNews && (
        <TradingDisabledDueToMarketNews />
      )}
      {modal.id === MODAL_TYPES.purchaseChallenge && <PurchaseChallenge />}
    </Styled.Modal>
  );
});
