import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  dateFormats,
  fixToTwoDigitAfterDot,
  formatByPipSize,
} from '@trader/utils';
import { TPositionHistoryEntity, useMst } from '@trader/store';
import { TPlaceOrderSide } from '@trader/types';
import { IPositionHistoryBE } from '@trader/api';
import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  PurchaseType,
  ScrollingList,
  ChangePercentage,
  ProfitValue,
  DateFilter,
} from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  totalCount: number;
  totalPl: number;
}

interface IPositionsHistory {
  shouldShowFilters?: boolean;
  shouldShowFooter?: boolean;
  isDefaultHeaderBg?: boolean;
}

export const PositionsHistory: React.FC<IPositionsHistory> = observer(
  ({ shouldShowFooter, isDefaultHeaderBg }) => {
    const store = useMst();
    const { translate } = useI18next();
    const positionsHistoryResp =
      store.entities.positionsHistory.getAll() as TPositionHistoryEntity[];

    const positionsHistory: TPositionHistoryEntity[] = positionsHistoryResp
      .map(item => {
        if (item.pipSize !== null) {
          return {
            ...item,
            entryPrice: +formatByPipSize(item.entryPrice, item.pipSize),
            exitPrice: +formatByPipSize(item.exitPrice, item.pipSize),
          };
        }
        return item;
      })
      .sort((a, b) => {
        return new Date(a.closeTime).getTime() > new Date(b.closeTime).getTime()
          ? -1
          : 1;
      });

    const hasEverBeenRun =
      store.entities.positionsHistory.getPositionsHistoryAsync.hasEverBeenRun;

    const [state, setState] = useState<IState>({
      isFetchingMore: false,
      totalCount: 0,
      isLoading: true,
      totalPl: 0,
    });

    const accountCurrencySymbol = store.user.portfolio.currencySymbol;

    useEffect(() => {
      (async function () {
        const response =
          await store.entities.positionsHistory.getPositionsHistoryAsync.run({
            shouldClearBeforeMerge: true,
            completedFrom: store.filters.positionsHistory.date.completedFrom,
            completedTo: store.filters.positionsHistory.date.completedTo,
            openedFrom: store.filters.positionsHistory.date.openedFrom,
            openedTo: store.filters.positionsHistory.date.openedTo,
          });

        setState(prev => ({
          ...prev,
          totalPl: response?.pl || 0,
          totalCount: response?.totalCount || 0,
        }));
      })();
    }, [
      store.filters.positionsHistory.date.completedTo,
      store.filters.positionsHistory.date.completedFrom,
      store.filters.positionsHistory.date.openedFrom,
      store.filters.positionsHistory.date.openedTo,
    ]);

    useEffect(() => {
      setState(prev => ({ ...prev, isLoading: !hasEverBeenRun }));
    }, [hasEverBeenRun]);

    const fetchMoreHistory = async (pageNumber: number) => {
      setState(prev => ({ ...prev, isFetchingMore: true }));
      await store.entities.positionsHistory.getPositionsHistoryAsync.run({
        pageNumber,
        shouldClearBeforeMerge: false,
        completedFrom: store.filters.positionsHistory.date.completedFrom,
        completedTo: store.filters.positionsHistory.date.completedTo,
        openedFrom: store.filters.positionsHistory.date.openedFrom,
        openedTo: store.filters.positionsHistory.date.openedTo,
      });
    };

    const renderRowItemsCb: IRenderRowItemsCallBacks<IPositionHistoryBE> =
      useMemo(
        () => ({
          positionId: item => item.value,
          symbol: item => item.value,
          side: item => <PurchaseType value={item.value as string} />,
          quantity: item => item.value,
          entryPrice: item => item.value,
          stopLoss: item => item.value || '-',
          takeProfit: item => item.value || '-',
          exitPrice: item => item.value || '-',
          pl: item => <ProfitValue value={item.value as number} />,
          commission: item => item.value,
          change: item => (
            <ChangePercentage
              entryValue={item.row.entryPrice}
              exitValue={item.row.exitPrice}
              side={item.row.side as TPlaceOrderSide}
            />
          ),
          swap: item => item.value,
          fee: item => item.value,
          closeTime: item =>
            dateFormats(
              new Date(item?.value as string),
              'Mm dd, yyyy hh:mm:ss'
            ),
          openTime: item =>
            dateFormats(
              new Date(item?.value as string),
              'Mm dd, yyyy hh:mm:ss'
            ),
        }),
        []
      );

    const cells: Array<IHeadCell> = [
      {
        id: 'positionId',
        label: translate('COMMON.LABELS.POSITION_ID'),
        minWidth: 100,
        padding: '0 10px 0 30px',
        align: 'start',
      },
      {
        id: 'symbol',
        label: translate('COMMON.LABELS.SYMBOL'),
        minWidth: 90,
        align: 'center',
      },
      {
        id: 'openTime',
        label: translate('COMMON.LABELS.OPEN_DATE'),
        minWidth: 190,
        align: 'start',
      },
      {
        id: 'closeTime',
        label: (
          <DateFilter
            filterName='positionsHistory'
            dateFilterNames={{ from: 'completedFrom', to: 'completedTo' }}
            label={translate('COMMON.LABELS.CLOSE_DATE')}
          />
        ),
        minWidth: 190,
        align: 'start',
      },
      {
        id: 'side',
        label: translate('COMMON.LABELS.TYPE'),
        minWidth: 60,
        align: 'center',
      },
      {
        id: 'quantity',
        label: translate('COMMON.LABELS.VOLUME'),
        minWidth: 90,
        align: 'center',
      },
      {
        id: 'entryPrice',
        label: translate('COMMON.LABELS.OPEN_PRICE'),
        minWidth: 90,
        align: 'center',
      },
      {
        id: 'stopLoss',
        label: translate('COMMON.LABELS.SL'),
        minWidth: 120,
        align: 'center',
      },
      {
        id: 'takeProfit',
        label: translate('COMMON.LABELS.TP'),
        minWidth: 120,
        align: 'center',
      },
      {
        id: 'exitPrice',
        label: translate('COMMON.LABELS.MARKET_PRICE'),
        minWidth: 90,
        align: 'center',
      },
      {
        id: 'commission',
        label: translate('COMMON.LABELS.COMMISSION'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'fee',
        label: translate('COMMON.LABELS.FEE'),
        minWidth: 120,
        align: 'center',
      },
      {
        id: 'swap',
        label: translate('COMMON.LABELS.SWAP'),
        minWidth: 120,
        align: 'center',
      },
      {
        id: 'pl',
        label: translate('COMMON.LABELS.PROFIT'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'change',
        label: translate('COMMON.LABELS.CHANGE'),
        minWidth: 120,
        align: 'start',
      },
    ];

    const renderFooter = () => {
      return (
        <Styled.Footer>
          <p>
            {translate('COMMON.LABELS.TOTAL_PL')}:
            <span>
              {accountCurrencySymbol}
              {fixToTwoDigitAfterDot(state.totalPl)}
            </span>
          </p>
        </Styled.Footer>
      );
    };

    if (state.isLoading) {
      return <Progress />;
    }

    return (
      <Styled.Root $isDefaultHeaderBg={isDefaultHeaderBg}>
        <ScrollingList<IPositionHistoryBE>
          rows={positionsHistory}
          headCells={cells}
          renderRowItemsCallBacks={renderRowItemsCb}
          renderFooter={shouldShowFooter ? renderFooter : undefined}
          fetchMore={fetchMoreHistory}
          totalCount={state.totalCount}
        />
      </Styled.Root>
    );
  }
);
