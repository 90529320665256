import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { getNetPl } from '@trader/utils';

type TCreatePosition = (
  id: number,
  price: number,
  pl: number,
  stripId: string
) => void;

interface IPosition {
  key: string;
  stripId: string;
  ref: IPositionLineAdapter | undefined;
}

const positionLineLength = 22;
const positions = new Map<string, IPosition>();

export const usePositions = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();

  const positionsMetrics =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
  const strips = Array.from(store.pages.muliBands.strips.values());

  const getInstrumentPl = useCallback(
    (pl: number) => {
      const instrument = store.entities.instruments.get<TInstrumentEntity>(
        store.pages.muliBands.symbol
      );
      return getNetPl(pl, instrument?.currencySymbol);
    },
    [store.pages.muliBands.symbol]
  );

  const createPosition: TCreatePosition = (id, price, pl, stripId) => {
    const key = id.toString();
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(key);

    const currentPl = getInstrumentPl(pl);

    const ref = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(currentPl.stringValue)
      .setBodyTextColor(currentPl.textColor)
      .setBodyBorderColor(theme.palette.tab.light)
      .setBodyBackgroundColor(theme.palette.white.main)
      .setLineLength(positionLineLength)
      .setQuantity(`${position?.side}: ${position?.quantity}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setLineColor(theme.palette.tab.light)
      .setPrice(position?.openPrice || price);

    positions.set(key, {
      ...positions.get(key),
      key,
      stripId,
      ref,
    });
  };

  const clearPositions = useCallback(() => {
    positions.clear();
  }, []);

  useEffect(() => {
    widget.current?.onChartReady(() => {
      strips.forEach(strip => {
        if (!strip.positionId) {
          return;
        }

        const position =
          store.entities.positionsMetrics.get<TPositionMetricEntity>(
            strip.positionId
          );

        if (!position) {
          return;
        }

        if (!positions.get(strip.positionId.toString())) {
          createPosition(
            strip.positionId,
            position.openPrice,
            strip.positionPl || 0,
            strip.id
          );
        } else {
          const currentPl = getInstrumentPl(position.pl);

          positions
            .get(strip.positionId.toString())
            ?.ref?.setText(currentPl.stringValue);
          positions
            .get(strip.positionId.toString())
            ?.ref?.setBodyTextColor(currentPl.textColor);
        }
      });
    });
  }, [JSON.stringify(strips), JSON.stringify(positionsMetrics)]);

  // delete position
  useEffect(() => {
    Array.from(positions.values()).forEach((position: IPosition) => {
      const matchedPosition =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(
          position.key
        );
      if (!matchedPosition) {
        positions.get(position.key)?.ref?.remove();
        positions.delete(position.key);
        store.pages.muliBands.updateStripPosition(position.stripId, null);
      }
    });
  }, [positionsMetrics.length]);

  return {
    clearPositions,
  };
};
