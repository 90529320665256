import {
  types,
  cast,
  SnapshotIn,
  SnapshotOut,
  Instance,
} from 'mobx-state-tree';
import { SnackbarOrigin, VariantType } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

interface INotificationOptions {
  autoHideDuration?: number;
  preventDuplicate?: boolean;
  persist?: boolean;
  variant?: VariantType;
  anchorOrigin?: SnackbarOrigin;
}

const defaultDuration = 3000; // 3s;
const defaultErrorDuration = 1000; // 1s;

const Notification = types.model({
  uid: types.optional(types.union(types.string, types.number), Date.now()),
  message: types.optional(types.string, ''),
  isCompleted: false,
  options: types.optional(types.frozen<INotificationOptions>(), {}),
});

export const notifications = types.optional(
  types
    .model({
      list: types.optional(types.array(Notification), []),
    })
    .views(self => ({
      checkIsAllCompleted: () => self.list.every(n => n.isCompleted),
    }))
    .actions(self => ({
      add(notification: INotificationIn) {
        notification.uid = uuidv4();
        notification.isCompleted = false;
        notification.options = {
          autoHideDuration:
            notification.options?.variant === 'error'
              ? defaultErrorDuration
              : notification.options?.autoHideDuration || defaultDuration,
          persist: notification.options?.persist || false,
          variant: notification.options?.variant || 'default',
          preventDuplicate: true,
          anchorOrigin: notification.options?.anchorOrigin || {
            vertical: 'top',
            horizontal: 'right',
          },
        };
        self.list.push(notification);
      },
      complete(uid: INotificationIn['uid']) {
        self.list = cast(
          self.list.map(n => {
            if (n.uid !== uid) {
              n.isCompleted = true;
            }
            return n;
          })
        );
      },
      clearAll() {
        self.list = cast([]);
      },
    })),
  { list: [] }
);

export interface INotificationsModel extends Instance<typeof notifications> {}
export interface INotificationIn extends SnapshotIn<typeof Notification> {}

export interface INotificationOut extends SnapshotOut<typeof Notification> {}
