import { useEffect } from 'react';

import { TPositionMetricEntity, useMst } from '@trader/store';
import { EOrderSide, EStripName } from '@trader/types';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

type TMessageType =
  | 'OrderCreated'
  | 'PositionCreated'
  | 'StrategyInitialized'
  | 'TradeResult';

interface IMessage {
  name: TMessageType;
  id: number;
  side: EOrderSide;
  bandId: string;
  retCode: number;
}

export const useMuliBands = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken;
  const muliBands = store.pages.muliBands;

  const handleSubscribe = () => {
    if (muliBands.id) {
      subscribe(async connection => {
        await connection?.send('SubscribeOnStrategyEvents', muliBands.id);
      });
    }
  };

  const handleUnsubscribe = () => {
    unsubscribe(async connection => {
      await connection?.send('UnsubscribeFromStrategyEvents', muliBands.id);
    });
  };

  const handleUpdate = (message: IMessage) => {
    if (message.name === 'OrderCreated') {
      store.pages.muliBands.updateStripOrder(
        EStripName[message.bandId],
        message.id,
        EOrderSide[message.side]
      );
    }
    if (message.name === 'PositionCreated') {
      store.entities.positionsMetrics.getPositionsMetricsAsync.run();
      const position =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(message.id);
      store.pages.muliBands.updateStripPosition(
        EStripName[message.bandId],
        message.id,
        position?.openPrice,
        position?.pl
      );
      store.pages.muliBands.runInAction(() => {
        store.pages.muliBands.firstOpenedPositionTime = new Date().toString();
      });
    }
    if (message.name === 'StrategyInitialized') {
      store.pages.muliBands.getExistingDataAsync.run(
        store.pages.muliBands.symbol
      );
    }
  };

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/strategy',
    hub: EConnectionHub.MuliBands,
    subscriber: EConnectionHubSubscriber.MuliBands,
    onMessage: handleUpdate,
    invokedName: 'event',
  });

  useEffect(() => {
    handleSubscribe();
  }, [muliBands.id, idToken]);

  useEffect(() => {
    return () => {
      handleUnsubscribe();
    };
  }, [muliBands.symbol, idToken]);
};
