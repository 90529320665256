import { api, IClosePositionParams, IEditPositionParams } from '@trader/api';
import { hasCopierFunctionality } from '@trader/constants';
import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { positionsMetricsSchema } from './schemas';

export const getPositionsMetricsAsync = createThunk<void, void>(
  () =>
    async function getPositionsMetrics(this: unknown, _options, _flow) {
      try {
        const root = getRootInstance();

        const response = await api.Trading.getPositionsMetrics();

        const positions = response?.positions || [];
        const metrics = positions.map(p =>
          p.positionsMetrics.map(m => ({
            ...m,
            symbol: p.symbol,
            pipSize: p.pipSize,
            currency: p.currency,
            spreadDiff: p.spreadDiff,
            iconUrl: p.iconUrl || '',
            swap: m?.swap || 0,
            price: m?.currentPrice || 0,
            value: Number((m.openPrice * m.quantity).toFixed(1)),
            exchangeRate: p.conversionRate,
            pl: p.pl,
            takeProfit: {
              type: 'TakeProfit',
              limitPrice: m.takeProfit?.limitPrice || 0,
            },
            stopLoss: {
              type: 'StopLoss',
              stopPrice: m.stopLoss?.stopPrice || 0,
            },
          }))
        );
        const positionsMetrics = metrics.flat(1);

        root.entities.normalizeMerge(
          positionsMetrics,
          positionsMetricsSchema,
          true
        );
      } catch (e) {
        devLoggerService.error('Error in getPositionsMetricsAsync', e);
      }
    }
);

export const editPositionAsync = createThunk<IEditPositionParams, void>(
  body =>
    async function editPosition(this: unknown, _options, _flow) {
      const root = getRootInstance();

      if (hasCopierFunctionality) {
        await api.Trading.editChallengePosition(body, _options);
      } else {
        await api.Trading.editPosition(body, _options);
      }

      root.ui.modal.close();
    }
);
export const closePositionAsync = createThunk<IClosePositionParams, void>(
  body =>
    async function closePosition(this: unknown, _options, _flow) {
      if (hasCopierFunctionality) {
        await api.Trading.closeChallengePosition(body, _options);
      } else {
        await api.Trading.closePosition(body, _options);
      }
    }
);
export const closeAllPositionsAsync = createThunk<void, void>(
  () =>
    async function closeAllPositions(this: unknown, _options, _flow) {
      const root = getRootInstance();

      try {
        if (hasCopierFunctionality) {
          await api.Trading.closeAllChallengePositions(_options);
        } else {
          await api.Trading.closeAllPositions(_options);
        }
        root.ui.modal.close();
      } catch (e) {
        devLoggerService.error('catch closeAllPositionsAsync error', e);
      }
    }
);
