import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import {
  layoutChildrenRadius,
  modalHorizontalPadding,
} from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .assets {
      height: 100%;
      margin-top: 0;

      > button {
        display: none;
      }
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;
export const Title = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${modalHorizontalPadding}px 20px ${modalHorizontalPadding}px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 13px;
      }

      > p {
        font-size: 18px;
        font-weight: 700;
        margin-left: 5px;
      }
    }

    > span {
      font-size: 15px;
      font-weight: 600;
      color: ${theme.palette.red.light};
      display: flex;
      align-items: center;

      > button {
        font-size: 15px;
        font-weight: 600;
        margin: 0 2px;
      }
    }

    border-bottom: 1px solid ${theme.palette.background.default};
    margin-bottom: 10px;
  `}
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 20px;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};
  `}
`;

export const PaddingContainer = styled(Wrapper)`
  ${() => css`
    padding: 34px ${modalHorizontalPadding}px 0 ${modalHorizontalPadding}px;
  `}
`;
