import { ERouteType, ICustomRouteObject } from '@trader/navigation';
import { paths } from '@trader/constants';
import { AutoLogin, SignIn } from '@trader/pages';

import { AuthPageLayout } from '../../../scenes/layouts/auth';

export const authRoutes: ICustomRouteObject[] = [
  {
    id: 'authRoutes_AuthPageLayout',
    path: paths.auth.root,
    element: <AuthPageLayout />,
    routeType: ERouteType.UnauthorizedOnly,
    children: [
      {
        id: 'authRoutes_LoginPage',
        path: paths.auth.signIn,
        routeType: ERouteType.UnauthorizedOnly,
        element: <SignIn />,
      },
    ],
  },
  {
    id: 'authRoutes_AutoLoginPage',
    path: paths.auth.autoLogin,
    element: <AutoLogin />,
    routeType: ERouteType.Public,
  },
];
