import { useEffect } from 'react';
import { useMst } from '@trader/store';
import {
  getAccountTypeForConnection,
  productId,
  successTradingCodes,
  tradingReturnCodesErrors,
} from '@trader/constants';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useI18next } from '@trader/services';

interface IMessage {
  updateAction: number;
  id: number;
  retCode: number;
  platformLogin: number;
  brand: string;
  orderId: number;
  volume: number;
  volumeCurrent: number;
  resultPrice: number;
  symbol: string;
  billType: string;
  comment: string;
}

/**
 * Custom hook that manages trade result updates by subscribing to a hub and handling incoming messages to update the store.
 */
export const useTradeResults = () => {
  const store = useMst();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const handleUpdate = (message: IMessage) => {
    const code = message.retCode?.toString();
    if (!successTradingCodes.includes(code)) {
      store.ui.modal.close();

      const msg = tradingReturnCodesErrors[code]
        ? tradingReturnCodesErrors[code]
        : 'WARNINGS.TRADING_ACTION_FAILED';

      store.notifications.add({
        message: translate(msg),
        options: {
          variant: 'warning',
        },
      });
    }
  };

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/v2/account',
    hub: EConnectionHub.Account,
    subscriber: EConnectionHubSubscriber.TradeResults,
    onMessage: handleUpdate,
    invokedName: 'trade',
  });

  useEffect(() => {
    if (platformLogin && accountType && product) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnTrade',
          platformLogin || null,
          productId[product],
          getAccountTypeForConnection[accountType]
        );
      });
    }
    return () => {
      if (platformLogin && accountType && product) {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromTrade',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken]);
};
