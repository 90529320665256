import { observer } from 'mobx-react-lite';

import { isChallengeAccountType } from '@trader/constants';
import { EApiType, EBrand } from '@trader/types';
import { useMst } from '@trader/store';
import { Select } from '@trader/components';

import * as Styled from './styled';

// The component is build only for internal uses to simplify testing.
export const DevModeSelection = observer(() => {
  const store = useMst();
  const selectedBrand = store.app.brand;
  const selectedApiType = store.app.apiType;

  const applicationType = isChallengeAccountType
    ? 'Challenge'
    : 'No Challenges';
  const brandOptions = Object.values(EBrand).map(brand => ({
    title: brand,
    value: brand,
  }));
  const apiTypeOptions = [
    {
      title: 'Development',
      value: EApiType.DEVELOP,
    },
    {
      title: 'Staging',
      value: EApiType.STAGING,
    },
    {
      title: 'Production',
      value: EApiType.PRODUCTION,
    },
  ];

  const handleBrandChange = (value: EBrand) => {
    store.app.runInAction(() => {
      store.app.brand = value;
    });
  };

  const handleApiTypeChange = (value: EApiType) => {
    store.app.runInAction(() => {
      store.app.apiType = value;
    });
  };

  return (
    <Styled.Root>
      <Select
        options={brandOptions}
        value={brandOptions.filter(o => o.value === selectedBrand)}
        onChange={v => handleBrandChange(v.target.value[0].value as EBrand)}
        label='Brand'
        sx={{ marginBottom: '12px' }}
      />
      <Select
        options={apiTypeOptions}
        value={apiTypeOptions.filter(o => o.value === selectedApiType)}
        onChange={v => handleApiTypeChange(v.target.value[0].value as EApiType)}
        label='API Type'
      />
      <Styled.Label>
        Selection is available only in DEV mode. <br />
        <span style={{ color: 'red' }}>{applicationType} Application</span> is
        deployed. UI mismatches may occur if an unsupported brand is selected.
      </Styled.Label>
    </Styled.Root>
  );
});
