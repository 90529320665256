import { useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { usePurchase } from '@trader/hooks';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Icon, SearchInstrument } from '@trader/components';

import { TradingAccounts } from '../../../../components/tradingAccounts';
import { useGetMenuList } from '../../../../hooks/useGetMenuList';

import * as Styled from './styled';

interface IMobileMenu {
  isOpen: boolean;
  onToggle: () => void;
  side?: 'right' | 'left';
}
export const MobileMenu: FC<IMobileMenu> = ({
  isOpen,
  onToggle,
  side = 'right',
}) => {
  const { translate } = useI18next();
  const purchase = usePurchase();

  return (
    <Styled.Drawer anchor={side} open={isOpen} onClick={onToggle}>
      <Styled.Container role='presentation'>
        {side === 'right' ? (
          <ListRightMenu onToggle={onToggle} />
        ) : (
          <ListLeftMenu onToggle={onToggle} />
        )}
        {side === 'right' && (
          <Styled.AddFunds
            disabled={purchase.isDisabled}
            onClick={purchase.action}
            variant='text'
          >
            {translate(purchase.title)}
          </Styled.AddFunds>
        )}
      </Styled.Container>
    </Styled.Drawer>
  );
};

interface IListMenu {
  onToggle: () => void;
}
const ListRightMenu: FC<IListMenu> = ({ onToggle }) => {
  const { getMenu } = useGetMenuList();

  return (
    <Styled.RootList>
      <TradingAccounts onClose={onToggle} />
      <Styled.List>
        {getMenu(onToggle).map(item => (
          <Styled.Item key={item.id}>
            <Styled.ItemButton
              disabled={item.disabled}
              onClick={_e => item?.action()}
            >
              {item.icon && <Icon iconType={item.icon} />}
              <Styled.Children>{item.children}</Styled.Children>
            </Styled.ItemButton>
          </Styled.Item>
        ))}
      </Styled.List>
    </Styled.RootList>
  );
};

const ListLeftMenu: FC<IListMenu> = observer(({ onToggle }) => {
  const store = useMst();
  const { getLeftMenu } = useGetMenuList();

  useEffect(() => {
    store.entities.categories.getCategoriesAsync.run();
  }, []);

  return (
    <Styled.RootList className='list-left-menu'>
      <SearchInstrument onClose={onToggle} />
      <Styled.List className='categories'>
        {getLeftMenu(onToggle).map(item => (
          <Styled.Item key={item.id}>
            <Styled.ItemButton
              disabled={item.disabled}
              onClick={_e => item.action(item.value)}
            >
              {item.icon && <Styled.CategoryIcon iconType={item.icon} />}
              <Styled.Children>{item.children}</Styled.Children>
              <Styled.IconRight iconType='arrowRight' />
            </Styled.ItemButton>
          </Styled.Item>
        ))}
      </Styled.List>
    </Styled.RootList>
  );
});
