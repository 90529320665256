import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { EChartLayouts, EGeneralTabContainerType } from '@trader/types';
import { useLayouts, useMuliBands, useNavigation } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { localStorageService } from '@trader/services';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import {
  AdvanceChart,
  BottomBar,
  InstrumentDetailsHeader,
  Modals,
  TopBar,
} from '@trader/containers';

import { InfoBlocks } from './components/infoBlocks';
import { RightMenu } from './components/rightMenu';
import { Assets } from './components/assets';

import * as Styled from './styled';

const pingStrategyInterval = 30000; // 30 sec

export const MuliBands: React.FC = observer(() => {
  const store = useMst();
  const { goBackNavigate } = useNavigation();
  useMuliBands();

  const idToken = store.auth.tokens.idToken;

  useEffect(() => {
    if (!store.user.settings.isMuliBandsDisplayedByUser) {
      goBackNavigate();
    }
  }, [store.user.settings.isMuliBandsDisplayedByUser]);

  const muliBandsSymbol = store.pages.muliBands.symbol;

  const instrument =
    store.entities.instruments.get<TInstrumentEntity>(muliBandsSymbol) ||
    store.entities.instruments.getAll<TInstrumentEntity>()[0];

  const defaultPeriod = store.pages.muliBands.chartPeriod;
  const generalTab = store.pages.muliBands.generalTab;

  useLayouts(instrument.symbol);

  const handleChangeSymbol = useCallback((value: string) => {
    store.pages.muliBands.runInAction(() => {
      store.pages.muliBands.symbol = value;
    });
  }, []);

  const renderFullChartContainer = useMemo(
    () => ({
      ordersAndPositions: <Assets navigationKey='' />,
      alerts: <Assets selectedTab='price-alerts' navigationKey='' />,
    }),
    [generalTab.id]
  );

  useEffect(() => {
    store.entities.instruments.getInstrumentSpecificationAsync.run(
      instrument.symbol
    );
    store.pages.muliBands.getInitialDataAsync.run(instrument.symbol);

    const interval = setInterval(() => {
      store.pages.muliBands.pingMuliBandAsync.run();
    }, pingStrategyInterval);

    return () => {
      store.pages.muliBands.deleteMuliBandAsync.run();
      clearInterval(interval);
    };
  }, [instrument.symbol, idToken]);

  useEffect(() => {
    store.entities.ordersMetrics.getOrdersMetricsAsync.run({
      shouldClearBeforeMerge: true,
    });
    store.user.getPortfolioDemoAsync.run();
    store.user.getProfileDemoAsync.run();
    store.entities.challenges.getChallengesAsync.run();
    return () => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewSettings);
    };
  }, []);

  if (!instrument) {
    return (
      <Styled.Root>
        <TopBar />
        <Styled.Main />
        <BottomBar />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <TopBar withChartLayoutsSelection={false} />
      <Styled.Body>
        <Styled.Main>
          <InstrumentDetailsHeader
            onChangeSymbol={handleChangeSymbol}
            hasDropDownSearch
            searchPlaceholder={'COMMON.LABELS.SEARCH_INSTRUMENT'}
            instrument={{
              ...instrument,
              layoutNumber: EChartLayouts.FirstLayout,
            }}
          />

          {generalTab.id &&
            generalTab.type === EGeneralTabContainerType.FullChart &&
            renderFullChartContainer[generalTab.id]}

          {!store.pages.muliBands.getInitialDataAsync.inProgress &&
            generalTab.type !== EGeneralTabContainerType.FullChart && (
              <React.Fragment>
                <InfoBlocks />
                <AdvanceChart
                  isMuliBands
                  hasVolume
                  timeframe='1D'
                  extraDisabledFeatures={[
                    'timeframes_toolbar',
                    'header_resolutions',
                    'main_series_scale_menu',
                    'property_pages',
                  ]}
                  defaultPeriod={defaultPeriod}
                  instrumentSymbol={instrument.symbol}
                  layoutNumber={EChartLayouts.FirstLayout}
                />
              </React.Fragment>
            )}
        </Styled.Main>
        <RightMenu />
      </Styled.Body>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
