export const headerHeight = 55;
export const footerHeight = 45;
export const searchInstrumentHeight = 56;

export const instrumentsPanelWidth = 75;
export const sideBarWidth = 410;
export const betaMaxSideBarWidth = 360;
export const sideBarClosedWidth = 65;
export const rightBarWidth = 250;

export const layoutMargin = 11;

export const layoutChildrenRadius = 5;

export const modalHorizontalPadding = 18;
