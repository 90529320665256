import {
  getMaxStopLoss,
  getMaxTakeProfitRate,
  getMinStopLoss,
  getMinTakeProfitRate,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';

export const useGetMinMaxValues = () => {
  const store = useMst();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const maxTakeProfit = +getMaxTakeProfitRate(
    instrument.stopsLevel,
    instrument.tickSize,
    instrument.pipSize,
    trading.side,
    instrument.bid,
    instrument.pipSize
  );

  const minTakeProfit = +getMinTakeProfitRate(
    instrument.stopsLevel,
    instrument.tickSize,
    instrument.pipSize,
    trading.side,
    instrument.ask,
    instrument.pipSize
  );

  const minStopLoss = +getMinStopLoss(
    instrument.stopsLevel,
    instrument.tickSize,
    instrument.pipSize,
    trading.side,
    instrument.ask,
    instrument.pipSize
  );
  const maxStopLoss = +getMaxStopLoss(
    instrument.stopsLevel,
    instrument.tickSize,
    instrument.pipSize,
    trading.side,
    instrument.bid,
    instrument.pipSize
  );

  return { minTakeProfit, maxTakeProfit, minStopLoss, maxStopLoss };
};
