import React, { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { usePurchase } from '@trader/hooks';
import {
  formatNumber,
  getMarketValue,
  getRequiredOpenCostQuantity,
  getTotalValue,
} from '@trader/utils';

import * as Styled from './styled';

export const minimumPriceFractionDigits = 2;

export const PurchaseDetails: React.FC = () => {
  const store = useMst();
  const { translate } = useI18next();
  const purchase = usePurchase();
  const { getValues } = useFormContext();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const quantity = getValues().amount;
  const price = getValues().price;
  const side = getValues().side;

  const accountCurrencySymbol: string = store.user.portfolio.currencySymbol;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const amount = useMemo(
    () => getRequiredOpenCostQuantity(quantity, instrument.minOrderSize),
    [quantity, instrument.minOrderSize]
  );

  const requiredMargin = trading?.requiredMargin || 0;
  const currencyConvert = trading?.currencyConvert || 0;

  const quantityNumber = Number(quantity);
  const priceNumber = Number(price);

  const marketValue = getMarketValue(
    side,
    instrument.ask,
    instrument.bid,
    amount,
    instrument.pipSize
  );

  const totalValue = getTotalValue(
    priceNumber ? priceNumber * quantityNumber : marketValue,
    currencyConvert,
    instrument.currencySymbol,
    accountCurrencySymbol
  );

  const convertReqMargin = useMemo(() => {
    if (currencyConvert !== 1) {
      const resultVolume = requiredMargin * currencyConvert;
      return `${instrument.currencySymbol}${formatNumber(resultVolume, {
        minimumFractionDigits: minimumPriceFractionDigits,
      })} = ${accountCurrencySymbol}${formatNumber(requiredMargin, {
        minimumFractionDigits: minimumPriceFractionDigits,
      })}`;
    }
    return `${instrument.currencySymbol}${formatNumber(requiredMargin, {
      minimumFractionDigits: minimumPriceFractionDigits,
    })}`;
  }, [requiredMargin, currencyConvert]);

  const renderError = useMemo(() => {
    if (!trading.isRequiredOpenCostError) {
      return;
    }

    const isShowDepositFunds =
      !purchase.shouldHideButtonOnCreateOrder && !purchase.isDisabled;

    return (
      <Fragment>
        <Styled.FundsTitle>
          {translate('COMMON.LABELS.INSUFFICIENT_FUNDS')}
        </Styled.FundsTitle>
        {isShowDepositFunds && (
          <Styled.FundsButton onClick={purchase.action}>
            {translate('COMMON.LABELS.DEPOSIT_FUNDS')}
          </Styled.FundsButton>
        )}
      </Fragment>
    );
  }, [trading.isRequiredOpenCostError, purchase]);

  return (
    <Styled.Root>
      <Styled.TextWrapper>
        <Styled.TotalValue>
          {translate('COMMON.LABELS.TOTAL_VALUE')}:{' '}
          {currencyConvert ? totalValue : translate('COMMON.LABELS.N_A')}
        </Styled.TotalValue>
      </Styled.TextWrapper>
      {!isInvestmentAccProduct && (
        <Styled.TextWrapper>
          <Styled.RequiredMarginTitle>
            {translate('COMMON.LABELS.REQUIRED_MARGIN')}:
          </Styled.RequiredMarginTitle>
          <Styled.RequiredMarginValue>
            {quantityNumber ? convertReqMargin : 0}
          </Styled.RequiredMarginValue>
        </Styled.TextWrapper>
      )}
      <Styled.TextWrapper mt={3}>{renderError}</Styled.TextWrapper>
    </Styled.Root>
  );
};
