import React from 'react';
import { observer } from 'mobx-react-lite';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { isLiveEnv } from '@trader/constants';

import { DevModeSelection } from './devModeSelection';
import * as Styled from './styled';

interface ILogin {
  bgImage?: string;
  children: (
    getRecaptchaBeforeSubmit: () => Promise<string>
  ) => React.ReactNode;
  title?: string;
  subtitle?: string;
}

export const Login: React.FC<ILogin> = observer(
  ({ children, bgImage, ...rest }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { translate } = useI18next();
    const store = useMst();

    const {
      title = translate('SIGN_IN.WELCOME_BACK'),
      subtitle = translate('SIGN_IN.LOGIN_TO_YOUR_TRADING_ACCOUNT'),
    } = rest;

    const getRecaptchaBeforeSubmit = async (): Promise<string> => {
      if (!executeRecaptcha) {
        return '';
      }

      return await executeRecaptcha(`Recaptcha_${store.app.brand}`);
    };

    return (
      <Styled.Root>
        <LazyLoadImage alt='background' src={bgImage} />
        <Styled.Main>
          <Styled.Logo iconType={store.app.getLoginIconBasedOnTheme()} />
          <Styled.Title>{title}</Styled.Title>
          <Styled.Subtitle>{subtitle}</Styled.Subtitle>
          {!isLiveEnv && <DevModeSelection />}
          {children(getRecaptchaBeforeSubmit)}
          <Styled.DontHaveAccount
            variant='text'
            fullWidth
            onClick={() => {
              window.open(import.meta.env.VITE_SIGN_UP_LINK);
            }}
          >
            {translate('SIGN_IN.DONT_HAVE_ACCOUNT')}{' '}
            <span>{translate('SIGN_IN.CREATE_ACCOUNT')}</span>
          </Styled.DontHaveAccount>
        </Styled.Main>
      </Styled.Root>
    );
  }
);
