import { types } from 'mobx-state-tree';
import {
  betaMaxSideBarWidth,
  isLiveEnv,
  sideBarWidth,
} from '@trader/constants';

const sidebarModel = types
  .model('sidebarModel', {
    leftBarWidth: types.number,
    isLeftBarShrinked: types.boolean,
    rightBar: types.model('rightBarModel', {
      orderSection: types.model('orderSectionModel', {
        isOpen: types.boolean,
      }),
      infoSection: types.model('orderSectionModel', {
        isOpen: types.boolean,
      }),
    }),
  })
  .actions(store => ({
    updateLeftBarWidth(value: number) {
      store.leftBarWidth = value;
    },
    expandLeftBar() {
      store.isLeftBarShrinked = false;
    },
    shrinkLeftBar() {
      store.isLeftBarShrinked = true;
    },
    openRightBarOrderSection() {
      store.rightBar.orderSection.isOpen = true;
    },
    closeRightBarOrderSection() {
      store.rightBar.orderSection.isOpen = false;
    },
    openRightBarInfoSection() {
      store.rightBar.infoSection.isOpen = true;
    },
    closeRightBarInfoSection() {
      store.rightBar.infoSection.isOpen = false;
    },
    isRightBarHidden() {
      return (
        !store.rightBar.orderSection.isOpen &&
        !store.rightBar.infoSection.isOpen
      );
    },
    getRightBarOpenedSectionsCount() {
      let count = 0;
      if (store.rightBar.orderSection.isOpen) count++;
      if (store.rightBar.infoSection.isOpen) count++;
      return count;
    },
  }));

// TODO(ok): remove leftBarWidth ternary after add new design
export const sidebar = types.optional(sidebarModel, {
  leftBarWidth: isLiveEnv ? sideBarWidth : betaMaxSideBarWidth,
  isLeftBarShrinked: false,
  rightBar: {
    orderSection: {
      isOpen: false,
    },
    infoSection: {
      isOpen: false,
    },
  },
});
