import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 192px;
    min-width: 192px;
    // 162 means topBar height + bottomBar height + layoutMargin * 4
    height: calc(100vh - 162px);
    overflow: scroll;
    margin-left: ${layoutMargin}px;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};
  `}
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    width: 261px;
    height: 645px;
    background: rgba(46, 48, 63, 0.7);
    position: absolute;
    right: 215px;
    bottom: 67px;
    z-index: ${theme.zIndex.modal + 1};
    cursor: move;
  `};
`;
