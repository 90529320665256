import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { Icon } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

type TRemovingType = 'orders' | 'positions';

export const RemoveAllMetrics: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const type: TRemovingType = store.ui.modal.options.get('removingType');

  const removingTitle = {
    orders: translate('COMMON.LABELS.ORDERS'),
    positions: translate('COMMON.LABELS.POSITIONS'),
  };

  const handleAction = useMemo(
    () => ({
      orders: () => store.entities.ordersMetrics.cancelAllOrdersAsync.run(),
      positions: () =>
        store.entities.positionsMetrics.closeAllPositionsAsync.run(),
    }),
    [type]
  );

  return (
    <Styled.Root>
      <Styled.Title>
        <Icon iconType='redCloseInCircle' />
      </Styled.Title>
      <Styled.Content>
        {translate('COMMON.LABELS.YOU_SURE')}{' '}
        <p>
          {translate('COMMON.LABELS.CLOSE_ALL')} {removingTitle[type]}?
        </p>
      </Styled.Content>
      <Styled.Body>
        <Styled.Cta size='medium' $isFailed onClick={store.ui.modal.close}>
          {translate('COMMON.LABELS.CANCEL')}
        </Styled.Cta>
        <Styled.Cta size='medium' onClick={() => handleAction[type]()}>
          {translate('COMMON.LABELS.YES_SURE')}
        </Styled.Cta>
      </Styled.Body>
    </Styled.Root>
  );
});
