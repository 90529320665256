import { requestService, TAsyncActionOptions } from '@trader/services';
import { userRoutes } from './routes';
import {
  IGetTradingAccountsParams,
  IUserBalance,
  IUserPortfolio,
  IUserProfile,
  IUserSettings,
  IUserTradingAccount,
} from './models';

export class User {
  routes = userRoutes;

  async getUserProfile(options: TAsyncActionOptions): Promise<IUserProfile> {
    const resp = await requestService.get<IUserProfile>(
      this.routes.getProfile,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getUserPortfolio(platformLogin?: string): Promise<IUserPortfolio> {
    const resp = await requestService.get<IUserPortfolio>(
      this.routes.getPortfolio,
      {
        params: {
          platformLogin,
        },
      }
    );

    return resp?.data;
  }

  async getUserBalance(options: TAsyncActionOptions): Promise<IUserBalance> {
    const resp = await requestService.get<IUserBalance>(
      this.routes.getBalance,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getUserSettings(options: TAsyncActionOptions): Promise<IUserSettings> {
    const resp = await requestService.get<IUserSettings>(
      this.routes.getSettings,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async updateUserSettings(
    isTradingCentral: boolean,
    isEdgeX: boolean,
    isEdgeZone: boolean,
    options: TAsyncActionOptions
  ): Promise<IUserSettings> {
    const resp = await requestService.put<IUserSettings>(
      this.routes.getSettings,
      {
        isTradingCentral,
        isEdgeX,
        isEdgeZone,
      },
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getTradingAccounts(
    options: TAsyncActionOptions,
    params?: IGetTradingAccountsParams
  ): Promise<Array<IUserTradingAccount>> {
    const resp = await requestService.get<Array<IUserTradingAccount>>(
      this.routes.getTradingAccounts,
      {
        ...options.cancelToken,
        params: {
          includePortfolio:
            params?.includePortfolio !== undefined
              ? params.includePortfolio
              : true,
          includePositions:
            params?.includePositions !== undefined
              ? params.includePositions
              : true,
        },
      }
    );

    return resp?.data;
  }

  async changeTradingAccount(
    platformLogin: string,
    options: TAsyncActionOptions
  ): Promise<Array<IUserTradingAccount>> {
    const resp = await requestService.post<Array<IUserTradingAccount>>(
      this.routes.postTradingAccount(platformLogin),
      {},
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }
}
