import { getGoalReached, getOverAllPl } from '@trader/utils';
import { api } from '@trader/api';
import { createThunk } from '../../utils/asyncModel';
import { EChallenge } from '@trader/types';
import { getRootInstance } from '../../configureStore/configureStore';
import { TTradingAccountEntity } from '../tradingAccounts';
import { IChallengeEntitySnapshotOut } from './index';
import { challengesSchema } from './schemas';

export const getChallengesAsync = createThunk<void, void>(
  () =>
    async function getChallenges(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const root = getRootInstance();

      const response = await api.Challenge.getChallenges(_options);

      response.forEach(challenge => {
        if (!challenge.hierarchyType) {
          challenge.hierarchyType = EChallenge.NonAssigned;
        }
      });

      const challengesEntity: IChallengeEntitySnapshotOut[] = [];

      for (const ch of response) {
        const tradingAccount =
          root.entities.tradingAccounts.get<TTradingAccountEntity>(
            ch.accountId
          );

        const portfolio = await api.User.getUserPortfolio(
          tradingAccount?.platformLogin
        );

        const challenge: IChallengeEntitySnapshotOut = {
          ...ch,
          profitLoss24: portfolio.dailyPl,
          profitLossOverall: getOverAllPl(
            portfolio.balance,
            ch.challengeAmount,
            portfolio.pl
          ),
          challengeAmount: ch.challengeAmount || 0,
          purchaseAmount: ch.purchaseAmount || 0,
          platformLogin: ch.platformLogin,
          balance: portfolio.balance,
          currencySymbol: portfolio.currencySymbol,
          goalReached: getGoalReached(
            ch.profitTarget,
            getOverAllPl(portfolio.balance, ch.challengeAmount, portfolio.pl),
            ch.challengeAmount
          ),
        };
        challengesEntity.push(challenge);
      }

      root.entities.normalizeMerge(challengesEntity, challengesSchema, true);

      challengesEntity.forEach(ch =>
        root.entities.tradingAccounts.update(ch.accountId, {
          challenge: ch.id,
        })
      );
    }
);
