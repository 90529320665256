import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, PropTypes, TabsProps } from '@mui/material';

import { useWindowDimensions } from '@trader/hooks';
import { breakpoints } from '@trader/themes';
import { TIconsName } from '@trader/assets';
import { useMst } from '@trader/store';

import { Icon } from '../icon';

import * as Styled from './styled';

interface ITabPanel {
  compareValue: string;
  value: string;
  children: React.ReactNode;
}

interface ITabLabel {
  tab: ITab;
}

export interface ITabs extends TabsProps {
  tabs: ITab[];
  color?: PropTypes.Color;
}

interface ITab {
  label?: string;
  value: string;
  children: React.ReactNode | string;
  icon?: React.ReactElement | string;
  disabled?: boolean;
  isDefault?: boolean;
}

export const TradingTabs: React.FC<ITabs> = observer(
  ({ tabs, color = 'default', ...args }) => {
    const store = useMst();
    const { width } = useWindowDimensions();

    const breakpointSm = breakpoints?.values?.sm as number;
    const orientation = width > breakpointSm ? 'vertical' : 'horizontal';

    const tab = store.pages.trading.selectedCategory?.name;

    const [selectedTab, setSelectedTab] = React.useState<string>(
      tabs[0]?.value
    );

    const handleChange = useCallback(
      (_event: React.SyntheticEvent, newValue: string): void => {
        setSelectedTab(newValue);
        store.pages.trading.selectCategoryAsync.run(newValue);
      },
      []
    );

    return (
      <Styled.Container orientation={orientation}>
        <Styled.AppBar color={color}>
          <Styled.Tabs
            variant='scrollable'
            scrollButtons={false}
            value={tab || selectedTab}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            {...args}
            orientation={orientation}
          >
            {tabs.map(t => (
              <Styled.Tab
                key={t.value}
                label={<Label tab={t} />}
                value={t.value}
                disabled={t.disabled}
              />
            ))}
          </Styled.Tabs>
        </Styled.AppBar>
        {tabs.map(t => (
          <TabPanel
            key={t.value}
            value={tab || selectedTab}
            compareValue={t.value}
          >
            {t.children}
          </TabPanel>
        ))}
      </Styled.Container>
    );
  }
);

const Label: React.FC<ITabLabel> = ({ tab }) => {
  return (
    <Styled.Label>
      <Icon iconType={tab.icon as TIconsName} style={{ marginBottom: 8 }} />
      {tab.label}
    </Styled.Label>
  );
};

const TabPanel: React.FC<ITabPanel> = ({ value, compareValue, children }) => {
  return (
    <Styled.Panel hidden={value !== compareValue}>
      {value === compareValue && <Box height={'100%'}>{children}</Box>}
    </Styled.Panel>
  );
};
