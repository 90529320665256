import React, { useCallback } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';

import { useMst } from '@trader/store';
import { urlHelpers } from '@trader/utils';
import { signalrReconnectTimeout } from '@trader/constants';

import { useConnection } from './useConnection';
import { EConnectionHub, EConnectionHubSubscriber } from './types';

type TUseHub<T> = {
  url: string;
  hub: EConnectionHub;
  subscriber: EConnectionHubSubscriber;
  invokedName: string;
  onMessage: (message: T) => void;
};

type TSend = (connection: HubConnection | null) => Promise<void>;

const BASE_URL = import.meta.env.VITE_SIGNALR_URL;
export const useHub = <T>({
  url,
  hub,
  subscriber,
  onMessage,
  invokedName,
}: TUseHub<T>) => {
  const store = useMst();
  const formattedUrl = urlHelpers.getBaseUrlWithApiType(
    BASE_URL,
    store.app.apiType
  );
  const { getConnection, closeConnection, idToken } = useConnection(
    formattedUrl + url,
    hub,
    subscriber
  );

  const setTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  const unsubscribe = useCallback(
    async (send?: TSend) => {
      const connection = await getConnection();

      if (connection?.state === HubConnectionState.Connected) {
        send && (await send(connection));
        closeConnection();
      }
    },
    [getConnection, idToken]
  );

  const subscribe = useCallback(
    async (send?: TSend, onSubscribeMessage?: (message: T) => void) => {
      const connection = await getConnection();

      if (setTimerRef.current) {
        clearTimeout(setTimerRef.current);
        setTimerRef.current = null;
      }

      try {
        if (connection?.state === HubConnectionState.Connected) {
          send && (await send(connection));
          connection?.on(invokedName, onSubscribeMessage || onMessage);

          connection?.onclose(error => {
            error && subscribe(send, onSubscribeMessage);
          });
        } else {
          throw Error("Connection is not in the 'Connected' State.");
        }
      } catch (err) {
        if (err) {
          // eslint-disable-next-line require-atomic-updates
          setTimerRef.current = setTimeout(
            () => subscribe(send, onSubscribeMessage),
            signalrReconnectTimeout
          );
        }
      }
    },
    [getConnection, unsubscribe, invokedName, onMessage, idToken, setTimerRef]
  );

  return {
    closeConnection,
    subscribe,
    unsubscribe,
  };
};
