export class UrlHelpers {
  /**
   * Replaces the subdomain in the provided URL with the specified API type.
   *
   * @param {string} url - The original URL whose subdomain will be replaced.
   * @param {string} apiType - The API type to be used in the subdomain.
   * @returns {string} - The modified URL with the new API type in the subdomain.
   *
   * @example
   * getBaseUrlWithApiType('https://mobileapigateway.staging.tplus.io', 'dev');
   * // Returns: 'https://mobileapigateway.dev.tplus.io'
   */
  getBaseUrlWithApiType(url: string, apiType: string) {
    return url.replace(/\.([^.]+)\./, `.${apiType}.`);
  }
}

export const urlHelpers = new UrlHelpers();
