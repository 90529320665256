import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  hasCopierFunctionality,
  isChallengeAccountType,
} from '@trader/constants';
import { EChallenge } from '@trader/types';
import { useI18next } from '@trader/services';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { GoalReached, Icon } from '@trader/components';

import * as Styled from './styled';

interface IBottomItemPart {
  tradingAccount: TTradingAccountEntity;
}

export const BottomItemPart: React.FC<IBottomItemPart> = observer(
  ({ tradingAccount }) => {
    const { translate } = useI18next();
    const store = useMst();

    const accountId = store.user.getAccountChallenge(
      tradingAccount.tradingId
    )?.accountId;
    //TODO: temporary hide remainingTime (it's customer needs)

    // const remainingTime = store.user.getAccountChallenge(
    //   tradingAccount.tradingId
    // )?.remainingTime;
    const goalReached = store.user.getAccountChallenge(
      tradingAccount.tradingId
    )?.goalReached;

    const renderIconAccountType = useMemo(() => {
      if (hasCopierFunctionality) {
        const challenge = store.user.getAccountChallenge(
          tradingAccount.tradingId
        );
        const iconType =
          challenge?.hierarchyType === EChallenge.Master
            ? 'accountTypeMaster'
            : challenge?.hierarchyType === EChallenge.Slave
            ? 'accountTypeSlave'
            : null;

        return iconType && <Icon iconType={iconType} />;
      }
      return null;
    }, []);

    if (isChallengeAccountType) {
      return (
        <Styled.ChallengeBody>
          <span>ID: {accountId}</span>
          <div>
            {renderIconAccountType}
            {/*<RemainingTime value={remainingTime} />*/}
            <GoalReached value={goalReached} />
          </div>
        </Styled.ChallengeBody>
      );
    }

    return (
      <Styled.Content>
        {tradingAccount.isManagedMaster
          ? translate('COMMON.LABELS.MASTER_ACCOUNT')
          : tradingAccount.product}{' '}
        -{' '}
        {translate(`COMMON.LABELS.${tradingAccount.accountType.toUpperCase()}`)}{' '}
        - {tradingAccount.currency}
        <span>{renderIconAccountType}</span>
      </Styled.Content>
    );
  }
);
