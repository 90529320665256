import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';
import { Typography } from '../typography';
import { Button } from '../button';
import { modalHorizontalPadding } from '@trader/constants';

interface IMargin {
  $bottomMargin?: string;
}

export const Root = styled(Wrapper)<IMargin>`
  ${({ theme, $bottomMargin }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${$bottomMargin};
    justify-content: flex-start;
    border-bottom: 1px solid ${theme.palette.background.default};
    padding: 0 ${modalHorizontalPadding}px 11px ${modalHorizontalPadding}px;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 700;
    margin-right: 10px;
    color: ${theme.palette.common.black};
  `}
`;
export const Close = styled(Button)`
  ${() => css`
    line-height: 18px;
    font-size: 14px;
    width: 160px;
    height: 31px;
    display: flex;
    flex-direction: column;
  `}
`;
