/* eslint-disable no-magic-numbers */
import { StudyInputValue } from '../charting_library';

export const getVwapInputValues = (
  inputs: <T extends StudyInputValue>(index: number) => T
) => ({
  source: inputs(0) as
    | 'open'
    | 'close'
    | 'high'
    | 'low'
    | 'hl2'
    | 'hlc3'
    | 'ohlc4',
  useMaStrategy: inputs(1) as boolean,
  maBasisType: inputs(2) as
    | 'high'
    | 'low'
    | 'sma'
    | 'wma'
    | 'smma'
    | 'swma'
    | 'vwma',
  maSignalType: inputs(3) as
    | 'high'
    | 'low'
    | 'sma'
    | 'wma'
    | 'smma'
    | 'swma'
    | 'vwma',
  maRatio: inputs(4) as number,
  maMultiplier: inputs(5) as number,
});
