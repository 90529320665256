import React from 'react';
import { observer } from 'mobx-react-lite';

import { TOrderMetricEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { TPlaceOrderType } from '@trader/types';

import * as Styled from './styled';

interface IPlaceOrderButton {
  isDisabled: boolean;
  orderType: TPlaceOrderType;
  buttonTitle?: string;
  onClick?: () => void;
}

export const PlaceOrderButton: React.FC<IPlaceOrderButton> = observer(
  ({ isDisabled, buttonTitle = 'COMMON.LABELS.PLACE_ORDER' }) => {
    const store = useMst();
    const { translate } = useI18next();

    const trading = store.trading;

    const orderId = store.ui.modal?.options.get('orderId');

    const metric =
      store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);

    const isInProgress =
      store.trading.prePlaceOrderAsync.inProgress ||
      (metric && metric.editOrderAsync.inProgress);

    return (
      <Styled.Root>
        <Styled.PlaceOrder
          type='submit'
          loading={isInProgress}
          disabled={isInProgress || isDisabled || trading.isOrderInPending}
        >
          <Styled.Title>
            {trading.isOrderInPending
              ? translate('COMMON.LABELS.PROCESSING')
              : translate(buttonTitle)}
          </Styled.Title>
        </Styled.PlaceOrder>
      </Styled.Root>
    );
  }
);
