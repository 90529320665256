import { HubConnection } from '@microsoft/signalr';
import { EChallengeResult, EChallengeStatus } from '@trader/types';

export enum EConnectionHub {
  Account = 'account',
  Quotes = 'quotes',
  Positions = 'positions',
  TradingAccount = 'tradingAccount',
  TradingView = 'tradingView',
  Layouts = 'layouts',
  Inboxes = 'inboxes',
  MuliBands = 'strategy',
  LocalPortfolio = 'localPortfolio',
  Auth = 'Auth',
}

export enum ESocketUpdateAction {
  Create = 0,
  Update = 1,
  Delete = 2,
}

export enum EConnectionHubSubscriber {
  Positions,
  PositionsExchangeRate,
  PositionsCurrentPrice,
  Portfolio,
  Quotes,
  TradingView,
  Layouts,
  Challenges,
  TradingAccount,
  TradingAccountPositionsCurrentPrice,
  TradingAccountPositionsExchangeRate,
  Orders,
  CustomerAccount,
  MuliBands,
  TradeResults,
  Auth,
}

export type TConnectionCache = {
  lastIdToken: string;
  subscribers: {
    [key in EConnectionHub]: { [sk in EConnectionHubSubscriber]: true };
  };
  connections: { [key in EConnectionHub]: null | HubConnection };
};

export interface IMessageTradingAccount {
  platformLogin: string;
  product: string;
  accountType: number;
  currency: string;
  currencySymbol: string;
  tradingId: number;
  isDepositAllowed: boolean;
  isTradingAllowed: boolean;
  updateAction: ESocketUpdateAction;
}

export interface IMessageCustomerAccount {
  isRegistrationCompleted: boolean;
}

export interface IMessageChallenge {
  id: number;
  status: EChallengeStatus;
  amount: number | null;
  accountId: number;
  result: EChallengeResult;
  payout: number | null;
  tradingDays: number | null;
  minTradingDays: number | null;
  remainingTime: number | null;
  updateAction: ESocketUpdateAction;
  blockedDueToDailyTarget: boolean;
}
