import { TUserStore, useMst } from '@trader/store';
import { EConditionType, TApplyCondition } from './index';

export const useFreeScoreLevelBusinessLogic = (
  conditionType: EConditionType
): TApplyCondition => {
  const store = useMst();
  const profile: TUserStore['profile'] = store.user.profile;

  const isDemoInUse: boolean = store.user.isDemoAccInUse();

  const isFirstCaseCondition =
    conditionType === EConditionType.RegistrationCompleted &&
    !profile.isRegistrationCompleted;

  const isThirdCaseCondition =
    conditionType === EConditionType.TradingAllowed &&
    !profile.isTradingAllowed;

  const isFourthCaseCondition =
    conditionType === EConditionType.DepositAllowed &&
    !profile.isDepositAllowed;

  return () => {
    if (isFirstCaseCondition) {
      return {
        status: isDemoInUse ? 'passed' : 'failed',
        action: () => {
          window.open(import.meta.env.VITE_REGISTRATION_LINK);
        },
      };
    }

    if (isThirdCaseCondition) {
      return {
        status: 'failed',
        action: () => {
          window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        },
      };
    }

    if (isFourthCaseCondition) {
      return {
        status: 'failed',
        action: () => {
          window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        },
      };
    }

    return {
      status: 'passed',
    };
  };
};
