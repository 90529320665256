import { ReactNode } from 'react';

import {
  hasCopierFunctionality,
  isChallengeAccountType,
  isMuliBandsEnv,
  LOCAL_STORAGE_KEYS,
  modifyIconsPerCategory,
  NAVIGATE_TO,
} from '@trader/constants';
import { TCategoryEntity, useMst } from '@trader/store';
import { localStorageService, useI18next } from '@trader/services';
import { TIconsName } from '@trader/assets';
import { useNavigation } from '@trader/hooks';

interface IMenuItem {
  children: ReactNode | string;
  value?: string;
  id: number | string;
  icon?: TIconsName;
  disabled: boolean;
  action: (value?: string) => void;
}

type TGetMenu = (onCloseMenu?: () => void) => Array<IMenuItem>;

export const useGetMenuList = () => {
  const store = useMst();
  const { translate } = useI18next();
  const { navigateTo } = useNavigation();

  const categories = store.entities.categories.getAll<TCategoryEntity>();

  const getMenu: TGetMenu = onCloseMenu => {
    const menu: Array<IMenuItem> = [
      {
        children: translate('COMMON.LABELS.STATISTICS'),
        id: 2,
        icon: 'statistics',
        disabled: true,
        action: () => {
          onCloseMenu && onCloseMenu();
          store.ui.modal.open('statistics');
        },
      },
      {
        children: translate('COMMON.LABELS.MY_HISTORY'),
        id: 3,
        icon: 'myHistory',
        disabled: false,
        action: () => {
          onCloseMenu && onCloseMenu();
          store.ui.modal.open('myHistory');
        },
      },
      {
        children: translate('COMMON.LABELS.MY_ACCOUNT'),
        id: 4,
        icon: 'myAccount',
        disabled: false,
        action: () => {
          onCloseMenu && onCloseMenu();
          navigateTo(NAVIGATE_TO.main.myAccount, {
            state: {
              disableTradingNavigation: true,
              disableMultipleLayouts: true,
            },
          });
        },
      },
      {
        children: translate('COMMON.LABELS.WITHDRAW'),
        id: 5,
        icon: 'withdraw',
        disabled: false,
        action: () => {
          window.open(import.meta.env.VITE_WITHDRAWAL_LINK);
        },
      },
      {
        children: translate('COMMON.LABELS.SETTINGS'),
        id: 6,
        icon: 'settings',
        disabled: false,
        action: () => {
          onCloseMenu && onCloseMenu();
          store.ui.modal.open('settings');
        },
      },
      {
        children: translate('COMMON.LABELS.SIGN_OUT'),
        id: 7,
        icon: 'signOut',
        disabled: false,
        action: () => {
          onCloseMenu && onCloseMenu();
          store.auth.logOut();
        },
      },
    ];

    if (isChallengeAccountType) {
      menu.unshift({
        children: translate('COMMON.LABELS.PURCHASE_CHALLENGE'),
        id: 8,
        icon: 'purchaseChallenge',
        disabled: false,
        action: () => window.open(import.meta.env.VITE_REGISTRATION_LINK),
      });
    }

    if (isMuliBandsEnv) {
      menu.unshift({
        children: translate('COMMON.LABELS.MULI_BANDS_STRATEGY'),
        id: 9,
        disabled: !store.user.settings.isMuliBandsDisplayedByUser,
        action: () => {
          localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewSettings);
          navigateTo(NAVIGATE_TO.main.muliBands);
        },
      });
    }

    if (hasCopierFunctionality) {
      menu.splice(2, 0, {
        children: translate('COPIER.TITLE'),
        id: 10,
        icon: 'copier',
        disabled: false,
        action: () => navigateTo(NAVIGATE_TO.main.copier),
      });
    }

    return menu;
  };

  const getLeftMenu: TGetMenu = onCloseMenu => [
    ...categories.map(category => ({
      id: category.id,
      value: category.name,
      isDefault: category.isDefault,
      icon:
        modifyIconsPerCategory[category.name] ||
        modifyIconsPerCategory.Commodities,
      disabled: false,
      children: category.name,
      action: (categoryName?: string) => {
        onCloseMenu && onCloseMenu();
        store.pages.trading.selectCategoryAsync.run(categoryName);
      },
    })),
    {
      children: translate('COMMON.LABELS.ORDERS_AND_ALERTS'),
      id: 'positionsAndAlerts',
      icon: 'positionsAndAlerts',
      disabled: false,
      action: () => {
        navigateTo(NAVIGATE_TO.main.assets);
        onCloseMenu && onCloseMenu();
      },
    },
  ];

  return {
    getMenu: (cb?: () => void) =>
      import.meta.env.VITE_HASNT_WITHDRAW
        ? getMenu(cb).filter(menu => menu.children !== 'Withdraw')
        : getMenu(cb),
    getLeftMenu,
  };
};
