import styled, { css } from 'styled-components';

import { Wrapper, IconButton } from '@trader/components';

interface IPadding {
  $isNoPaddingLeft?: boolean;
}

export const DesktopContainer = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;
export const Block = styled(Wrapper)`
  ${() => css`
    height: 100%;
    align-items: center;

    .userAction-fullscreen {
      padding: 0 0 0 20px;
    }
  `};
`;

export const User = styled(Wrapper)<IPadding>`
  ${({ theme, $isNoPaddingLeft }) => css`
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    padding: ${$isNoPaddingLeft ? '0 20px 0 0' : '0 20px'};
    font-size: 12px;
    font-weight: 700;
    border-right: 1px solid ${theme.palette.background.default};

    span {
      margin-left: 4px;
      font-weight: 500;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const UserAction = styled(Wrapper)<IPadding>`
  ${({ theme, $isNoPaddingLeft }) => css`
    height: 100%;
    align-items: center;
    text-transform: uppercase;
    padding: ${$isNoPaddingLeft ? '0 20px 0 0' : '0 20px'};
    border-left: 1px solid ${theme.palette.background.default};

    p {
      width: 195px;
      text-align: start;
    }

    .fullscreen {
      text-transform: initial;
      font-size: 12px;
      font-weight: 500;
    }

    span {
      font-size: 12px;
      font-weight: 700;
      margin-right: 4px;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const FullScreenBtn = styled(IconButton)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.gray.light};
    border-radius: 8px;
    width: 30px;
    height: 30px;
    margin-left: 10px;

    &:hover {
      background-color: ${theme.palette.gray.main};
    }
  `};
`;

export const BalanceDetails = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px;
    background: ${theme.palette.common.black};

    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `};
`;

interface IAccountType {
  $isDemo?: boolean;
}

export const AccountType = styled(Wrapper)<IAccountType>`
  ${({ theme, $isDemo }) => css`
    border-radius: 5px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 5px 10px;
    background: ${$isDemo
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};
  `};
`;
