/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */

import { TMuliBandsStore } from '@trader/store';
import { getMaxDigitWithDot, roundToXDigits } from '@trader/utils';

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  StudyColorerPlotInfo,
  StudyLinePlotInfo,
} from '../charting_library';
import {
  baseDefaultsStyles,
  muliBandsInitial,
  muliBandsInputs,
} from '../constants';

const defaultMuliBandsStyles = {
  ...baseDefaultsStyles,
  visible: true,
  linewidth: 1,
  linestyle: 1,
};

let cumVolume = 0;
let cumHlc3 = 0;
let prevBarDay = 0;

const roundedVwapList = {};

let bestStripForShort = '';
let bestStripForLong = '';

const strategy = { ...muliBandsInitial };

export const muliBands = (
  Pine: PineJS,
  store: TMuliBandsStore,
  pipSize = 4,
  tickSize = 0
): CustomIndicator => ({
  name: 'MuliBands',
  metainfo: {
    _metainfoVersion: 51,
    id: 'MuliBands@tv-basicstudies-1' as RawStudyMetaInfoId,
    description: 'MuliBands',
    shortDescription: 'MuliBands',
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      ...Object.values(muliBandsInitial).map(strip => ({
        id: strip.key,
        type: 'line' as StudyLinePlotInfo['type'],
      })),
      {
        id: 'roundedVwapColor',
        type: 'colorer' as StudyColorerPlotInfo['type'],
        target: muliBandsInitial.RoundedVwap.key,
        palette: 'roundedVwapColor',
      },
    ],
    filledAreas: [],
    palettes: {
      roundedVwapColor: {
        colors: {
          0: {
            name: 'Color 0',
          },
          1: {
            name: 'Color 1',
          },
        },
      },
    },

    defaults: {
      filledAreasStyle: {},
      inputs: {
        topMultiplier: 1,
        bottomMultiplier: 1,
        staticTopMultiplier: 1,
        staticBottomMultiplier: 1,
      },
      palettes: {
        roundedVwapColor: {
          colors: {
            0: {
              color: 'rgba(35, 207, 63, 1)',
              width: 2,
              style: 0,
            },
            1: {
              color: 'rgba(255, 0, 0, 1)',
              width: 2,
              style: 0,
            },
          },
        },
      },
      styles: Object.values(muliBandsInitial).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.key]: {
            ...defaultMuliBandsStyles,
            color: curr.color,
          },
        }),
        {}
      ),
    },
    styles: Object.values(muliBandsInitial).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key]: {
          histogramBase: 0,
          title: curr.key,
        },
      }),
      {}
    ),
    inputs: muliBandsInputs,
    format: {
      type: 'price',
      precision: getMaxDigitWithDot(pipSize),
    },
  },
  constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const topMultiplier = this._input(0);
      const bottomMultiplier = this._input(1);
      const staticTopMultiplier = this._input(2);
      const staticBottomMultiplier = this._input(3);

      const volume = Pine.Std.volume(this._context);

      const close = Pine.Std.close(this._context);
      // eslint-disable-next-line
      const instrumentClosePrice = (context.symbol.info as any).closePrice;
      const hlc3 = Pine.Std.hlc3(this._context);
      const currentBarDay = Pine.Std.dayofweek(
        this._context,
        Pine.Std.time(this._context)
      );
      // const currentDate = new Date();
      // currentDate.setSeconds(0, 0);
      // const barDate = new Date(Pine.Std.updatetime(this._context));
      // barDate.setSeconds(0, 0);

      if (prevBarDay !== currentBarDay) {
        cumHlc3 = hlc3 * volume;
        cumVolume = volume;
        Array.from(Object.values(strategy)).forEach(s => {
          strategy[s.key].cross = 0;
        });
      } else {
        cumHlc3 += hlc3 * volume;
        cumVolume += volume;
      }

      const upMultiplier = staticTopMultiplier || 0.001;
      const downMultiplier = staticBottomMultiplier || 0.001;

      prevBarDay = Pine.Std.dayofweek(context, Pine.Std.time(this._context));

      const round = roundToXDigits(getMaxDigitWithDot(pipSize), tickSize);

      strategy.RoundedVwap.value = round(cumHlc3 / cumVolume);
      strategy.Strip1Low.value = round(
        (strategy.RoundedVwap.value -
          5 * downMultiplier * bottomMultiplier +
          strategy.RoundedVwap.value) /
          2
      );
      strategy.Strip2Low.value = round(
        strategy.RoundedVwap.value - 5 * downMultiplier * bottomMultiplier
      );
      strategy.Strip3Low.value = round(
        strategy.RoundedVwap.value - 7.5 * downMultiplier * bottomMultiplier
      );
      strategy.Strip0HalfLow.value = round(
        (strategy.RoundedVwap.value + strategy.Strip1Low.value) / 2
      );
      strategy.Strip025Low.value = round(
        (strategy.RoundedVwap.value + strategy.Strip0HalfLow.value) / 2
      );
      strategy.Strip075Low.value = round(
        (strategy.Strip1Low.value + strategy.Strip0HalfLow.value) / 2
      );
      strategy.Strip1HalfLow.value = round(
        (strategy.Strip2Low.value + strategy.Strip1Low.value) / 2
      );
      strategy.Strip2HalfLow.value = round(
        (strategy.Strip2Low.value + strategy.Strip3Low.value) / 2
      );
      strategy.Strip1High.value = round(
        (strategy.RoundedVwap.value +
          5 * upMultiplier * topMultiplier +
          strategy.RoundedVwap.value) /
          2
      );
      strategy.Strip2High.value = round(
        strategy.RoundedVwap.value + 5 * upMultiplier * topMultiplier
      );
      strategy.Strip3High.value = round(
        strategy.RoundedVwap.value + 7.5 * upMultiplier * topMultiplier
      );
      strategy.Strip0HalfHigh.value = round(
        (strategy.RoundedVwap.value + strategy.Strip1High.value) / 2
      );
      strategy.Strip025High.value = round(
        (strategy.RoundedVwap.value + strategy.Strip0HalfHigh.value) / 2
      );
      strategy.Strip075High.value = round(
        (strategy.Strip1High.value + strategy.Strip0HalfHigh.value) / 2
      );
      strategy.Strip1HalfHigh.value = round(
        (strategy.Strip2High.value + strategy.Strip1High.value) / 2
      );
      strategy.Strip2HalfHigh.value = round(
        (strategy.Strip2High.value + strategy.Strip3High.value) / 2
      );

      const vwapColor = Pine.Std.ge(strategy.RoundedVwap.value, close);

      roundedVwapList[Pine.Std.n(context) + 1] = strategy.RoundedVwap.value;

      if (Pine.Std.n(context) === 0) {
        Array.from(Object.values(strategy)).forEach(s => {
          strategy[s.key].cross = 0;
        });
      }

      if (!context.symbol.isLastBar) {
        Array.from(Object.values(strategy)).forEach(s => {
          strategy[s.key].cross += Pine.Std.cross(s.value, close, context)
            ? 1
            : 0;
        });
      }

      if (context.symbol.isLastBar) {
        const values = Array.from(Object.values(strategy)).map(s => ({
          id: s.key,
          value: s.value,
          change: `${(
            ((s.value - instrumentClosePrice) / instrumentClosePrice) *
            100
          ).toFixed(2)}%`,
          cross: s.cross,
        }));

        const nineBarBackVwap = roundedVwapList[Pine.Std.n(context) - 8];

        const trend =
          strategy.RoundedVwap.value === nineBarBackVwap
            ? 'Flat'
            : strategy.RoundedVwap.value > nineBarBackVwap
            ? 'Higher'
            : 'Lower';

        if (trend !== store.strip.vwapTrend) {
          store.strip.updateVwapTrend(trend);
        }

        strategy.RoundedVwap.value && store.updateStripsValues(values);

        const highStrips = Array.from(Object.values(strategy)).filter(s =>
          s.key.includes('High')
        );
        highStrips.forEach((strip, index) => {
          if (index === highStrips.length && strip.cross > 0) {
            bestStripForShort = strip.bestStripValue;
          }
          if (strip.cross > 0 && highStrips[index + 1]?.cross === 0) {
            bestStripForShort = strip.bestStripValue;
          }
        });

        const lowStrips = Array.from(Object.values(strategy)).filter(s =>
          s.key.includes('Low')
        );
        lowStrips.forEach((strip, index) => {
          if (index === lowStrips.length && strip.cross > 0) {
            bestStripForShort = strip.bestStripValue;
          }
          if (strip.cross > 0 && lowStrips[index + 1]?.cross === 0) {
            bestStripForLong = strip.bestStripValue;
          }
        });

        store.strip.updateLongStrip(bestStripForLong);
        store.strip.updateShortStrip(bestStripForShort);
      }

      return [
        ...Array.from(Object.values(strategy)).map(strip => strip.value),
        vwapColor,
      ];
    };
  },
});
