import { ERouteType, ICustomRouteObject } from '@trader/navigation';
import { NotFound } from '@trader/components';
import { paths } from '@trader/constants';

import { AuthPageLayout } from '../../../scenes/layouts/auth';

export const commonRoutes: ICustomRouteObject[] = [
  {
    element: <NotFound />,
    id: 'commonRoutes_NotFound',
    path: paths.common.notFound,
    routeType: ERouteType.Public,
  },
  {
    // TODO: add accessDenied logic
    element: <AuthPageLayout />,
    id: 'commonRoutes_AccessDenied',
    path: paths.common.accessDenied,
    routeType: ERouteType.Public,
  },
];
