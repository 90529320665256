import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { Wrapper } from '../wrapper';
import { Button } from '../button';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 408px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
  `};
`;
export const Content = styled(Typography)`
  ${() => css`
    font-size: 12px;
    font-weight: 400;
    white-space: normal;
    text-align: center;
  `};
`;
export const Cta = styled(Button)`
  ${({ theme, onClick }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    margin-top: 30px;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    color: ${theme.palette.common.white};
    background: ${theme.palette.primary.main};
    font-weight: 700;
    cursor: ${onClick ? 'pointer' : 'default'};
  `};
`;
