import { Instance, types } from 'mobx-state-tree';

import { EAccountTypeNames } from '@trader/constants';
import { TPlaceOrderSide } from '@trader/types';

import { createCollectionModel } from '../../utils/collectionModel';
import { portfolioModel } from '../../user/portfolio';
import { getTradingAccountsAsync, changeTradingAccountAsync } from './actions';
import { challengeModel } from '../challenges';

const accountPositionModel = types.model('accountPositionModel', {
  positionId: types.string,
  symbol: types.string,
  currency: types.string,
  quantity: types.number,
  side: types.frozen<TPlaceOrderSide>('Buy'),
  openPrice: types.number,
  currentPrice: types.number,
  spreadDiff: types.number,
  swap: types.number,
  conversionRate: types.number,
});

export const tradingAccountModel = types.model('tradingAccountModel', {
  tradingId: types.identifierNumber,
  platformLogin: types.string,
  isInUse: types.boolean,
  product: types.optional(types.string, ''),
  accountType: types.union(
    types.literal(EAccountTypeNames.Live),
    types.literal(EAccountTypeNames.Demo)
  ),
  currency: types.string,
  currencySymbol: types.string,
  isManagedMaster: types.boolean,
  isTradingAllowed: types.boolean,
  isDepositAllowed: types.boolean,
  portfolio: types.maybeNull(portfolioModel),
  // TODO: (av) Convert to map for optimization.
  //            The example is strips field in muliBands. Use types.map.
  positions: types.array(accountPositionModel),
  challenge: types.maybeNull(
    types.late(() => types.safeReference(challengeModel))
  ),
});

interface ITradingAccountsAsync {
  getTradingAccountsAsync: typeof getTradingAccountsAsync;
  changeTradingAccountAsync: typeof changeTradingAccountAsync;
}

export const tradingAccounts = createCollectionModel<
  typeof tradingAccountModel,
  ITradingAccountsAsync
>(tradingAccountModel, {
  getTradingAccountsAsync,
  changeTradingAccountAsync,
});

export type TTradingAccountEntity = Instance<typeof tradingAccountModel>;
export type TTradingAccountsEntity = Instance<typeof tradingAccounts>;
