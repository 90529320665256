import { getIconBaseProps } from './utils';

export const plus = {
  ...getIconBaseProps('plus'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1.33301 8.00004H7.99967M7.99967 8.00004H14.6663M7.99967 8.00004V14.6667M7.99967 8.00004V1.33337'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
