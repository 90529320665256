import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { createCollectionModel } from '../../utils/collectionModel';
import { getCategoriesAsync } from './actions';

const subcategoryModel = types.model('subcategoryModel', {
  id: types.identifierNumber,
  name: types.string,
});

export const categoryModel = types.model('categoryModel', {
  id: types.identifierNumber,
  name: types.string,
  iconUrl: types.maybeNull(types.string),
  hasInstrumentsLayout: types.optional(types.boolean, true),
  isDefault: types.optional(types.boolean, false),
  subCategories: types.array(subcategoryModel),
});

interface ICategoriesAsync {
  getCategoriesAsync: typeof getCategoriesAsync;
}

export const categories = createCollectionModel<
  typeof categoryModel,
  ICategoriesAsync
>(categoryModel, {
  getCategoriesAsync,
});

export type TSubcategoryEntity = Instance<typeof subcategoryModel>;
export type TSubcategoryEntityIn = SnapshotIn<typeof subcategoryModel>;
export type TCategoryEntity = Instance<typeof categoryModel>;
