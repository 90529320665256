import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled('div')`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 450px;
    height: 180px;
    flex-direction: column;
  `}
`;

export const Symbol = styled(Typography)`
  ${() => css`
    font-weight: 700;
    font-size: 15px;
  `}
`;

export const Description = styled(Typography)`
  ${() => css`
    font-weight: 400;
    font-size: 12px;
  `}
`;

export const Info = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    width: 100%;
    padding: 0 ${modalHorizontalPadding}px;
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;

export const InfoLeft = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const InfoRight = styled(Wrapper)`
  ${() => css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `}
`;

export const Placeholder = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 12px;
    color: ${theme.palette.gray.main};
  `}
`;

export const Price = styled(Typography)`
  ${() => css`
    font-weight: 400;
    font-size: 14px;
  `}
`;
