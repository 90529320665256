import { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  fixTotalValue,
} from '@trader/utils';
import { shouldDisplayCreditInPortfolio } from '@trader/constants';
import { usePositionMetricsPl } from '@trader/hooks';
import { Icon, Wrapper, Popover, Tooltip } from '@trader/components';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const BetaDesign = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const [isPortfolioDetailsOpen, setIsPortfolioDetailsOpen] = useState(false);
  const { positionsPl } = usePositionMetricsPl();

  const portfolio = store.user.portfolio;

  const currencyFormatter = (value: number) => {
    if (!value || !Number.isFinite(value)) {
      return '--';
    }

    return `${portfolio.currencySymbol}${value.toFixed(fixTotalValue)}`;
  };

  const percentageFormatter = (value: number) => {
    if (!value || !Number.isFinite(value)) {
      return '--';
    }

    return `${value.toFixed(fixTotalValue)}%`;
  };

  const equity = calcEquity(positionsPl, portfolio.balance, portfolio.credit);
  const portfolioDetailsItems = [
    {
      label: translate('COMMON.LABELS.BALANCE'),
      value: currencyFormatter(portfolio.balance),
      tooltip: translate('TOOLTIPS.BALANCE'),
    },
    {
      label: translate('COMMON.LABELS.EQUITY'),
      value: currencyFormatter(equity),
      tooltip: translate('TOOLTIPS.EQUITY'),
    },
    {
      label: translate('COMMON.LABELS.P_L'),
      value: currencyFormatter(positionsPl),
      tooltip: translate('TOOLTIPS.P_L'),
    },
    {
      label: translate('COMMON.LABELS.FREE_MARGIN'),
      value: currencyFormatter(calcFreeMargin(equity, portfolio.usedMargin)),
      tooltip: translate('TOOLTIPS.FREE_MARGIN'),
    },
    {
      label: translate('COMMON.LABELS.USED_MARGIN'),
      value: currencyFormatter(portfolio.usedMargin),
      tooltip: translate('TOOLTIPS.USED_MARGIN'),
    },
    {
      label: translate('COMMON.LABELS.MARGIN_LEVEL'),
      value: percentageFormatter(calcMarginLevel(equity, portfolio.usedMargin)),
      tooltip: translate('TOOLTIPS.MARGIN_LEVEL'),
    },
  ];

  if (shouldDisplayCreditInPortfolio) {
    portfolioDetailsItems.splice(1, 0, {
      label: translate('COMMON.LABELS.CREDIT'),
      value: currencyFormatter(portfolio.credit),
      tooltip: translate('TOOLTIPS.CREDIT'),
    });
  }

  return (
    <Popover
      behavior='click'
      elevation={4}
      trigger={
        <BalanceValue
          value={currencyFormatter(portfolio.balance)}
          isOpen={isPortfolioDetailsOpen}
        />
      }
      content={<PortfolioDetails items={portfolioDetailsItems} />}
      transformOrigin={{
        vertical: -5,
        horizontal: 130,
      }}
      onOpen={() => setIsPortfolioDetailsOpen(true)}
      onClose={() => setIsPortfolioDetailsOpen(false)}
    />
  );
});

const BalanceValue = ({
  value,
  isOpen,
}: {
  value: string;
  isOpen: boolean;
}) => {
  const { translate } = useI18next();

  return (
    <div style={{ cursor: 'pointer' }}>
      <Styled.Label>{translate('COMMON.LABELS.BALANCE')}</Styled.Label>
      <Wrapper alignItems='center'>
        <Styled.Value>{value}</Styled.Value>
        <Icon
          iconType={isOpen ? 'arrowUp' : 'arrowDown'}
          style={{ marginLeft: '7px' }}
        />
      </Wrapper>
    </div>
  );
};

const PortfolioDetails = ({ items }: { items: Record<string, string>[] }) => {
  const { translate } = useI18next();

  return (
    <Styled.Portfolio>
      <Styled.PortfolioHeader>
        <Styled.PortfolioTitle>
          {translate('COMMON.LABELS.PORTFOLIO')}
        </Styled.PortfolioTitle>
      </Styled.PortfolioHeader>
      <Styled.PortfolioContent spacing='10px'>
        {items.map(item => (
          <Styled.PortfolioItem key={item.label}>
            <Wrapper alignItems='center'>
              <Tooltip title={item.tooltip}>
                <Icon iconType='info' style={{ marginRight: '5px' }} />
              </Tooltip>
              <Styled.PortfolioLabel>{item.label}</Styled.PortfolioLabel>
            </Wrapper>
            <Styled.PortfolioValue>{item.value}</Styled.PortfolioValue>
          </Styled.PortfolioItem>
        ))}
      </Styled.PortfolioContent>
    </Styled.Portfolio>
  );
};
