import { useNavigation } from '@trader/hooks';
import { TTradingAccountEntity, TUserStore, useMst } from '@trader/store';

import { EConditionType, TApplyCondition } from './index';
import { EAccountTypeNames } from '@trader/constants';

export const useWholeBusinessLogic = (
  conditionType: EConditionType
): TApplyCondition => {
  const store = useMst();
  const { navigateTo } = useNavigation();
  const profile: TUserStore['profile'] = store.user.profile;

  const isDemoInUse: boolean = store.user.isDemoAccInUse();
  const demoAccount = store.entities.tradingAccounts
    .getAll<TTradingAccountEntity>()
    .find(acc => acc.accountType === EAccountTypeNames.Demo);

  const isFirstCaseCondition =
    conditionType === EConditionType.RegistrationCompleted &&
    !profile.isRegistrationCompleted;

  const isSecondCaseCondition =
    conditionType === EConditionType.ScoreLevel &&
    profile.isRegistrationCompleted &&
    profile.scoreLevel === 'LOW';

  const isThirdCaseCondition =
    conditionType === EConditionType.TradingAllowed &&
    !profile.isTradingAllowed;

  const isFourthCaseCondition =
    conditionType === EConditionType.DepositAllowed &&
    !profile.isDepositAllowed;

  return () => {
    if (isFirstCaseCondition) {
      return {
        status: isDemoInUse ? 'passed' : 'failed',
        action: () => {
          window.open(import.meta.env.VITE_REGISTRATION_LINK);
        },
      };
    }

    if (isSecondCaseCondition) {
      !isDemoInUse &&
        store.entities.tradingAccounts.changeTradingAccountAsync.run({
          tradingId: demoAccount?.tradingId || 0,
          navigation: navigateTo,
        });
      return {
        status: 'failed',
      };
    }

    if (isThirdCaseCondition || isFourthCaseCondition) {
      return {
        status: 'failed',
        action: () => {
          window.open(`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`);
        },
      };
    }

    return {
      status: 'passed',
    };
  };
};
