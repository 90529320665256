import { TAvailableChallengeEntity, useMst } from '@trader/store';

import { MODAL_TYPES } from '@trader/constants';

import { PurchaseListCard } from './purchaseListCard';
import { PurchaseListDescription } from './purchaseListDescription';
import * as Styled from './styled';

export interface IPurchaseList {
  purchaseType: 'evaluationChallenges' | 'instantChallenges';
  items: TAvailableChallengeEntity[];
}

export const PurchaseList = ({ purchaseType, items }: IPurchaseList) => {
  const store = useMst();

  const handlePurchaseButtonClick = (contentLinkId: number) => {
    store.ui.modal.open(MODAL_TYPES.purchaseChallenge, {
      contentLinkId,
      borderRadius: 12,
    });
  };

  return (
    <Styled.Root>
      <PurchaseListDescription purchaseType={purchaseType} />
      <Styled.Stack>
        {items.map((item, index) => (
          <PurchaseListCard
            key={item.contentLinkId}
            challengeAmount={item.challengeAmount}
            purchaseAmount={item.purchaseAmount}
            purchaseType={purchaseType}
            withChartUpBackground={index % 2 === 0}
            onPurchaseButtonClick={() =>
              handlePurchaseButtonClick(item.contentLinkId)
            }
          />
        ))}
      </Styled.Stack>
    </Styled.Root>
  );
};
