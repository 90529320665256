import styled, { css } from 'styled-components';
import {
  ListItem,
  List as MuiList,
  Drawer as MuiDrawer,
  ListItemButton,
} from '@mui/material';

import { Wrapper, Button, IconButton, Icon } from '@trader/components';

export const Item = styled(ListItem)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.gray.main};
    height: 58px;
    padding: 0;
  `};
`;

export const ItemButton = styled(ListItemButton)`
  ${() => css`
    height: 100%;
    padding-left: 32px;

    & > svg {
      margin-right: 16px;
    }
  `};
`;

export const CategoryIcon = styled(Icon)`
  ${() => css`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `};
`;

export const IconRight = styled(Icon)`
  ${() => css`
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
  `};
`;

export const List = styled(MuiList)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.gray.main};
    margin-top: 10px;
  `};
`;

export const Close = styled(IconButton)`
  ${() => css`
    width: 15px;
    height: 15px;
    position: absolute;
    right: 25px;
    top: 20px;
  `};
`;

export const Children = styled(Wrapper)`
  ${() => css`
    align-items: center;
    font-size: 15px;
    font-weight: 500;
  `};
`;

export const AddFunds = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white} !important;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 90%;
    margin: 0 auto;
    margin-top: 31px;

    &:hover {
      background-color: ${theme.palette.primary.main};
    }
  `};
`;

export const Container = styled(Wrapper)`
  ${() => css`
    width: 325px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
  `};
`;
export const Drawer = styled(MuiDrawer)`
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal};
  `};
`;

export const RootList = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;

    .categories {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    .search-instruments {
      > div {
        background: initial;
      }

      fieldset {
        border-bottom: 1px solid ${theme.palette.gray.main};
      }

      svg {
        width: 24px;
        height: 24px;
        margin-left: 22px;
        margin-right: 5px;
      }

      input::placeholder {
        font-size: 15px;
        font-weight: 500;
      }
    }
  `};
`;
