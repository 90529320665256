import { types } from 'mobx-state-tree';

const profileModel = types.model('profileModel', {
  tradingId: types.number,
  customerId: types.number,
  mt5Id: types.maybeNull(types.number),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.string,
  phone: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  country: types.string,
  isRegistrationCompleted: types.boolean,
  isAcceptedAccountApproved: types.boolean,
  isTradingAllowed: types.boolean,
  isDepositAllowed: false,
  complianceStage: types.string,
  scoreLevel: types.string,
  blockedDueToDailyTarget: types.boolean,
});

export const initialProfile = {
  tradingId: 0,
  customerId: 0,
  mt5Id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  country: '',
  isRegistrationCompleted: false,
  isAcceptedAccountApproved: false,
  isTradingAllowed: false,
  isDepositAllowed: false,
  complianceStage: '',
  scoreLevel: '',
  blockedDueToDailyTarget: false,
};

export const profile = types.optional(profileModel, initialProfile);
