import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  confirmButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    textDecoration: 'none',
    fontSize: '13px',
    padding: '10px 20px',
    borderRadius: '5px',
    background: '#212121',
    color: '#FFFFFF',
  },
});
