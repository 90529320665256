import { useEffect } from 'react';

import {
  getAccountTypeForConnection,
  isChallengeAccountType,
  productId,
} from '@trader/constants';
import { useMst } from '@trader/store';

import { EConnectionHub, EConnectionHubSubscriber } from './core/types';
import { useHub } from './core/useHub';
import { getGoalReached, getOverAllPl } from '@trader/utils';
import { EChallengeStatus } from '@trader/types';

interface IMessage {
  marginFree: number;
  freeMargin: number;
  balance: number;
  equity: number;
  marginLevel: number;
  profit: number;
  credit: number;
  brand: number;
  login: string;
  pl: number;
}

/**
 * Custom hook that manages portfolio updates by subscribing
 * to a hub and handling incoming messages to update the store.
 */
export const usePortfolio = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken || '';
  const product = store.user.getAccountProduct();

  const handleUpdate = (message: IMessage) => {
    const portfolio = {
      equity: message.equity,
      balance: message.balance,
      pl: message.profit,
      freeMargin: message.marginFree,
      usedMargin: message.equity - message.marginFree,
      marginLevel: message.marginLevel,
      credit: message.credit,
    };
    store.user.updatePortfolio<typeof portfolio>(portfolio, message.login);
    if (isChallengeAccountType) {
      const activeChallenge = store.user.tradingAccount?.challenge;

      if (!activeChallenge) {
        return;
      }

      if (
        store.user.tradingAccount?.challenge?.status ===
        EChallengeStatus.Disable
      ) {
        return;
      }

      if (
        Number(store.user.tradingAccount?.platformLogin) ===
        Number(message.login)
      ) {
        store.entities.challenges.update(activeChallenge.id, {
          profitLossOverall: getOverAllPl(
            message.balance,
            activeChallenge.challengeAmount,
            message.profit
          ),
          goalReached: getGoalReached(
            activeChallenge.profitTarget,
            getOverAllPl(
              message.balance,
              activeChallenge.challengeAmount,
              message.profit
            ),
            activeChallenge.challengeAmount
          ),
        });
      }
    }
  };

  const activeTradingAccount = store.user.activeTradingAcc();
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/v2/account',
    hub: EConnectionHub.Account,
    subscriber: EConnectionHubSubscriber.Portfolio,
    onMessage: handleUpdate,
    invokedName: 'portfolio',
  });

  useEffect(() => {
    if (platformLogin && accountType && product) {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnPortfolio',
          platformLogin,
          productId[product],
          getAccountTypeForConnection[accountType]
        );
      });
    }
    return () => {
      if (platformLogin && accountType && product) {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromPortfolio',
            platformLogin,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken, product]);
};
