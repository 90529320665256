import { getIconBaseProps } from './utils';

export const minus = {
  ...getIconBaseProps('minus'),
  width: '16px',
  height: '2px',
  viewBox: '0 0 16 2',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M1.33301 1H14.6663'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
