import {
  cast,
  types,
  Instance,
  getRoot,
  IAnyStateTreeNode,
} from 'mobx-state-tree';

import { runInAction } from '@trader/utils';
import { EAccountTypeNames, productId, productName } from '@trader/constants';

import { TChallengeEntity } from '../entities/challenges';
import { initialPortfolio, portfolio } from './portfolio';
import { initialProfile, profile } from './profile';
import { initialSettings, settings } from './settings';
import {
  getPortfolioDemoAsync,
  getProfileDemoAsync,
  makeDepositAsync,
  getUserBalanceAsync,
  getSettingsAsync,
  getPspsAsync,
  getTransactionsAsync,
  getTransfersHistoryAsync,
  updateSettingsAsync,
  getPurchaseHistoryAsync,
  getAvailablePaymentMethodsAsync,
  getDepositLinkAsync,
} from './actions';
import {
  tradingAccountModel,
  TTradingAccountEntity,
} from '../entities/tradingAccounts';

export const userModel = types
  .model('userModel', {
    portfolio: portfolio,
    profile: profile,
    settings: settings,
    tradingAccount: types.safeReference(tradingAccountModel),
    getPortfolioDemoAsync,
    getProfileDemoAsync,
    makeDepositAsync,
    getPspsAsync,
    getTransactionsAsync,
    getSettingsAsync,
    updateSettingsAsync,
    getUserBalanceAsync,
    getTransfersHistoryAsync,
    getPurchaseHistoryAsync,
    getAvailablePaymentMethodsAsync,
    getDepositLinkAsync,
  })
  .views(state => ({
    getActiveAccountChallenge: (): TChallengeEntity | null =>
      state.tradingAccount?.challenge || null,
    getAccountChallenge: (accountId: number): TChallengeEntity => {
      const root: IAnyStateTreeNode = getRoot(state);
      return root.entities.tradingAccounts.get(accountId)?.challenge;
    },
    isDemoAccInUse: () =>
      state.tradingAccount?.accountType === EAccountTypeNames.Demo,
    activeTradingAcc: (): TTradingAccountEntity =>
      state.tradingAccount || {
        platformLogin: '',
        accountType: EAccountTypeNames.Demo,
        product: '',
        currency: '',
        currencySymbol: '',
        tradingId: 0,
        isInUse: false,
        isManagedMaster: false,
        isTradingAllowed: false,
        isDepositAllowed: false,
        portfolio: null,
        positions: cast([]),
        challenge: null,
      },
    getAccountProduct: () => {
      return state.tradingAccount?.product || import.meta.env.VITE_PRODUCT;
    },
    isInvestmentAccProduct: () => {
      const product =
        state.tradingAccount?.product || import.meta.env.VITE_PRODUCT;
      const name = productName[productId[product]];

      return name === productName[productId.Investment];
    },
  }))
  .actions(state => ({
    runInAction,
    reset: () => {
      state.portfolio = initialPortfolio;
      state.profile = initialProfile;
      state.settings = initialSettings;
      state.getPortfolioDemoAsync.hasEverBeenRun = false;
    },
    updatePortfolio: <T extends IAnyStateTreeNode = object>(
      payload: T,
      platformLogin: string
    ) => {
      if (
        Number(state.tradingAccount?.platformLogin) === Number(platformLogin)
      ) {
        state.portfolio = {
          ...state.portfolio,
          ...payload,
        };
      }
    },
  }));

export const user = types.optional(userModel, {});

export type TUserStore = Instance<typeof userModel>;
