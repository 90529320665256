import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { localStorageService } from '@trader/services';

export interface INotificationMessage {
  title?: string;
  body?: string;
}

class NotificationService {
  private readonly _vapidKey: string;

  constructor(vapidKey: string) {
    this._vapidKey = vapidKey;
  }

  private _registerMessagingServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(() => {
          console.info('Messaging service worker registered.');
        })
        .catch(err => {
          console.error('Messaging service worker registration failed:', err);
        });
    }
  }

  async requestBrowserPermission() {
    try {
      await Notification.requestPermission();

      if (Notification.permission === 'granted') {
        this._registerMessagingServiceWorker();
      } else {
        console.warn('Unable to get permission to notify.');
      }
    } catch (error) {
      console.error('Error getting permission:', error);
    }
  }

  async proceedMessagingToken() {
    const token = await getToken(getMessaging(), { vapidKey: this._vapidKey });
    localStorageService.set(
      LOCAL_STORAGE_KEYS.tokens.firebaseMessagingToken,
      token
    );
    console.info('Messaging token is received.');
  }

  handleMessaging(onReceived: (message: INotificationMessage) => void) {
    onMessage(getMessaging(), payload =>
      onReceived({
        title: payload.data?.['pinpoint.notification.title'],
        body: payload.data?.['pinpoint.notification.body'],
      })
    );
  }
}

export const notificationService = new NotificationService(
  import.meta.env.VITE_FCM_VAPID_KEY
);
