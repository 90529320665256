import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from '@trader/components';
import { useMst } from '@trader/store';

import * as Styled from '../../styled';

export const InfoSection: FC<{ openedSectionsCount: number }> = observer(
  ({ openedSectionsCount }) => {
    const store = useMst();

    if (!store.ui.sidebar.rightBar.infoSection.isOpen) {
      return null;
    }

    return (
      <Styled.Section $openedSectionsCount={openedSectionsCount}>
        <Styled.CloseIcon>
          <IconButton
            iconType='remove'
            onClick={() => store.ui.sidebar.closeRightBarInfoSection()}
          />
        </Styled.CloseIcon>
        Info Section will be here.
      </Styled.Section>
    );
  }
);
