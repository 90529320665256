import { IChartingLibraryWidget, StudyInputId } from '../../charting_library';
import React, { useCallback, useEffect } from 'react';
import { useMst } from '@trader/store';
import { isMuliBandsEnv } from '@trader/constants';
import { EMultiplier } from '@trader/types';

export const useUpdateMultipliers = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();

  const updateMultipliers = useCallback(() => {
    const studyId = widget?.current
      ?.activeChart()
      ?.getAllStudies()
      .find(x => x.name === 'MuliBands')?.id;

    if (
      studyId &&
      store.pages.muliBands.staticTopMultiplier &&
      store.pages.muliBands.staticBottomMultiplier
    ) {
      const muliBands = widget?.current?.activeChart().getStudyById(studyId);
      muliBands &&
        muliBands.setInputValues([
          {
            id: 'topMultiplier' as StudyInputId,
            value: store.pages.muliBands.topMultiplier,
          },
          {
            id: 'bottomMultiplier' as StudyInputId,
            value: store.pages.muliBands.bottomMultiplier,
          },
          {
            id: 'staticTopMultiplier' as StudyInputId,
            value:
              store.pages.muliBands.staticTopMultiplier[
                EMultiplier[store.pages.muliBands.multiplier]
              ],
          },
          {
            id: 'staticBottomMultiplier' as StudyInputId,
            value:
              store.pages.muliBands.staticBottomMultiplier[
                EMultiplier[store.pages.muliBands.multiplier]
              ],
          },
        ]);
    }
  }, [widget?.current]);

  useEffect(() => {
    if (isMuliBandsEnv && isMuliBands) {
      widget?.current?.onChartReady(() => {
        updateMultipliers();
        widget?.current
          ?.activeChart()
          .onDataLoaded()
          .subscribe(null, () => {
            updateMultipliers();
          });
      });
    }
  }, [
    widget?.current,
    store.pages.muliBands.topMultiplier,
    store.pages.muliBands.bottomMultiplier,
    store.pages.muliBands.multiplier,
  ]);
};
