/* eslint-disable no-magic-numbers */
import {
  CustomIndicator,
  IPineSeries,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  StudyLinePlotInfo,
  StudySourceInputInfo,
} from '../charting_library';
import { vwapDefaultInputs } from './defaultInputs';
import {
  movingAverageDefaults,
  movingAverageFilledAreas,
  movingAveragePallets,
  movingAveragePlots,
  movingAverageStyles,
  getMovingAverageValues,
  movingAverageInputs,
} from './movingAverage';
import { getType, TGetTypeResult } from '../utils/getType';
import { getVwapInputValues } from './vwapInputValues';
import { baseDefaultsStyles } from '../constants';

const length = 100;

const vwapKeys = {
  close: 'vwapClose',
  high: 'vwapHigh',
  low: 'vwapLow',
  highestClose: 'vwapHighestClose',
  highestHigh: 'vwapHighestHigh',
  highestLow: 'vwapHighestLow',
  lowestClose: 'vwapLowestClose',
  lowestHigh: 'vwapLowestHigh',
  lowestLow: 'vwapLowestLow',
} as const;

export const vwap = (Pine: PineJS): CustomIndicator => ({
  name: 'EdgeZone',
  metainfo: {
    _metainfoVersion: 51,
    id: 'EdgeZone@tv-basicstudies-1' as RawStudyMetaInfoId,
    description: 'EdgeZone',
    shortDescription: 'EdgeZone',
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      {
        id: vwapKeys.close,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.high,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.low,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.highestClose,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.highestHigh,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.highestLow,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.lowestClose,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.lowestHigh,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      {
        id: vwapKeys.lowestLow,
        type: 'line' as StudyLinePlotInfo['type'],
      },
      ...movingAveragePlots,
    ],
    filledAreas: [...movingAverageFilledAreas],
    palettes: {
      ...movingAveragePallets,
    },

    defaults: {
      filledAreasStyle: {
        ...movingAverageDefaults.filledAreasStyle,
      },
      palettes: {
        ...movingAverageDefaults.palettes,
      },
      styles: {
        [vwapKeys.close]: baseDefaultsStyles,
        [vwapKeys.high]: baseDefaultsStyles,
        [vwapKeys.low]: baseDefaultsStyles,
        [vwapKeys.highestClose]: {
          ...baseDefaultsStyles,
          color: 'rgb(68,224,16)',
        },
        [vwapKeys.highestHigh]: {
          ...baseDefaultsStyles,
          color: 'rgb(68,224,16)',
        },
        [vwapKeys.highestLow]: {
          ...baseDefaultsStyles,
          color: 'rgb(68,224,16)',
        },
        [vwapKeys.lowestClose]: {
          ...baseDefaultsStyles,
          color: 'rgb(224,16,89)',
        },
        [vwapKeys.lowestHigh]: {
          ...baseDefaultsStyles,
          color: 'rgb(224,16,89)',
        },
        [vwapKeys.lowestLow]: {
          ...baseDefaultsStyles,
          color: 'rgb(224,16,89)',
        },
        ...movingAverageDefaults.styles,
      },
      inputs: vwapDefaultInputs,
    },
    styles: {
      [vwapKeys.close]: {
        histogramBase: 0,
        title: 'Close VWAP',
      },
      [vwapKeys.high]: {
        histogramBase: 0,
        title: 'High VWAP',
      },
      [vwapKeys.low]: {
        histogramBase: 0,
        title: 'Low VWAP',
      },
      [vwapKeys.highestClose]: {
        histogramBase: 0,
        title: 'Hi-Close VWAP 50%',
      },
      [vwapKeys.highestHigh]: {
        histogramBase: 0,
        title: 'Hi-High VWAP 50%',
      },
      [vwapKeys.highestLow]: {
        histogramBase: 0,
        title: 'Hi-Low VWAP 50%',
      },
      [vwapKeys.lowestClose]: {
        histogramBase: 0,
        title: 'Lo-Close VWAP 50%',
      },
      [vwapKeys.lowestHigh]: {
        histogramBase: 0,
        title: 'Lo-High VWAP 50%',
      },
      [vwapKeys.lowestLow]: {
        histogramBase: 0,
        title: 'Lo-Low VWAP 50%',
      },
      ...movingAverageStyles,
    },
    inputs: [
      {
        id: 'source',
        name: 'Source',
        type: 'source' as StudySourceInputInfo['type'],
        defval: 'close',
      },
      ...movingAverageInputs,
    ],
    format: {
      type: 'price',
      precision: 4,
    },
  },
  constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const inputValues: ReturnType<typeof getVwapInputValues> =
        getVwapInputValues(this._input);

      const source: IPineSeries = context.new_var(
        Pine.Std[inputValues.source](context)
      );

      const valuesFromType: TGetTypeResult = getType({
        context,
        source,
        Pine,
      });

      // Moving Average constants
      const basisLength = (
        inputValues.maRatio * inputValues.maMultiplier
      ).toFixed(0);
      const signalLength = inputValues.maMultiplier;
      const valueFromSignalType: number = valuesFromType[
        inputValues.maSignalType
      ](Number(signalLength));
      const valueFromBasisType: number = valuesFromType[
        inputValues.maBasisType
      ](Number(basisLength));

      const MAShort = valueFromBasisType;
      const MALong = valueFromSignalType;

      const closeSource: IPineSeries = context.new_var(Pine.Std.close(context));
      const highSource: IPineSeries = context.new_var(Pine.Std.high(context));
      const lowSource: IPineSeries = context.new_var(Pine.Std.low(context));

      const volume = Pine.Std.volume(context);
      const cumulativeVolume = Pine.Std.cum(volume, context);

      const closePrice = Pine.Std.close(context);
      const cumulativeCloseSum = Pine.Std.cum(closePrice * volume, context);

      const highPrice = Pine.Std.high(context);
      const cumulativeHighSum = Pine.Std.cum(highPrice * volume, context);

      const lowPrice = Pine.Std.low(context);
      const cumulativeLowSum = Pine.Std.cum(lowPrice * volume, context);

      const highestClosePrice = Pine.Std.highest(closeSource, length, context);
      const highestHighPrice = Pine.Std.highest(highSource, length, context);
      const highestLowPrice = Pine.Std.highest(lowSource, length, context);

      const lowestClosePrice = Pine.Std.lowest(closeSource, length, context);
      const lowestHighPrice = Pine.Std.lowest(highSource, length, context);
      const lowestLowPrice = Pine.Std.lowest(lowSource, length, context);

      const closeVwap = cumulativeCloseSum / cumulativeVolume;
      const highVwap = cumulativeHighSum / cumulativeVolume;
      const lowVwap = cumulativeLowSum / cumulativeVolume;

      const highestCloseVwap = (highestClosePrice + closeVwap) / 2;
      const highestHighVwap = (highestHighPrice + highVwap) / 2;
      const highestLowVwap = (highestLowPrice + lowVwap) / 2;

      const lowestCloseVwap = (lowestClosePrice + closeVwap) / 2;
      const lowestHighVwap = (lowestHighPrice + highVwap) / 2;
      const lowestLowVwap = (lowestLowPrice + lowVwap) / 2;

      const movingAverage: ReturnType<typeof getMovingAverageValues> =
        getMovingAverageValues({
          context,
          Pine,
          MAShort,
          MALong,
          isMaCrossingStrategy: inputValues.useMaStrategy,
        });

      return [
        closeVwap,
        highVwap,
        lowVwap,
        highestCloseVwap,
        highestHighVwap,
        highestLowVwap,
        lowestCloseVwap,
        lowestHighVwap,
        lowestLowVwap,
        ...movingAverage.result,
      ];
    };
  },
});
