import { formatByPipSize, getPercentOfSecondNumber } from '@trader/utils';

const getInstrumentGrowStatus = (
  currentPrice: number,
  close: number,
  pipSize: number
) => {
  const openCloseDifference = Number(
    formatByPipSize(currentPrice - close, pipSize)
  );
  const isGrow = openCloseDifference > 0;
  const isSame = openCloseDifference === 0;
  return {
    isGrow,
    isSame,
    openCloseDifference,
  };
};

export const getInstrumentDifference = (
  currentPrice: number,
  close: number,
  pipSize: number
) => {
  const { isGrow, isSame, openCloseDifference } = getInstrumentGrowStatus(
    currentPrice,
    close,
    pipSize
  );
  const openCloseDifferenceInPercent = getPercentOfSecondNumber(
    currentPrice,
    close
  );
  return {
    openCloseDifference,
    openCloseDifferenceInPercent,
    isGrow,
    isSame,
  };
};
