import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TextField } from './components/textField';
import { useI18next } from '@trader/services';

import { Checkbox } from '../checkbox';
import * as Styled from './styled';
import {
  createFilterOptions,
  Autocomplete as MuiAutocomplete,
} from '@mui/material';
import { IOption, ISelectEvent } from '@trader/types';

export interface IAutocomplete {
  onChange?: (event: ISelectEvent) => void;
  onHandeSearchableValue?: (value: string) => void;
  value?: IOption[];
  isError?: boolean;
  isMulti?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  options?: IOption[];
  helperText?: string;
  shouldHideControlInfo?: boolean;
  name?: string;
  allowSelectAll?: boolean;
  disabled?: boolean;
  limitTags?: number;
  withCheckbox?: boolean;
  isCreatable?: boolean;
  isSearchable?: boolean;
  groupBy?: (option: IOption) => string;
}

const maxLimitTags = 2;
const initialLength = 50;
const selectAll = { title: 'Select all', value: 'Select all' };
const filter = createFilterOptions<IOption>();

export const Autocomplete: React.FC<IAutocomplete> = React.forwardRef<
  HTMLInputElement,
  IAutocomplete
>(
  (
    {
      options = [],
      value = [],
      name = '',
      onChange,
      isMulti,
      limitTags = maxLimitTags,
      withCheckbox = true,
      allowSelectAll,
      groupBy,
      isCreatable,
      isSearchable,
      onHandeSearchableValue,
      ...props
    },
    ref
  ) => {
    const currentOptions: Array<IOption> = allowSelectAll
      ? [selectAll, ...options]
      : options;
    const { translate } = useI18next();
    const isAllSelected = value.length === options.length;

    const onSelectOptions = (selectedOptions: Array<IOption>) => {
      onChange?.({
        target: {
          name,
          value: selectedOptions,
        },
      });
    };

    const handleSelectAll = () => {
      if (isAllSelected) {
        onSelectOptions([]);
      } else {
        onSelectOptions(options);
      }
    };

    const handleChange = (
      event: React.SyntheticEvent,
      optionsValues: Array<IOption>
    ) => {
      if (isMulti) {
        if (optionsValues.find(o => o.value === selectAll.value)) {
          handleSelectAll();
        } else {
          onSelectOptions(optionsValues);
        }
      } else {
        const singleOption =
          optionsValues.length > 1
            ? [optionsValues[optionsValues.length - 1]]
            : optionsValues.length === 1
            ? [optionsValues[0]]
            : [];
        onSelectOptions(singleOption);
      }
    };

    const filterOptions = (opts, params) => {
      const filtered = filter(
        isSearchable ? currentOptions.slice(0, initialLength) : currentOptions,
        params
      );

      const { inputValue } = params;

      if (isSearchable) {
        onHandeSearchableValue && onHandeSearchableValue(inputValue);
        const result = currentOptions.filter(opt =>
          opt.title.toUpperCase().includes(inputValue.toUpperCase())
        );
        if (inputValue && result.length) {
          return result;
        }
      }

      if (isCreatable) {
        const result = currentOptions.filter(opt => inputValue === opt.title);
        if (inputValue && !result.length) {
          filtered.push({
            value: inputValue,
            title: inputValue,
          });
        }
      }

      return filtered;
    };

    return (
      <MuiAutocomplete
        multiple={true}
        fullWidth={props.fullWidth}
        options={
          isSearchable ? currentOptions.slice(0, initialLength) : currentOptions
        }
        limitTags={limitTags}
        value={value}
        ref={ref}
        groupBy={groupBy}
        disabled={props.disabled}
        disableCloseOnSelect={isMulti}
        onChange={handleChange}
        popupIcon={<ExpandMoreIcon />}
        selectOnFocus={isCreatable}
        clearOnBlur={isCreatable}
        handleHomeEndKeys={isCreatable}
        noOptionsText={
          isCreatable
            ? translate('COMMON.LABELS.PUT_SOMETHING_TO_ADD')
            : translate('COMMON.LABELS.NO_OPTIONS')
        }
        getOptionLabel={(option: IOption) => option.title || ''}
        isOptionEqualToValue={(option, v) => option.value === v.value}
        filterOptions={isCreatable || isSearchable ? filterOptions : undefined}
        renderOption={(prop, option: IOption, { selected }) => {
          const selectedAsync = value?.find(o => o.value === option.value);
          const selectAllProps =
            option.value === selectAll.value
              ? { checked: isAllSelected }
              : {
                  checked: selectedAsync ? true : selected,
                };

          return (
            <Styled.ListItem
              {...prop}
              aria-selected={selectedAsync ? true : selected}
            >
              {withCheckbox && (
                <Checkbox {...selectAllProps} shouldHideControlInfo />
              )}
              <Styled.ListTitle>{option.title}</Styled.ListTitle>
            </Styled.ListItem>
          );
        }}
        renderInput={params => (
          <TextField {...params} label={props.placeholder} {...props} />
        )}
      />
    );
  }
);
