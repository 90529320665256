import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';

import { useMst } from '@trader/store';
import { devLoggerService } from '@trader/services';
import { signalrReconnectTimeout } from '@trader/constants';

import {
  EConnectionHub,
  EConnectionHubSubscriber,
  TConnectionCache,
} from './types';

export const enumValues = <T extends object>(e: T) => {
  const keys = Object.keys(e).filter(v =>
    isNaN(Number(v))
  ) as unknown as (keyof T)[];

  return keys.map(key => e[key]);
};

const cache: TConnectionCache = {
  lastIdToken: '',
  subscribers: enumValues(EConnectionHub).reduce(
    (acc, next) => ({ ...acc, [next]: {} }),
    {} as TConnectionCache['subscribers']
  ),
  connections: enumValues(EConnectionHub).reduce(
    (acc, next) => ({ ...acc, [next]: null }),
    {} as TConnectionCache['connections']
  ),
};

interface IOutput {
  idToken: string;
  closeConnection: () => void;
  getConnection: () => Promise<HubConnection | null>;
}

export const useConnection = (
  url: string,
  hub: EConnectionHub,
  subscriber: EConnectionHubSubscriber
): IOutput => {
  const store = useMst();
  const idToken = store.auth.tokens.idToken || '';

  cache.lastIdToken = idToken;

  const getConnection = useCallback(async () => {
    cache.subscribers[hub][subscriber] = true;

    if (cache.lastIdToken && !cache.connections[hub]) {
      try {
        cache.connections[hub] = new HubConnectionBuilder()
          .withUrl(url, {
            accessTokenFactory: () => cache.lastIdToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .configureLogging(LogLevel.None)
          .build();
      } catch (e) {
        devLoggerService.error('Build connection error', e);
      }
      try {
        await cache.connections[hub]?.start();
        cache.connections[hub]?.onclose(error => {
          if (error) {
            cache.connections[hub]?.state === HubConnectionState.Disconnected &&
              cache.connections[hub]?.start();
          }
        });
      } catch (e) {
        devLoggerService.error('Re-Start connection error', e);
      }
    }

    if (cache.connections[hub]?.state === HubConnectionState.Disconnected) {
      await cache.connections[hub]?.start();

      if (cache.connections[hub]?.state !== HubConnectionState.Connected) {
        return new Promise<HubConnection | null>(resolve => {
          setTimeout(() => {
            resolve(getConnection());
          }, signalrReconnectTimeout);
        });
      }
    }

    return cache.connections[hub];
  }, [hub, subscriber, idToken]);

  const closeConnection = useCallback(() => {
    delete cache.subscribers[hub][subscriber];
    if (isEmpty(cache.subscribers[hub]) && !store.auth.isAuth) {
      if (cache.connections[hub]?.state !== HubConnectionState.Disconnected) {
        cache.connections[hub]?.stop();
      }
      cache.connections[hub] = null;
    }
  }, [hub, subscriber, store.auth.isAuth]);

  return {
    idToken,
    closeConnection,
    getConnection,
  };
};
