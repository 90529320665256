import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { TInstrumentEntity, TOrderMetricEntity, useMst } from '@trader/store';
import { devLoggerService, useI18next } from '@trader/services';
import { TPlaceOrderSide } from '@trader/types';

import {
  IChartingLibraryWidget,
  IOrderLineAdapter,
} from '../../charting_library';
import { checkOnTickSize } from '@trader/utils';

type TCreateOrder = (
  id: number,
  side: TPlaceOrderSide,
  price: number,
  quantity: number,
  stripId: string
) => void;

interface IOrder {
  key: string;
  stripId: string;
  ref: IOrderLineAdapter | undefined;
}

const orderLineLength = 30;
const orders = new Map<string, IOrder>();

export const useOrders = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const { translate } = useI18next();
  const theme = useTheme();

  const ordersMetrics =
    store.entities.ordersMetrics.getAll<TOrderMetricEntity>();
  const strips = Array.from(store.pages.muliBands.strips.values());

  const createLimitOrder: TCreateOrder = (
    id,
    side,
    price,
    quantity,
    stripId
  ) => {
    const key = id.toString();

    const bodyTextColor = (
      side === 'Buy'
        ? theme.palette.purchaseButtons.green
        : theme.palette.purchaseButtons.red
    ) as string;

    const ref = widget?.current
      ?.activeChart()
      .createOrderLine()
      .setCancelTooltip('Cancel order')
      .onCancel('onCancel called', async function () {
        try {
          await store.pages.muliBands.deleteMuliBandSingleOrderAsync.run(
            stripId
          );
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.remove();
          orders.delete(key);
        } catch (e) {
          devLoggerService.error('onCancel called', e);
        }
      })
      .setText(`${side} Limit`)
      .setBodyTextColor(bodyTextColor)
      .setBodyBorderColor(theme.palette.tab.light)
      .setBodyBackgroundColor(theme.palette.white.main)
      .setQuantity(` ${quantity}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setLineColor(theme.palette.tab.light)
      .setLineLength(orderLineLength)
      .setCancelButtonBorderColor(theme.palette.tab.light)
      .setCancelButtonBackgroundColor(theme.palette.tab.light)
      .setCancelButtonIconColor(theme.palette.white.main)
      .onMove(async function () {
        const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(key);
        const instrument = store.entities.instruments.get<TInstrumentEntity>(
          order.symbol
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (checkOnTickSize(this.getPrice(), instrument.tickSize)) {
          return store.notifications.add({
            message: translate('COMMON.ERRORS.TICK_SIZE', {
              tickSize: instrument.tickSize,
            }),
            options: {
              variant: 'warning',
            },
          });
        }

        try {
          await order.editOrderAsync.run({
            orderId: key,
            body: {
              symbol: order.symbol,
              type: order.type,
              side: order.side,
              quantity: order.quantity,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              price: Number(this.getPrice()),
              takeProfit: order.takeProfit || undefined,
              stopLoss: order.stopLoss || undefined,
            },
          });
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.setPrice(order.limitPrice || order.stopPrice);
        }
      })
      .setPrice(price);

    orders.set(key, {
      ...orders.get(key),
      key,
      stripId,
      ref,
    });
  };

  const clearOrders = useCallback(() => {
    orders.clear();
  }, []);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      strips.forEach(strip => {
        if (!strip.orderId) {
          return;
        }

        const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(
          strip.orderId
        );

        if (!order) {
          return;
        }

        const price = order.limitPrice || order.stopPrice || strip.value;

        if (!orders.get(strip.orderId.toString())) {
          createLimitOrder(
            strip.orderId,
            strip.orderSide as TPlaceOrderSide,
            price,
            order.quantity,
            strip.id
          );
        } else {
          strip.value &&
            orders.get(strip.orderId.toString())?.ref?.setPrice(price);
        }
      });
    });
  }, [JSON.stringify(strips), JSON.stringify(ordersMetrics)]);

  // delete order
  useEffect(() => {
    Array.from(orders.values()).forEach((order: IOrder) => {
      const matchedOrder = store.entities.ordersMetrics.get<TOrderMetricEntity>(
        order.key
      );
      if (!matchedOrder) {
        orders.get(order.key)?.ref?.remove();
        orders.delete(order.key);
        store.pages.muliBands.updateStripOrder(order.stripId, null);
      }
    });
  }, [ordersMetrics.length]);

  return {
    clearOrders,
  };
};
