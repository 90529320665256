import { observer } from 'mobx-react-lite';

import {
  ExpandedContent,
  IconButton,
  SearchInstrument,
  Select,
  Subcategories,
  Wrapper,
} from '@trader/components';
import { TCategoryEntity, useMst } from '@trader/store';
import { betaMaxSideBarWidth, defaultCategories } from '@trader/constants';

import { InstrumentsTable } from './components/instrumentsTable';
import * as Styled from './styled';

export const BetaDesign = observer(() => {
  const store = useMst();

  const selectedCategoryName =
    store.pages.trading.selectedCategory?.name || defaultCategories[0].name;
  const fetchedCategories = store.entities.categories.getAll<TCategoryEntity>();
  const categoryOptions = [
    ...(fetchedCategories.length === 0 ? defaultCategories : fetchedCategories),
  ].map(category => ({
    title: category.name,
    value: category.name,
  }));

  const handleCategoryChange = (categoryName: string) => {
    store.pages.trading.selectCategoryAsync.run(categoryName);
  };

  if (store.ui.sidebar.isLeftBarShrinked) {
    return (
      <Styled.Shrinked>
        <IconButton
          iconType='arrowRight'
          onClick={() => {
            store.ui.sidebar.updateLeftBarWidth(betaMaxSideBarWidth);
            store.ui.sidebar.expandLeftBar();
          }}
        />
      </Styled.Shrinked>
    );
  }

  return (
    <ExpandedContent
      width={store.ui.sidebar.leftBarWidth}
      minWidth={100}
      maxWidth={betaMaxSideBarWidth}
      onUpdateWidth={newWidth => store.ui.sidebar.updateLeftBarWidth(newWidth)}
    >
      <SearchInstrument />
      <Wrapper padding='4px 8px' justifyContent='flex-end' alignItems='center'>
        <IconButton
          iconType='shrink'
          onClick={store.ui.sidebar.shrinkLeftBar}
        />
      </Wrapper>
      <Styled.Selection>
        <Select
          options={categoryOptions}
          value={categoryOptions.filter(o => o.value === selectedCategoryName)}
          onChange={event => handleCategoryChange(event.target.value[0].value)}
          sx={{
            '& .MuiSelect-select': {
              padding: '5.5px 32px 5.5px 14px',
            },
          }}
        />
        <Subcategories withTopDivider={false} />
      </Styled.Selection>
      <InstrumentsTable />
    </ExpandedContent>
  );
});
