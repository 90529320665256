import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { MoveableWrapper } from '@trader/components';
import { useMst } from '@trader/store';

import {
  Bands,
  HidingInfo,
  Order,
  GeneralTabs,
  Indicators,
  DataBox,
} from './components';

import * as Styled from './styled';

export const RightMenu: React.FC = observer(() => {
  const store = useMst();
  const dataBoxRef = React.useRef<HTMLDivElement>(null);

  const floatingBoxId = store.pages.muliBands.generalTab.id;

  const renderMovingContainer = useMemo(
    () => ({
      dataBox: <DataBox ref={dataBoxRef} />,
    }),
    [floatingBoxId]
  );

  const getMovingContainerRef = useMemo(
    () => ({
      dataBox: dataBoxRef,
    }),
    [floatingBoxId]
  );

  return (
    <Styled.Root>
      <Bands />
      <HidingInfo title='order' children={<Order />} />
      <HidingInfo title='general tabs' children={<GeneralTabs />} />
      <HidingInfo title='indicators' children={<Indicators />} />
      {floatingBoxId && renderMovingContainer[floatingBoxId]}
      {floatingBoxId && (
        <MoveableWrapper
          elementRefToMove={getMovingContainerRef[floatingBoxId]}
        />
      )}
    </Styled.Root>
  );
});
