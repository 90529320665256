import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { TAlertEntity, useMst } from '@trader/store';
import { dateToUTC, formatNumber } from '@trader/utils';
import { MODAL_TYPES } from '@trader/constants';
import { useI18next } from '@trader/services';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  ScrollingList,
  SymbolWithIcon,
} from '../scrollingList';
import { IconButton } from '../iconButton';

import * as Styled from './styled';

export const AlertsList = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const alerts = store.entities.alerts.getAll<TAlertEntity>();

  useEffect(() => {
    store.entities.alerts.getAlertsAsync.run();
  }, []);

  const cells: Array<IHeadCell> = [
    {
      id: 'symbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 120,
      padding: '0 10px 0 30px',
      align: 'start',
    },
    {
      id: 'name',
      label: translate('COMMON.LABELS.CREATION_DATE'),
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'alertPrice',
      label: translate('COMMON.LABELS.ALERT_WHEN'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'currentPrice',
      label: translate('COMMON.LABELS.CURRENT_PRICE'),
      minWidth: 140,
      align: 'center',
    },
    {
      id: 'actions',
      label: '',
      minWidth: 120,
      align: 'end',
    },
  ];

  const renderActions = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    const openModal = (modalType: keyof typeof MODAL_TYPES) => {
      store.ui.modal.open(modalType, {
        symbol: instrument.symbol,
        alertId: alert.id,
      });
    };

    return (
      <Styled.Actions>
        <IconButton
          iconType='edit'
          onClick={event => {
            event.stopPropagation();
            openModal('createPriceAlert');
          }}
        />
        <IconButton
          iconType='remove'
          onClick={event => {
            event.stopPropagation();
            openModal('deleteAlert');
          }}
        />
      </Styled.Actions>
    );
  };

  const renderAlertPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;
    const rightExpression = alert?.conditions[0]?.rightExpression;
    const defaultAlertPrice = parseFloat(rightExpression?.parameters?.Number);

    return (
      <Styled.Title>
        {instrument?.currencySymbol}
        {defaultAlertPrice
          ? formatNumber(defaultAlertPrice, {
              minimumFractionDigits: instrument?.pipSize,
            })
          : ''}
      </Styled.Title>
    );
  };

  const renderPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    return (
      <Styled.Title>
        {instrument?.currencySymbol}
        {formatNumber(instrument?.currentPrice, {
          minimumFractionDigits: instrument?.pipSize,
        })}
      </Styled.Title>
    );
  };

  const renderRowItemsCb: IRenderRowItemsCallBacks<TAlertEntity> = useMemo(
    () => ({
      symbol: item => {
        const alert = item.row;
        const instrument = alert.conditions[0].instrument;
        return (
          <SymbolWithIcon
            symbol={instrument?.symbol}
            iconUrl={instrument?.iconUrl}
          />
        );
      },
      name: item => `${dateToUTC(new Date(item.value as string))}`,
      alertPrice: renderAlertPrice,
      currentPrice: renderPrice,
      actions: renderActions,
    }),
    []
  );

  return (
    <Styled.Root>
      <ScrollingList<TAlertEntity>
        rows={alerts}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
        totalCount={alerts.length}
      />
    </Styled.Root>
  );
});
