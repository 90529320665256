import React from 'react';

import { ERouteType, ICustomRouteObject } from '@trader/navigation';
import { Progress } from '@trader/components';
import { paths } from '@trader/constants';
import { MainLayout } from '@trader/containers';

import { Dashboard } from '../../../scenes/pages/main/dashboard';
import { TradingLayout } from '../../../scenes/layouts/trading';

const AssetsLazy = React.lazy(() =>
  import('@trader/pages').then(({ Assets }) => ({
    default: Assets,
  }))
);
const MyAccountLazy = React.lazy(() =>
  import('@trader/pages').then(({ MyAccount }) => ({
    default: MyAccount,
  }))
);
const MuliBandsLazy = React.lazy(() =>
  import('@trader/pages').then(({ MuliBands }) => ({
    default: MuliBands,
  }))
);
const CopierLazy = React.lazy(() =>
  import('@trader/pages').then(({ Copier }) => ({
    default: Copier,
  }))
);
const PurchaseChallengesLazy = React.lazy(() =>
  import('@trader/pages').then(({ PurchaseChallenges }) => ({
    default: PurchaseChallenges,
  }))
);
const AccountStatementReportLazy = React.lazy(() =>
  import('@trader/pages').then(({ AccountStatementReport }) => ({
    default: AccountStatementReport,
  }))
);

export const mainRoutes: ICustomRouteObject[] = [
  {
    id: 'mainRoutes_Root',
    path: paths.main.root,
    element: <MainLayout />,
    routeType: ERouteType.Authorized,
    children: [
      {
        id: 'mainRoutes_Root_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_MainLayout',
    path: paths.main.dashboard,
    element: <MainLayout />,
    routeType: ERouteType.Authorized,
    children: [
      {
        id: 'mainRoutes_MainLayout_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_TradingLayout',
    path: paths.main.trading,
    routeType: ERouteType.Authorized,
    element: <TradingLayout />,
    children: [
      {
        id: 'mainRoutes_TradingLayout_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_Assets',
    path: paths.main.assets,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <AssetsLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_My_Account',
    path: paths.main.myAccount,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <MyAccountLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_Muli_Bands',
    path: paths.main.muliBands,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <MuliBandsLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_Copier',
    path: paths.main.copier,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <CopierLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_PurchaseChallenges',
    path: paths.main.purchaseChallenges,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <PurchaseChallengesLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_AccountStatementReport',
    path: paths.main.accountStatementReport,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <AccountStatementReportLazy />
      </React.Suspense>
    ),
  },
];
