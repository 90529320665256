import { Auth } from './auth';
import { User } from './user';
import { Trading } from './trading';
import { Category } from './category';
import { Instrument } from './instrument';
import { Historical } from './historical';
import { Alert } from './alert';
import { Payment } from './payment';
import { BandStrategy } from './bandStrategy';
import { Challenge } from './challenge';

export type * from './category/models/category';
export type * from './alert/models';
export type * from './auth/models/token';
export type * from './instrument/models/instrument';
export type * from './historical/models';
export type * from './trading/models/ordersMetrics';
export type * from './trading/models/positionsMetrics';
export type * from './trading/models/placeOrder';
export type * from './trading/models/positionsHistory';
export type * from './trading/models/requiredOpenCost';
export type * from './user/models';
export type * from './payment/models';
export type * from './trading/models/costAndCharges';
export type * from './trading/models/accountStatement';
export type * from './challenge/models';

export type {
  IIsMuliBandsStrategy,
  IMuliBandsPlaceOrderBody,
} from './bandStrategy/models';

export class Api {
  Auth = new Auth();
  User = new User();
  Trading = new Trading();
  Alert = new Alert();
  Category = new Category();
  Instrument = new Instrument();
  Historical = new Historical();
  Payment = new Payment();
  BandStrategy = new BandStrategy();
  Challenge = new Challenge();
}

export const api = new Api();
