import { createContext, Context } from 'react';
import { types, Instance } from 'mobx-state-tree';

import { persist } from '../utils/persist';

import { app } from '../app';
import { auth } from '../auth';
import { entities } from '../entities';
import { pages } from '../pages';
import { ui } from '../ui';
import { notifications } from '../notifications';
import { user } from '../user';
import { filters } from '../filters';
import { trading } from '../trading';

const RootStore = types.model({
  app,
  auth,
  user,
  entities,
  pages: types.optional(pages, {}),
  ui,
  notifications,
  filters,
  trading,
});

export const rootStore = RootStore.create();

persist(rootStore);

export interface IRootStoreInstance extends Instance<typeof RootStore> {}

export const rootStoreContext: Context<IRootStoreInstance> =
  createContext<IRootStoreInstance>(rootStore);

export const RootStoreProvider = rootStoreContext.Provider;

export const getRootInstance = (): IRootStoreInstance => rootStore;
