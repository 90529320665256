import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { LAYOUTS_TYPES } from '@trader/constants';
import { EChartLayoutTypes, IOption } from '@trader/types';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { DropdownMenu } from '@trader/components';

import * as Styled from './styled';

export const TradingChartLayoutSelection: React.FC = observer(() => {
  const store = useMst();
  const layoutType = store.pages.trading.layout.layoutType;

  const { translate } = useI18next();
  const location = useLocation();

  const changeLayout = (option: IOption) => {
    store.pages.trading.layout.changeLayout(option.value as EChartLayoutTypes);
  };

  const layouts: Array<IOption> = Object.values(LAYOUTS_TYPES).map(layout => ({
    value: layout.type,
    title: layout.iconType,
    id: layout.type,
  }));

  if (location?.state?.disableMultipleLayouts) {
    return null;
  }

  return (
    <Styled.Container>
      <DropdownMenu
        itemType='icon'
        items={layouts}
        buttonTitle={translate('COMMON.LABELS.SELECT_LAYOUT')}
        onChange={changeLayout}
        selectedOption={{
          value: layoutType,
          title: LAYOUTS_TYPES[layoutType].iconType,
          id: layoutType,
        }}
        isChartLayout={true}
      />
    </Styled.Container>
  );
});
