import { useEffect, useState, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IAccountStatementBE, IGetAccountStatementParams } from '@trader/api';
import { EmptyData, Progress } from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import { recursivelyConvertJsonToPdfComponents } from './utils';
import { HtmlReport } from './components/htmlReport';

export interface IChartData {
  name: number;
  value: number;
}

export interface IAccountStatementAction {
  id: number;
  description: string;
}

const timerInterval = 1000;

export const AccountStatementReport: FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const [searchParams] = useSearchParams();

  const [pdfBalanceChart, setPdfBalanceChart] = useState(null);
  const [accountStatement, setAccountStatement] = useState<
    IAccountStatementBE | undefined
  >();

  let timer;

  const setChartData = () => {
    const chart = document.getElementById('balanceChart');
    if (chart) {
      const chartHtml = chart.outerHTML
        .replace('<title></title>', '')
        .replace('<desc></desc>', '');

      const sv = recursivelyConvertJsonToPdfComponents(chartHtml, true);
      setPdfBalanceChart(sv);
    }

    clearInterval(timer);
  };

  const fetchAccountStatement = async () => {
    const params: IGetAccountStatementParams = {
      from: searchParams.get('from') || '',
      to: searchParams.get('to') || '',
      platformLogin: searchParams.get('platformLogin') || '',
    };

    const resp = await store.pages.trading.getAccountStatementAsync.run(params);

    if (resp) {
      setAccountStatement(resp);
    }
  };

  useEffect(() => {
    timer = setInterval(() => {
      setChartData();
    }, timerInterval);
  }, []);

  useEffect(() => {
    fetchAccountStatement();
  }, []);

  if (
    (!accountStatement &&
      !store.pages.trading.getAccountStatementAsync.isError) ||
    store.pages.trading.getAccountStatementAsync.inProgress
  ) {
    return <Progress />;
  }

  if (store.pages.trading.getAccountStatementAsync.isError) {
    return <EmptyData title={translate('WARNINGS.FETCHING_DATA_FAILED')} />;
  }

  if (!accountStatement) {
    return;
  }

  return (
    <HtmlReport
      from={searchParams.get('from') || ''}
      to={searchParams.get('to') || ''}
      currency={searchParams.get('currency') || ''}
      platformLogin={searchParams.get('platformLogin') || ''}
      product={searchParams.get('product') || ''}
      accountType={searchParams.get('accountType') || ''}
      accountStatement={accountStatement}
      balanceChart={pdfBalanceChart}
    />
  );
});
