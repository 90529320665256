import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  AppBar as MuiAppBar,
  Box as MuiBox,
  Skeleton as MuiSkeleton,
  TabsProps,
} from '@mui/material';
import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';

import { Wrapper } from '../wrapper';
import {
  instrumentsPanelWidth,
  searchInstrumentHeight,
} from '@trader/constants';

interface IContainerProps {
  orientation?: TabsProps['orientation'];
}

export const Tabs = styled(MuiTabs)`
  span {
    text-transform: capitalize;
  }
`;
export const Label = styled(Wrapper)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 5px;
  }
`;

export const Tab = styled(MuiTab)`
  ${({ theme }) => css`
    text-transform: capitalize;
    height: 61px;
    width: 68px;
    min-width: 68px;
    font-size: 10px;
    font-weight: 500;
    border-bottom: 1px solid ${theme.palette.background.default};
    &.Mui-selected {
      background-color: ${theme.palette.primary.main};
      color: white;
    }
  `};
`;

export const Skeleton = styled(MuiSkeleton)`
  ${() => css`
    text-transform: capitalize;
    height: 61px;
    width: 68px;
    min-width: 68px;
    margin: 1px;
  `};
`;

export const SkeletonContainer = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `};
`;

export const Container = styled(Wrapper)<IContainerProps>`
  ${({ theme }) => css`
    flex-grow: 1;
    overflow: hidden;
    flex-direction: row;
    display: flex;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`;

export const Panel = styled(MuiBox)`
  ${({ theme }) => css`
    width: 100%;
    margin-left: ${instrumentsPanelWidth}px;

    ${theme.breakpoints.down('sm')} {
      height: 100%;
      margin-left: 0;
    }
  `}
`;

export const AppBar = styled(MuiAppBar)`
  position: absolute;
  left: 0;
  top: ${searchInstrumentHeight}px;
  width: ${instrumentsPanelWidth}px;
  height: calc(100% - ${searchInstrumentHeight}px);
  justify-content: space-between;
  box-shadow: none;
  ${({ color, theme }) => css`
    border-right: 1px solid ${theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      display: none;
    }

    ${conditionApply(
      [color === 'default'],
      `
          background: transparent;
          `
    )}
  `}
`;
