import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTriggerTradingFormFields } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IBuyButton {
  side: TPlaceOrderSide;
  isAsk?: boolean;
  isDisabled?: boolean;
}

export const BuyButton: React.FC<IBuyButton> = ({
  side,
  isDisabled,
  isAsk,
}) => {
  const store = useMst();
  const { translate } = useI18next();
  const { setValue, reset } = useFormContext();
  const { triggerAllPriceRelatedFields } = useTriggerTradingFormFields();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;
  const isActive = trading.side === side;

  const changeButtonType = () => {
    if (!isDisabled) {
      setValue('side', side);
      reset(
        {
          side: side,
          orderType: trading.orderType,
        },
        { keepValues: true }
      );
      trading.updateSide(side);
      triggerAllPriceRelatedFields();
    }
  };

  return (
    <Styled.ActionButton
      isAsk={isAsk}
      isActive={isActive}
      $isDisabled={isDisabled}
      onClick={changeButtonType}
    >
      <Styled.ButtonTitle isActive={isActive} isAsk={isAsk}>
        {translate(`COMMON.LABELS.${side.toUpperCase()}`)}
      </Styled.ButtonTitle>
      <Styled.ButtonPrice isActive={isActive} isAsk={isAsk}>
        {side !== 'Sell'
          ? formatByPipSize(Number(instrument.bid), instrument.pipSize)
          : formatByPipSize(Number(instrument.ask), instrument.pipSize)}
      </Styled.ButtonPrice>
    </Styled.ActionButton>
  );
};
