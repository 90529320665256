import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { maxMultiplier, minMultiplier, MODAL_TYPES } from '@trader/constants';
import {
  TInstrumentEntity,
  TMuliBandsStore,
  TOrderMetricEntity,
  useMst,
} from '@trader/store';
import { OrderInput, Slider } from '@trader/components';

import * as Styled from './styled';

export const Order: React.FC = observer(() => {
  const store = useMst();
  const muliBands: TMuliBandsStore = store.pages.muliBands;

  const strips = Array.from(store.pages.muliBands.strips.values());
  const hasSomeOrders = strips.some(
    s =>
      s.orderId &&
      store.entities.ordersMetrics.get<TOrderMetricEntity>(s.orderId)
  );

  const instrument =
    store.entities.instruments.get<TInstrumentEntity>(muliBands.symbol) ||
    store.entities.instruments.getAll<TInstrumentEntity>()[0];

  const changeAmount = useCallback((value: string) => {
    muliBands.runInAction(() => {
      muliBands.orderAmount = value;
    });
  }, []);

  const changeTopMultiplier = useCallback(
    (value: number) => {
      if (hasSomeOrders) {
        store.ui.modal.open(MODAL_TYPES.changingMuliBandsMultiplier, {
          topMultiplier: value,
        });
      } else {
        muliBands.runInAction(() => {
          muliBands.topMultiplier = value;
        });
      }
    },
    [hasSomeOrders]
  );

  const changeBottomMultiplier = useCallback(
    (value: number) => {
      if (hasSomeOrders) {
        store.ui.modal.open(MODAL_TYPES.changingMuliBandsMultiplier, {
          bottomMultiplier: value,
        });
      } else {
        muliBands.runInAction(() => {
          muliBands.bottomMultiplier = value;
        });
      }
    },
    [hasSomeOrders]
  );

  return (
    <Styled.Root>
      <OrderInput
        value={muliBands.orderAmount}
        onChange={event => changeAmount(event.target.value)}
        shouldCheckActivityOfButtons
        step={instrument.minOrderSizeIncrement}
        fixDigitAfterDot={0}
        minValue={instrument.minOrderSize}
        maxValue={instrument.maxOrderSize}
        disabled={!muliBands.id}
      />
      <Slider
        label='TOP MULTIPLIER:'
        isLabelValue
        min={minMultiplier}
        max={maxMultiplier}
        step={minMultiplier}
        value={muliBands.topMultiplier}
        onChange={changeTopMultiplier}
        disabled={!muliBands.id}
      />
      <Slider
        label='BOTTOM MULTIPLIER:'
        isLabelValue
        min={minMultiplier}
        max={maxMultiplier}
        step={minMultiplier}
        value={muliBands.bottomMultiplier}
        onChange={changeBottomMultiplier}
        disabled={!muliBands.id}
      />
    </Styled.Root>
  );
});
