export const defaultPipSize = 0.0001;
export const defaultPipsWithTickSize = '1';
export const successTradingCodes = ['0', '10008', '100010', '10009'];

export const tradingReturnCodesErrors = {
  10006: 'WARNINGS.TRADING_REQUEST_REJECTED',
  10014: 'WARNINGS.TRADING_INVALID_VOLUME',
  10015: 'WARNINGS.TRADING_INVALID_PRICE',
  10016: 'WARNINGS.TRADING_WRONG_STOPS_LEVELS_OR_PRICE',
  10019: 'WARNINGS.TRADING_NOT_ENOUGH_MONEY',
  10020: 'WARNINGS.TRADING_PRICE_HAS_CHANGED',
  10023: 'WARNINGS.TRADING_ORDER_HAS_BEEN_CHANGED',
} as const;
