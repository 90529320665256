import styled, { css } from 'styled-components';
import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableFooter,
} from '@mui/material';
import { conditionApply } from '@trader/utils';

const minWidth = 80;

interface IPosition {
  $minWidth?: number;
  $padding?: string;
  $align?: 'center' | 'start' | 'end';
}

export const Root = styled(Table)`
  ${() => css``}
`;

interface IBody {
  $isAddMarginToLastItem: boolean;
}

export const Body = styled(TableBody)<IBody>`
  ${({ theme, $isAddMarginToLastItem }) => css`
    background: ${theme.palette.gray[600]};
    > &:last-child {
      ${conditionApply(
        [$isAddMarginToLastItem],
        css`
          margin-bottom: 13px;
        `
      )}
    }
  `}
`;
export const Footer = styled(TableFooter)`
  ${({ theme }) => css`
    width: 100%;
    position: sticky;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;

    > div {
      background: ${theme.palette.background.default};
      display: flex;
      align-items: center;
      width: 100%;
      height: 42px;

      ${theme.breakpoints.down('sm')} {
        height: 82px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  `}
`;

export const Header = styled(TableRow)`
  ${({ theme }) => css`
    height: 32px;
    background: ${theme.palette.background.default};
    border-bottom: none;
  `}
`;

export const CellValue = styled(TableCell)<IPosition>`
  ${({ theme, $align, $padding, $minWidth }) => css`
    font-size: 11px;
    font-weight: 400;
    padding: ${$padding ? $padding : '0 10px'};
    border-bottom: none;
    color: ${theme.palette.gray.dark};
    text-align: ${$align || 'center'};
    min-width: ${$minWidth || minWidth}px;
  `}
`;

export const RowValue = styled(TableCell)<IPosition>`
  ${({ $align, $padding, $minWidth }) => css`
    min-width: ${$minWidth || minWidth}px;
    text-align: ${$align || 'center'};
    font-size: 11px;
    font-weight: 400;
    padding: ${$padding ? $padding : '0 10px'};
  `}
`;

export const Item = styled(TableRow)`
  ${({ theme }) => css`
    height: 32px;
    border-bottom: 1px solid ${theme.palette.gray.light};
  `}
`;
