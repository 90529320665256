import React from 'react';
import { observer } from 'mobx-react-lite';

import { EChartLayouts, IOption } from '@trader/types';
import { useMst, TInstrumentEntity } from '@trader/store';

import { ChartTiming } from '../chartTiming';

import * as Styled from './styled';

const chantTiming: Array<IOption> = [
  { value: 'Minute1', title: 'M1', id: '1 Minute' },
  { value: 'Minute5', title: 'M5', id: '5 Minutes' },
  { value: 'Minute15', title: 'M15', id: '15 Minutes' },
  { value: 'Minute30', title: 'M30', id: '30 Minutes' },
  { value: 'Hour1', title: 'H1', id: '1 Hour' },
  { value: 'Hour2', title: 'H2', id: '2 Hours' },
  { value: 'Hour4', title: 'H4', id: '4 Hours' },
  { value: 'Day1', title: 'D1', id: '1 Day' },
  { value: 'Week1', title: 'W1', id: '1 Week' },
  { value: 'Month1', title: 'MN', id: '1 Month' },
];

interface IConfigurationMenu {
  layoutNumber: EChartLayouts;
  shouldHideTimeConfig?: boolean;
  instrument: TInstrumentEntity;
  shouldHideActions?: boolean;
}

export const ConfigurationMenu: React.FC<IConfigurationMenu> = observer(
  ({ layoutNumber, shouldHideTimeConfig, instrument, shouldHideActions }) => {
    const store = useMst();

    const layoutType = store.pages.trading.layout.layoutType;

    const selectedTime =
      store.pages.trading.layout.layouts[layoutNumber].configuration.period;

    const handleChangeTime = (option: IOption) => {
      store.pages.trading.layout.layouts.runInAction(() => {
        store.pages.trading.layout.layouts[layoutNumber].configuration.period =
          option.value;
      });
    };

    const openPriceAlertModal = () => {
      store.ui.modal.open('createPriceAlert', { symbol: instrument.symbol });
    };

    return (
      <Styled.Root>
        {!shouldHideTimeConfig && (
          <ChartTiming
            layoutType={layoutType}
            options={chantTiming}
            onChange={handleChangeTime}
            isDisplay
            selectedOption={{
              value: selectedTime,
              title:
                Object.values(chantTiming).find(
                  option => option.value === selectedTime
                )?.title || '',
            }}
          />
        )}

        {!shouldHideActions && (
          <Styled.Actions $layout={layoutType}>
            {instrument?.isFavorite ? (
              <Styled.Button
                onClick={() =>
                  instrument.removeInstrumentFromFavouriteAsync.run(
                    instrument.id
                  )
                }
                size='medium'
                iconType='favoriteOn'
              />
            ) : (
              <Styled.Button
                onClick={() =>
                  instrument.addInstrumentToFavouriteAsync.run(instrument.id)
                }
                size='medium'
                iconType='favouriteOff'
              />
            )}
            <Styled.Button
              size='medium'
              onClick={openPriceAlertModal}
              iconType='priceAlert'
            />
          </Styled.Actions>
        )}
      </Styled.Root>
    );
  }
);
