import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { BalanceDetails, Footer, Time } from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';

import * as Styled from './styled';

export const BottomBar: React.FC = observer(() => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { translate } = useI18next();
  const store = useMst();

  const user = store.user;
  const accountIsInUse = user.activeTradingAcc();
  const isDemo = accountIsInUse?.accountType === EAccountTypeNames.Demo;

  const handleFullScreen = useCallback(() => {
    if (
      !document.fullscreenElement &&
      document.documentElement.requestFullscreen
    ) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  }, []);

  //TODO: temporary hide remainingTime (it's customer needs)
  return (
    <Footer>
      <Styled.DesktopContainer>
        <Styled.Block>
          <Styled.User $isNoPaddingLeft>
            {user.profile.firstName} {user.profile.lastName}
          </Styled.User>
          <Styled.User>
            {translate('COMMON.LABELS.USER_ID')}:
            <span>
              {isChallengeAccountType
                ? accountIsInUse?.tradingId
                : accountIsInUse?.platformLogin}
            </span>
          </Styled.User>
          {!isChallengeAccountType && (
            <Styled.User>
              <Styled.AccountType $isDemo={isDemo}>
                {translate(
                  `COMMON.LABELS.${accountIsInUse?.accountType.toUpperCase()}`
                )}
              </Styled.AccountType>
            </Styled.User>
          )}
        </Styled.Block>
        <Styled.Block>
          <Styled.UserAction>
            <span>{translate('COMMON.LABELS.CURRENT_PLATFORM_TIME')}:</span>{' '}
            <Time />
          </Styled.UserAction>
          <Styled.UserAction className='userAction-fullscreen'>
            <span className='fullscreen'>
              {isFullScreen
                ? translate('COMMON.LABELS.PRESS_FOR_EXIT_FULLSCREEN')
                : translate('COMMON.LABELS.PRESS_FOR_FULLSCREEN')}
            </span>
            <Styled.FullScreenBtn
              onClick={handleFullScreen}
              iconType={isFullScreen ? 'fullScreenExit' : 'fullScreen'}
            />
          </Styled.UserAction>
        </Styled.Block>
      </Styled.DesktopContainer>
      <Styled.BalanceDetails>
        <BalanceDetails isShowDetails />
      </Styled.BalanceDetails>
    </Footer>
  );
});
