import React from 'react';
import { Outlet } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const AuthPageLayout: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_CAPTCHA_KEY}>
      <Outlet />
    </GoogleReCaptchaProvider>
  );
};
