import { defaultPageNumber } from '@trader/constants';

import { IGetInstrumentsBody } from './models/instrument';

export const instrumentRoutes = {
  getRelatedInstuments: '/instrument/related?take=20',
  getInstruments: (params: IGetInstrumentsBody) =>
    params.isFavourite
      ? `/instrument?pageNumber=${
          params.pageNumber || defaultPageNumber
        }&pageSize=30&favorite=true`
      : params.isPopular
      ? `/instrument?&pageNumber=${
          params.pageNumber || defaultPageNumber
        }&pageSize=30&tradingNowType=Popular`
      : `/instrument?categoryId=${params.category}&pageNumber=${
          params.pageNumber || defaultPageNumber
        }&pageSize=30`,
  getExchangeRateInstruments: '/instrument/rate',
  getInstrumentSpecification: '/instrument/specification',
  getInstrumentTradersTrend: '/instrument/traders-trend',
  searchInstuments: '/instrument/search',
  favorite: (instrumentId: number) => `/instrument/favorite/${instrumentId}`,
} as const;
