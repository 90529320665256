import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import {
  localStorageService,
  TLanguageKey,
  useI18next,
} from '@trader/services';
import {
  emptyFn,
  getAccountTypeForConnection,
  LOCAL_STORAGE_KEYS,
  productId,
} from '@trader/constants';
import { Wrapper } from '@trader/components';
import { EChartLayouts } from '@trader/types';
import { useTradingView } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { api, IIndicators, IInstrumentSpecificationBE } from '@trader/api';

import { tradingCentral } from './tradingCentralIndicator';
import { getTradingCentral } from './utils/getTradingCentral';
import { twoMars } from './twoMarsIndicator';
import { IChartWidgetProps } from './types';
import { muliBands } from './muliBands';
import { vwap } from './vwapIndicator';
import { useMuliBands } from './hooks';
import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  IChartingLibraryWidget,
  PineJS,
  ResolutionString,
  ThemeName,
  widget,
} from './charting_library';
import {
  customFormatters,
  defaultStudy,
  disabledFeatures,
  enabledFeatures,
} from './constants';
import Datafeed, {
  invertedResolutionObject,
  resolutionObject,
} from './datafeed';

import './index.css';

interface IAdvanceChart {
  instrumentSymbol: string;
  isMuliBands?: boolean;
  hasVolume?: boolean;
  layoutNumber: EChartLayouts;
  defaultPeriod?: string;
  timeframe?: string;
  extraDisabledFeatures?: string[];
  onIndicators?: (indicators: IIndicators | undefined) => void;
}

export const AdvanceChart: React.FC<IAdvanceChart> = observer(
  ({
    instrumentSymbol,
    layoutNumber,
    onIndicators,
    defaultPeriod,
    extraDisabledFeatures,
    isMuliBands,
    hasVolume,
    timeframe,
  }) => {
    const store = useMst();
    const { translate, currentLng } = useI18next();
    const { subscribe } = useTradingView();
    const activeTradingAccount = store.user.activeTradingAcc();

    const idToken = store.auth.tokens.idToken;
    const product = store.user.getAccountProduct();
    const muliBandsStore = store.pages.muliBands;

    const chartContainerRef =
      useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    const symbol =
      instrumentSymbol ||
      store.pages.trading.getInstrumentSymbolByLayout() ||
      '';
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    const isTradingCentral = store.user.settings.isDisplayedByUser;
    const isEdgeX = store.user.settings.isEdgeXDisplayedByUser;
    const isEdgeZone = store.user.settings.isEdgeZoneDisplayedByUser;

    const interval =
      store.pages.trading.layout.layouts[layoutNumber].configuration.period;

    const languageCode = (currentLng as TLanguageKey) || 'en';
    const time_frames: IChartWidgetProps['time_frames'] = [
      {
        text: '1d',
        resolution: '60' as ResolutionString,
        description: translate('COMMON.LABELS.1_DAY'),
        title: translate('COMMON.LABELS.1_DAY'),
      },
      {
        text: '5d',
        resolution: '60' as ResolutionString,
        description: translate('COMMON.LABELS.5_DAYS'),
        title: translate('COMMON.LABELS.5_DAYS'),
      },
      {
        text: '1m',
        resolution: '120' as ResolutionString,
        description: translate('COMMON.LABELS.1_MONTH'),
        title: translate('COMMON.LABELS.1_MONTH'),
      },
      {
        text: '3m',
        resolution: '240' as ResolutionString,
        description: translate('COMMON.LABELS.3_MONTHS'),
        title: translate('COMMON.LABELS.3_MONTHS'),
      },
      {
        text: '6m',
        resolution: '360' as ResolutionString,
        description: translate('COMMON.LABELS.6_MONTHS'),
        title: translate('COMMON.LABELS.6_MONTHS'),
      },
      {
        text: '1y',
        resolution: '1D' as ResolutionString,
        description: translate('COMMON.LABELS.1_YEAR'),
        title: translate('COMMON.LABELS.1_YEAR'),
      },
    ];
    const getSymbolData = async (smb: string) => {
      return (await instrument.getInstrumentSpecificationAsync.run(
        smb
      )) as IInstrumentSpecificationBE;
    };

    const getTradingCentralData = async (smb: string) => {
      if (isTradingCentral && smb) {
        const response = await api.Historical.getIndicators(smb);
        onIndicators && onIndicators(response);
        return getTradingCentral(response);
      }
      return defaultStudy;
    };

    const renderIndicators = useCallback(
      (Pine: PineJS) => {
        const indicators: CustomIndicator[] = [];
        if (isTradingCentral) {
          indicators.push(tradingCentral());
        }
        if (isEdgeX) {
          indicators.push(twoMars(Pine));
        }
        if (isEdgeZone) {
          indicators.push(vwap(Pine));
        }

        indicators.push(
          muliBands(
            Pine,
            muliBandsStore,
            instrument.pipSize,
            instrument.tickSize
          )
        );

        return Promise.resolve<CustomIndicator[]>(indicators);
      },
      [isTradingCentral, isEdgeX, isEdgeZone, instrument.pipSize]
    );

    const defaultWidgetOptions: Omit<IChartWidgetProps, 'container'> = {
      symbol,
      theme: store.app.themeMode as ThemeName,
      locale: languageCode,
      interval: defaultPeriod
        ? invertedResolutionObject[defaultPeriod]
        : invertedResolutionObject[interval] || ('60' as ResolutionString), // 1 hour
      datafeed: Datafeed(
        subscribe,
        getSymbolData,
        getTradingCentralData,
        {
          description: instrument.description,
          pipSize: instrument.pipSize,
          category: instrument.category,
          close: instrument.close || 0,
          sessions: instrument.sessions || [],
        },
        activeTradingAccount,
        hasVolume
      ) as unknown as ChartingLibraryWidgetOptions['datafeed'],
      library_path: '/charting_library/',
      charts_storage_url: 'https://saveload.tradingview.com',
      client_id: store.app.brand,
      user_id: store.user.profile?.customerId?.toString(),
      fullscreen: true,
      auto_save_delay: 0.1,
      autosize: true,
      timeframe: timeframe || '7D', // 7 days
      disabled_features: (extraDisabledFeatures
        ? [...(disabledFeatures as string[]), ...extraDisabledFeatures]
        : disabledFeatures) as IChartWidgetProps['disabled_features'],
      enabled_features: enabledFeatures,
      time_frames: defaultPeriod ? [] : time_frames,
      custom_formatters: customFormatters,
      custom_indicators_getter: renderIndicators,
      overrides: {
        'paneProperties.legendProperties.showStudyValues': false,
        'mainSeriesProperties.priceAxisProperties.alignLabels': false,
        'mainSeriesProperties.statusViewStyle.symbolTextSource': 'ticker',
        'scalesProperties.showStudyLastValue': false,
        'paneProperties.legendProperties.showBarChange': false,
      },
    };

    useEffect(() => {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeToQuote',
          symbol,
          1,
          productId[product],
          activeTradingAccount.platformLogin,
          getAccountTypeForConnection[activeTradingAccount.accountType]
        );
      });
    }, [idToken]);

    useEffect(() => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.onChartReady(() => {
          widgetRef?.current?.setSymbol(
            symbol,
            defaultWidgetOptions.interval,
            emptyFn
          );
        });
      }
    }, [symbol]);

    useEffect(() => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.onChartReady(() => {
          widgetRef?.current?.changeTheme(store.app.themeMode);
        });
      }
    }, [store.app.themeMode]);

    useEffect(() => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewTheme);
      const tvWidget = new widget({
        ...defaultWidgetOptions,
        locale: languageCode,
        container: chartContainerRef.current,
      } as ChartingLibraryWidgetOptions);

      widgetRef.current = tvWidget;

      tvWidget.subscribe('onAutoSaveNeeded', () => {
        tvWidget.save(ch => {
          localStorageService.set(
            `${LOCAL_STORAGE_KEYS.chartAdvanced}-${String(layoutNumber)}`,
            JSON.stringify(ch)
          );
        });
      });

      tvWidget.onChartReady(() => {
        // to reset time for AutoLogoutWithNoActivity
        tvWidget
          ?.activeChart()
          .crossHairMoved()
          .subscribe(null, _obj => {
            store.auth.userActivity.resetSeconds();
          });

        tvWidget
          .chart()
          .onIntervalChanged()
          .subscribe(null, value => {
            store.pages.trading.layout.layouts.runInAction(() => {
              store.pages.trading.layout.layouts[
                layoutNumber
              ].configuration.period = resolutionObject[value];
            });
          });

        const chartJson = localStorageService.get(
          `${LOCAL_STORAGE_KEYS.chartAdvanced}-${String(layoutNumber)}`
        );

        !tvWidget?.activeChart().getAllStudies()?.length &&
          isMuliBands &&
          !chartJson &&
          tvWidget?.activeChart().createStudy('MuliBands');

        if (chartJson) {
          tvWidget
            .activeChart()
            .applyStudyTemplate(JSON.parse(chartJson).charts[0]);

          !tvWidget?.activeChart().getAllStudies()?.length &&
            isMuliBands &&
            tvWidget?.activeChart().createStudy('MuliBands');

          tvWidget
            ?.activeChart()
            .getAllStudies()
            .forEach(s => {
              if (s.name === 'MuliBands') {
                !isMuliBands && tvWidget?.activeChart().removeAllStudies();
                !isMuliBands && tvWidget?.activeChart().removeAllShapes();
              }
            });

          !isTradingCentral &&
            tvWidget
              ?.activeChart()
              .getAllStudies()
              .forEach(s => {
                if (s.name === 'TradingCentral') {
                  tvWidget?.activeChart().removeEntity(s.id);
                }
              });

          !isEdgeX &&
            tvWidget
              ?.activeChart()
              .getAllStudies()
              .forEach(s => {
                if (s.name === 'EdgeX') {
                  tvWidget?.activeChart().removeEntity(s.id);
                }
              });

          !isEdgeZone &&
            tvWidget
              ?.activeChart()
              .getAllStudies()
              .forEach(s => {
                if (s.name === 'EdgeZone') {
                  tvWidget?.activeChart().removeEntity(s.id);
                }
              });
        }
      });
    }, [
      isTradingCentral,
      isEdgeX,
      isEdgeZone,
      languageCode,
      isMuliBands,
      idToken,
    ]);

    isMuliBands && useMuliBands(widgetRef, isMuliBands);

    return (
      <Wrapper className='advanced-chart-root'>
        <div ref={chartContainerRef} className={'TVChartContainer'} />
      </Wrapper>
    );
  }
);
