import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { EConditionType, useBusinessLogic } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { EBrand } from '@trader/types';
import {
  Icon,
  ModalTitle,
  TextField,
  TradingIsClosed,
} from '@trader/components';
import {
  IGetPspsParams,
  IMakeDepositBody,
  IPspsResponseData,
} from '@trader/api';

import { ConfirmButton } from '../components/confirmButton';
import * as Styled from './styled';

interface IFund {
  value: number;
  title: string;
  isActive: boolean;
  id: string;
}

export const AddFunds: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { applyCondition } = useBusinessLogic(
    EConditionType.RegistrationCompleted
  );
  const { applyCondition: applyConditionFourth } = useBusinessLogic(
    EConditionType.DepositAllowed
  );
  const tradingAccountSymbol = store.ui.modal.options.get(
    'tradingAccountSymbol'
  );
  const currencySymbol: string = (
    tradingAccountSymbol || store.user.portfolio.currencySymbol
  ).replace('R$', '$');

  const funds = [
    { value: 100, title: '100', isActive: true, id: '100' },
    { value: 300, title: '300', isActive: false, id: '300' },
    { value: 500, title: '500', isActive: false, id: '500' },
    {
      value: 1000,
      title: '1000',
      isActive: false,
      id: '1000',
    },
    {
      value: 5000,
      title: '5000',
      isActive: false,
      id: '5000',
    },
    {
      value: 0,
      title: translate('COMMON.LABELS.OTHER'),
      isActive: false,
      id: 'Other',
    },
  ];
  const defaultPsps: IPspsResponseData = {
    providers: [
      {
        id: 0,
        integrationType: 'string',
        name: 'string',
        displayName: 'string',
        descriptor_: 'string',
        integrationUrl: 'string',
        logoUrl: 'string',
        additionalImgUrl: 'string',
      },
    ],
  };
  const minimumPsps = 1;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const [iframeUrl, setIframeUrl] = useState('');
  const [state, setState] = useState<Array<IFund>>(funds);
  const [psps, setPsps] = useState<IPspsResponseData>(defaultPsps);

  const otherOption = state.find(s => s.id === 'Other') as IFund;

  const getTradingAccountId = () => {
    const modalTradingAccountId =
      store.ui.modal.options.get('tradingAccountId');
    const tradingAccountInUseId = store.user.tradingAccount?.tradingId;

    return modalTradingAccountId ?? tradingAccountInUseId;
  };

  const addFundsRequest = async (pspId = 0) => {
    const makeDepositBody: IMakeDepositBody = {
      amount: (state.find(s => s.isActive) as IFund).value,
      tradingAccountId: getTradingAccountId(),
    };

    if (pspId !== 0) {
      makeDepositBody.pspId = pspId;
    }

    const resUrl = await store.user.makeDepositAsync.run(makeDepositBody);
    resUrl && setIframeUrl(resUrl);
  };

  const getPsps = async () => {
    try {
      const pspsParams: IGetPspsParams = {
        tradingAccountId: getTradingAccountId(),
        amount: (state.find(s => s.isActive) as IFund).value,
      };
      const response = await store.user.getPspsAsync.run(pspsParams);

      if (response) {
        if (response.providers.length <= minimumPsps) {
          addFundsRequest();
        } else {
          setPsps(response);
        }
      }
    } catch (error) {
      addFundsRequest();
    }
  };
  const changeFund = (fund: IFund) => {
    const changedState: Array<IFund> = state.map(s => {
      s.isActive = s.title === fund.title;
      return s;
    });
    setState(changedState);
    const activeState = changedState.find(s => s.isActive);

    if (activeState?.id !== otherOption.id) {
      getPsps();
    }
  };

  const changeOther = (value: string) => {
    const changedState: Array<IFund> = state.map(s => {
      if (s.id === 'Other') {
        s.value = Number(value);
      }
      return s;
    });
    setState(changedState);
  };

  if (
    store.app.brand !== EBrand.Markets77 &&
    applyCondition().status === 'failed'
  ) {
    return (
      <TradingIsClosed
        iconTypeName='completeRegistration'
        title={translate('WARNINGS.COMPLETE_REGISTRATION')}
        content={translate('WARNINGS.HAVENT_COMPLETED_REGISTRATION')}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyCondition().action}
      />
    );
  }

  if (applyConditionFourth().status === 'failed') {
    return (
      <TradingIsClosed
        title={translate('WARNINGS.DEPOSIT_IS_DISABLED')}
        content={translate('WARNINGS.HAVENT_COMPLETED_REGISTRATION')}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionFourth().action}
      />
    );
  }

  return (
    <Styled.Root $isIframeOpen={!!iframeUrl}>
      {iframeUrl ? (
        <>
          <ModalTitle title={translate('ADD_FUNDS.TITLE')} />
          <iframe
            title='deposit-iframe'
            width={'100%'}
            height={'100%'}
            style={{
              border: 'none',
            }}
            src={iframeUrl}
          />
        </>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
          {psps.providers.length <= minimumPsps ? (
            <>
              <ModalTitle title={translate('ADD_FUNDS.TITLE')} />
              <Styled.Funds>
                {state.map(f => (
                  <Styled.Fund
                    $isActive={f.isActive}
                    variant='text'
                    onClick={() => changeFund(f)}
                    key={f.value}
                  >
                    {f.title ? `${currencySymbol}${f.title}` : f.title}
                  </Styled.Fund>
                ))}
              </Styled.Funds>
              {otherOption.isActive && (
                <Styled.Field>
                  <TextField
                    autoFocus
                    fullWidth
                    shouldHideControlInfo
                    label={translate('COMMON.LABELS.QUANTITY')}
                    value={otherOption.value}
                    onChange={event => changeOther(event.target.value)}
                  />
                </Styled.Field>
              )}
              <ConfirmButton
                isDisabled={otherOption.isActive && !otherOption.value}
                isLoading={
                  store.user.getPspsAsync.inProgress ||
                  store.user.makeDepositAsync.inProgress
                }
                onClick={getPsps}
                title={translate('ADD_FUNDS.ADD_FUNDS_START_TRADING')}
              />
              {!isInvestmentAccProduct && (
                <Styled.ExtraInformation>
                  {translate('ADD_FUNDS.DESCRIPTION', {
                    currencySymbol: currencySymbol,
                  })}
                </Styled.ExtraInformation>
              )}
            </>
          ) : (
            <>
              <ModalTitle title={translate('COMMON.LABELS.SELECT_PROVIDER')} />
              <Styled.Psps>
                {store.user.makeDepositAsync.inProgress ? (
                  <Icon iconType='loading' />
                ) : (
                  psps.providers.map(f => (
                    <Styled.Psp
                      onClick={() => addFundsRequest(f.id)}
                      key={f.id}
                    >
                      <Styled.PspTitle>{f.displayName}</Styled.PspTitle>
                      {f.displayName === 'Wire' ? (
                        <Icon iconType='wirePaymentServiceProvider' />
                      ) : (
                        <Icon iconType='paymentServiceProvider' />
                      )}
                    </Styled.Psp>
                  ))
                )}
              </Styled.Psps>
            </>
          )}
        </React.Fragment>
      )}
    </Styled.Root>
  );
});
