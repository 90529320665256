import React from 'react';
import styled, { css } from 'styled-components';
import { Box, BoxProps } from '@mui/material';

import { conditionApply } from '@trader/utils';

import { Typography, Wrapper } from '@trader/components';
import { layoutMargin, sideBarWidth } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

export const Body = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`;

export const Footer = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `};
`;

export const Instrument = styled(Wrapper)`
  ${() => css`
    width: 100%;
    align-items: center;
  `};
`;

export const Symbol = styled(Typography)`
  ${() => css`
    font-size: 18px;
    margin-right: 10px;
  `};
`;

export const Available = styled(Typography)`
  ${() => css`
    font-size: 15px;
  `};
`;

export const Routing = styled(Wrapper)<{ $leftBarWidth?: number }>`
  ${({ theme, $leftBarWidth }) => css`
    height: 100%;
    width: calc(100% - ${($leftBarWidth || sideBarWidth) + layoutMargin}px);
    border-radius: 4px;
    display: flex;
    flex: 3;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `};
`;

interface IMain extends BoxProps {
  $isSidebarOpen?: boolean;
  children: React.ReactNode;
}

export const Main = styled<React.FC<IMain>>(({ $isSidebarOpen, ...rest }) => (
  <Box {...rest} />
))`
  ${({ theme, $isSidebarOpen }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    transition: ${theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
    ${conditionApply(
      [$isSidebarOpen],
      `
        transition: ${theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })};`
    )};
  `}
`;
