import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { defaultIcon, large } from '@trader/constants';
import { getInstrumentDifference } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';
import {
  ActionButtons,
  ConfigurationMenu,
  SearchInstrument,
  Tooltip,
} from '@trader/components';

import * as Styled from './styled';

export interface IInstrumentWithLayout extends TInstrumentEntity {
  layoutNumber?: EChartLayouts;
}

interface IInstrumentDetailsHeader {
  instrument?: IInstrumentWithLayout;
  layout?: EChartLayoutTypes;
  layoutNumber?: EChartLayouts;
  hasDropDownSearch?: boolean;
  searchPlaceholder?: string;
  onChangeSymbol?: (symbol: string) => void;
}

export const InstrumentDetailsHeader: React.FC<IInstrumentDetailsHeader> =
  observer(
    ({
      instrument: instr,
      layout,
      hasDropDownSearch,
      onChangeSymbol,
      layoutNumber,
      searchPlaceholder,
    }) => {
      const store = useMst();
      const [isSearch, setIsSearch] = useState(false);

      const symbol = store.pages.trading.getInstrumentSymbolByLayout();
      const ins = store.entities.instruments.get<TInstrumentEntity>(symbol);

      const instrument = instr || {
        ...ins,
        layoutNumber: EChartLayouts.FirstLayout,
      };

      const layoutType = layout || store.pages.trading.layout.layoutType;

      const openSearchInstrument = useCallback(() => {
        setIsSearch(!isSearch);
      }, [isSearch]);

      if (!instrument?.symbol) {
        return null;
      }

      return (
        <React.Fragment>
          {isSearch && (
            <SearchInstrument
              placeholder={searchPlaceholder}
              closeSearching={openSearchInstrument}
            />
          )}

          <Styled.Header
            $layout={layoutType}
            className='instrument_details_header'
            $selectedLayout={
              layoutNumber || store.pages.trading.layout.selectedLayoutNumber
            }
            $instrumentLayout={layoutNumber || instrument?.layoutNumber}
          >
            <Styled.Info>
              {layoutType === 'single' && (
                <img
                  className='instrument_details_header_icon'
                  src={instrument?.iconUrl || defaultIcon}
                  alt='instrument icon'
                  width={large}
                  height={large}
                />
              )}
              <Styled.InstrumentInfo
                className='instrument_details_header_info'
                $layout={layoutType}
              >
                <Tooltip title={instrument?.description}>
                  <Styled.Symbol $layout={layoutType}>
                    {instrument?.symbol || ''}
                  </Styled.Symbol>
                </Tooltip>
                <Styled.ExtraInfo>
                  <Difference
                    layoutType={layoutType}
                    symbol={instrument.symbol}
                  />
                </Styled.ExtraInfo>
              </Styled.InstrumentInfo>
              {layoutType !== EChartLayoutTypes.Single && (
                <Styled.Search
                  onClick={openSearchInstrument}
                  iconType='search'
                />
              )}
              <Styled.DesktopContainer>
                <ActionButtons
                  layout={layoutType}
                  symbol={instrument.symbol}
                  ask={instrument.ask}
                  bid={instrument.bid}
                  pipSize={instrument.pipSize}
                />
              </Styled.DesktopContainer>
              <Styled.MobileContainer>
                <ConfigurationMenu
                  shouldHideTimeConfig={!!layout}
                  instrument={instrument}
                  layoutNumber={
                    instrument?.layoutNumber || EChartLayouts.FirstLayout
                  }
                />
              </Styled.MobileContainer>
            </Styled.Info>
            <Styled.DesktopContainer>
              {hasDropDownSearch && (
                <Styled.DropDownSearch>
                  <SearchInstrument
                    placeholder={searchPlaceholder}
                    onChangeSymbol={onChangeSymbol}
                  />
                </Styled.DropDownSearch>
              )}
              <ConfigurationMenu
                shouldHideTimeConfig={!!layout}
                instrument={instrument}
                layoutNumber={
                  instrument?.layoutNumber || EChartLayouts.FirstLayout
                }
              />
            </Styled.DesktopContainer>
            <Styled.MobileContainer>
              <ActionButtons
                layout={layoutType}
                symbol={instrument.symbol}
                ask={instrument.ask}
                bid={instrument.bid}
                pipSize={instrument.pipSize}
              />
            </Styled.MobileContainer>
          </Styled.Header>
        </React.Fragment>
      );
    }
  );

interface IChildren {
  layoutType?: string;
  symbol: string;
}

const Difference: React.FC<IChildren> = observer(({ layoutType, symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
    getInstrumentDifference(
      instrument?.ask || 0,
      instrument?.close || 0,
      instrument?.pipSize || 0
    );

  return (
    <Styled.Difference $layout={layoutType} $isAboveZero={isGrow}>
      {openCloseDifference} ({openCloseDifferenceInPercent}%)
    </Styled.Difference>
  );
});
