import React from 'react';
import * as Styled from './styled';

export interface IRadioButton {
  className?: string;
  value?: string;
  label: string;
  disabled?: boolean;
  helperText?: string;
  isError?: boolean;
}

export const RadioButton = React.forwardRef<HTMLInputElement, IRadioButton>(
  ({ helperText, isError, ...props }, ref) => {
    return (
      <Styled.LabelContainer
        {...props}
        ref={ref}
        control={
          <Styled.Radio
            icon={<Styled.Icon />}
            checkedIcon={<Styled.CheckedIcon />}
          />
        }
        label={props.label}
      />
    );
  }
);
RadioButton.defaultProps = {
  disabled: false,
};
