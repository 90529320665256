import { useTheme } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';

import * as Styled from './styled';

interface IProps {
  value: Date | null;
  minValue?: Date | null;
  maxValue?: Date | null;
  onChange: (date: Date | null) => void;
}

export const Datepicker = ({ value, minValue, maxValue, onChange }: IProps) => {
  const theme = useTheme();

  return (
    <Styled.Datepicker
      format='DD/MM/YYYY'
      // For some reason typescript shows that supported types for value are only null or undefined that is incorrect.
      // To fix this we need to converted to any until we found better solution.
      /* eslint-disable @typescript-eslint/no-explicit-any */
      value={value ? (dayjs(value) as any) : undefined}
      minDate={minValue ? (dayjs(minValue) as any) : undefined}
      maxDate={maxValue ? (dayjs(maxValue) as any) : undefined}
      slots={{
        openPickerIcon: () => <Styled.CalendarIcon iconType='calendar' />,
      }}
      slotProps={{
        textField: {
          sx: {
            borderRadius: '8px',
            backgroundColor: theme.palette.gray.light,
            fontSize: '11px',
          },
        },
        day: {
          sx: {
            fontSize: '11px',

            '&.Mui-selected': {
              backgroundColor: `${theme.palette.primary.main}!important`,
            },
          },
        },
        desktopPaper: {
          sx: {
            width: '320px',
            height: '335px',

            '.MuiPickersCalendarHeader-labelContainer': {
              fontSize: '14px',
            },

            '.MuiPickersYear-root': {
              height: '35px',

              button: {
                fontSize: '12px',
              },

              '.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main}!important`,
              },
            },

            '.MuiDayCalendar-weekDayLabel': {
              fontSize: '11px',
            },
          },
        },
      }}
      // For some reason typescript shows that supported types for value are only null that is incorrect.
      // To fix this we need to converted to Dayjs manually until we found better solution.
      onChange={v => onChange(v ? (v as unknown as Dayjs).toDate() : null)}
    />
  );
};
