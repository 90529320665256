import { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import { isChallengeAccountType, isLiveEnv } from '@trader/constants';
import { usePurchase } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { GoalReached, InfoChip } from '@trader/components';

import { Logotype } from '../../components/logotype';

import { BetaDesignSwitcher } from './components/betaDesignSwitcher';
import { TradingChartLayoutSelection } from './components/tradingChartLayoutSelection';
import { DesktopMenu } from './components/desktopMenu';
import { BalanceInformation } from './components/balanceInformation';

import * as Styled from './styled';

interface IDesktopTopBar {
  withChartLayoutsSelection?: boolean;
}

export const DesktopTopBar: FC<IDesktopTopBar> = observer(
  ({ withChartLayoutsSelection = true }) => {
    const store = useMst();
    const purchase = usePurchase();
    const theme = useTheme();
    const { translate } = useI18next();

    const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

    return (
      <Styled.Root>
        <Logotype />
        <Styled.Content>
          {!isLiveEnv && (
            <Fragment>
              <Styled.Divider $spacingSide='right' />
              <BetaDesignSwitcher />
            </Fragment>
          )}
          <Styled.Divider $spacingSide='left' />
          {withChartLayoutsSelection && <TradingChartLayoutSelection />}
          <Styled.Divider $spacingSide='right' />
          <BalanceInformation />
          <Styled.Divider $spacingSide='left' />
          {isChallengeAccountType && (
            <>
              <GoalReached
                value={store.user.getActiveAccountChallenge()?.goalReached}
                margin='0 18px'
                hasTitle
              />
              <Styled.Divider />
            </>
          )}
          {isDailyTargetGoalReached && (
            <>
              <InfoChip
                iconType='circleCheckmark'
                text={translate('COMMON.LABELS.DAILY_TARGET_REACHED')}
                textColor={theme.palette.common.white}
                backgroundColor={theme.palette.green.dark}
                margin='0 18px'
              />
              <Styled.Divider />
            </>
          )}
          {!isChallengeAccountType && (
            <Styled.AddFunds
              disabled={false}
              onClick={purchase.action}
              variant='text'
            >
              {translate(purchase.title)}
            </Styled.AddFunds>
          )}
          <Styled.Divider />
          <DesktopMenu />
        </Styled.Content>
      </Styled.Root>
    );
  }
);
