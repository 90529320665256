import { FC, ReactNode, useRef } from 'react';

import * as Styled from './styled';

interface IExpandedContent {
  width: number;
  minWidth: number;
  maxWidth: number;
  children: ReactNode;
  onUpdateWidth: (width: number) => void;
}

export const ExpandedContent: FC<IExpandedContent> = ({
  children,
  minWidth,
  maxWidth,
  width,
  onUpdateWidth,
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = (e: MouseEvent) => {
    if (rootRef.current) {
      const newWidth = e.clientX - rootRef.current.getBoundingClientRect().left;
      onUpdateWidth(newWidth);
    }
  };

  const handleMouseDown = () => {
    document.body.style.userSelect = 'none';
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.body.style.userSelect = 'auto';
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Styled.Root
      ref={rootRef}
      $width={width}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
    >
      {children}
      <Styled.ResizeHandler onMouseDown={handleMouseDown} />
    </Styled.Root>
  );
};
