import { observer } from 'mobx-react-lite';
import { useMst } from '@trader/store';
import { EChartLayoutTypes } from '@trader/types';
import { SingleLayout } from './singleLayout';
import { MultipleLayouts } from './multipleLayouts';

import { Root } from './styled';

export const InstrumentLayouts = observer(() => {
  const store = useMst();

  const isSingleLayout =
    store.pages.trading.layout.layoutType === EChartLayoutTypes.Single;

  return <Root>{isSingleLayout ? <SingleLayout /> : <MultipleLayouts />}</Root>;
});
