import styled, { css } from 'styled-components';
import {
  Box,
  FormControlLabel,
  Radio as RadioCore,
  RadioGroup,
} from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup';
import { ContainerControlInfo } from '../controlInfo/styled';
import { Direction, TDirection } from '@trader/themes';

export const LabelContainer = styled(FormControlLabel)`
  margin-left: -9px;
  user-select: none;
`;

export const CheckedIcon = styled.span`
  ${props => css`
    box-sizing: border-box;
    border: 2px solid ${props.theme.palette.primary.main};
    border-radius: 50%;
    background-color: ${props.theme.palette.common.white};
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:before {
      box-sizing: border-box;

      display: block;
      width: 15px;
      height: 15px;
      background-image: radial-gradient(
        ${props.theme.palette.primary.main},
        ${props.theme.palette.primary.main} 60%,
        transparent 32%
      );
      content: '';
    }

    input:hover ~ & {
      background-color: ${props.theme.palette.primary.dark};
    }

    input:disabled ~ & {
      background-color: ${props.theme.palette.grey['400']};
      opacity: 0.3;
    }
  `}
`;

export const Icon = styled.span`
  ${props => css`
    box-sizing: border-box;
    border: 2px solid ${props.theme.palette.grey[500]};
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background-color: ${props.theme.palette.common.white};
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    input:hover ~ & {
      border-color: ${props.theme.palette.grey[600]};
    }

    input:disabled ~ & {
      border-color: ${props.theme.palette.grey[300]};
    }
  `}
`;

export const RadioContainer = styled('div')`
  ${ContainerControlInfo} {
    margin-top: -9px;
  }
`;

export const Radio = styled(RadioCore)`
  &:hover {
    background-color: transparent;
  }

  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const TextContainer = styled(Box)`
  padding-top: 7px;
  padding-right: 30px;
  font-size: 16px;
`;

interface IRadioButtonGroupContainer extends RadioGroupProps {
  direction?: TDirection;
}

export const RadioGroupContainer = styled(
  RadioGroup
)<IRadioButtonGroupContainer>`
  ${({ direction }) =>
    css`
      flex-direction: ${direction === Direction.vertical ? 'column' : 'row'};
    `}
`;
