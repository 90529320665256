import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '@trader/components';
import { useNavigation, useWindowDimensions } from '@trader/hooks';
import {
  calculateSpread,
  formatByPipSize,
  getInstrumentDifference,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { breakpoints } from '@trader/themes';
import { useI18next } from '@trader/services';
import {
  NAVIGATE_TO,
  shouldDisplayChangeColumnOnInstrumentList,
  shouldDisplaySpreadColumnOnInstrumentList,
} from '@trader/constants';

import { LineChart } from '../../lineChart';
import * as Styled from './styled';

interface IInstrument {
  symbol: string;
  miniChartCoordinates: Array<number>;
}

export const Instrument: React.FC<IInstrument> = observer(
  ({ symbol, miniChartCoordinates }) => {
    const { width } = useWindowDimensions();
    const store = useMst();
    const { translate } = useI18next();
    const { navigateTo } = useNavigation();

    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    const selectedSymbol = store.pages.trading.getInstrumentSymbolByLayout();

    const breakpointSm = breakpoints?.values?.sm as number;
    const shouldDisplay = width > breakpointSm;

    const isNextButtonDisabled =
      store.pages.trading.getPrefetchInformationAsync.inProgress;

    const selectInstrument = () => {
      store.pages.trading.layout.selectInstrument(symbol);
    };

    const navigateToTrading = (event: SyntheticEvent) => {
      event.stopPropagation();
      selectInstrument();
      navigateTo(NAVIGATE_TO.main.trading);
    };

    return (
      <Styled.Box
        id={`instrument-${symbol}`}
        onClick={selectInstrument}
        $isSelected={symbol === selectedSymbol}
      >
        <Styled.InstrumentInfo>
          <Styled.Symbol>{symbol || ''}</Styled.Symbol>
          {instrument?.tradingAvailability?.isUnavailable && (
            <Tooltip
              maxWidth={215}
              title={translate('COMMON.LABELS.MARKET_IS_CLOSED', {
                openIn: instrument.tradingAvailability.openIn,
              })}
            >
              <Styled.TradingDisabledIcon iconType='notAllowed' />
            </Tooltip>
          )}
        </Styled.InstrumentInfo>
        <Ask symbol={symbol} />
        <Bid symbol={symbol} />
        {shouldDisplaySpreadColumnOnInstrumentList && (
          <Spread symbol={symbol} />
        )}
        {shouldDisplayChangeColumnOnInstrumentList && (
          <Change symbol={symbol} />
        )}
        {shouldDisplay && (
          <Styled.InstrumentCharts>
            <LineChart data={miniChartCoordinates} />
          </Styled.InstrumentCharts>
        )}
        <Favorite symbol={symbol} />
        <Styled.NavigateButton
          disabled={isNextButtonDisabled}
          iconType='arrowRight'
          onClick={navigateToTrading}
        />
      </Styled.Box>
    );
  }
);

interface IRealTimeComponent {
  symbol: string;
}

const Ask: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Price
      onAnimationEnd={() => {
        store.entities.instruments.update(symbol, {
          updateAskType: 'none',
        });
      }}
      $isAsk={true}
      $updateType={instrument?.updateAskType}
    >
      {formatByPipSize(instrument?.ask || 0, instrument?.pipSize || 0)}
    </Styled.Price>
  );
});

const Bid: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Price
      onAnimationEnd={() => {
        store.entities.instruments.update(symbol, {
          updateBidType: 'none',
        });
      }}
      $isAsk={true}
      $updateType={instrument?.updateBidType}
    >
      {formatByPipSize(instrument?.bid || 0, instrument?.pipSize || 0)}
    </Styled.Price>
  );
});

const Spread: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Spread>
      {calculateSpread({
        ask: Number(instrument?.ask || 0),
        bid: Number(instrument?.bid || 0),
        pipSize: instrument?.pipSize || 0,
      })}
    </Styled.Spread>
  );
});

const Change: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const { isGrow, openCloseDifferenceInPercent } = getInstrumentDifference(
    instrument?.ask,
    instrument?.close,
    instrument?.pipSize
  );

  return (
    <Styled.Change $isAboveZero={isGrow}>
      {openCloseDifferenceInPercent}%
    </Styled.Change>
  );
});

const Favorite: React.FC<IRealTimeComponent> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.Favourite $hideOnDesktop={!store.app.isBetaDesignEnabled()}>
      {instrument?.isFavorite ? (
        <Styled.Button
          onClick={() =>
            instrument.removeInstrumentFromFavouriteAsync.run(instrument.id)
          }
          size='medium'
          iconType='favoriteOn'
        />
      ) : (
        <Styled.Button
          onClick={() =>
            instrument?.addInstrumentToFavouriteAsync.run(instrument?.id)
          }
          size='medium'
          iconType='favouriteOff'
        />
      )}
    </Styled.Favourite>
  );
});
