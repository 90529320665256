import { useMemo } from 'react';
import * as yup from 'yup';

import {
  checkOnTickSize,
  getMaxStopLoss,
  getMaxTakeProfitRate,
  getMinStopLoss,
  getMinTakeProfitRate,
  getOrderBasePrice,
  handleIndicesPipSize,
} from '@trader/utils';
import { useI18next } from '@trader/services';
import { defaultPipSize } from '@trader/constants';
import { TInstrumentEntity, useMst } from '@trader/store';

export const useProtectionSchema = () => {
  const store = useMst();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const actualPipSize = instrument.pipSize;
  const tickSize = instrument.tickSize;
  const stopsLevel = instrument.stopsLevel;
  const category = instrument?.category;
  const minOrderSizeIncrement = instrument.minOrderSizeIncrement;

  const pipSize = handleIndicesPipSize(
    actualPipSize ?? defaultPipSize,
    category || undefined,
    minOrderSizeIncrement ?? 1
  );
  const { translate } = useI18next();
  const protectionSchema = useMemo(() => {
    const pipsOrRate = yup.string().oneOf(['Rate', 'Pips']);

    return yup.object().shape({
      isTakeProfit: yup.boolean(),
      takeProfitType: pipsOrRate,
      ask: yup.number(),
      bid: yup.number(),
      takeProfitPips: yup
        .number()
        .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
      takeProfit: yup
        .string()
        .when(
          [
            'isTakeProfit',
            'bid',
            'ask',
            'side',
            'takeProfitType',
            'price',
            'orderType',
            'takeProfitPips',
          ],
          (
            [isTakeProfit, bid, ask, side, takeProfitType, price, orderType],
            schema
          ) => {
            const isPendingOrder = orderType !== 'Market';
            const max = getMaxTakeProfitRate(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            );

            const min = getMinTakeProfitRate(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            );

            const path =
              takeProfitType === 'Rate' ? 'takeProfit' : 'takeProfitPips';
            const messagePrefix = takeProfitType === 'Pips' ? 'Rate ' : '';

            return schema.test(
              'trading-protection-diff',
              '',
              (value, context) => {
                if (!value) {
                  return context.createError({
                    path,
                    message:
                      messagePrefix + translate('COMMON.ERRORS.REQUIRED_FIELD'),
                  });
                }

                if (isTakeProfit) {
                  if (checkOnTickSize(value, tickSize)) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.TICK_SIZE', { tickSize }),
                    });
                  }

                  if (+value > +max) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
                          amount: max,
                        }),
                    });
                  }

                  if (+value < +min) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
                          amount: min,
                        }),
                    });
                  }
                }
                return true;
              }
            );
          }
        )
        .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
      isStopLoss: yup.boolean(),
      stopLossType: pipsOrRate,
      stopLossPips: yup
        .number()
        .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
      stopLoss: yup
        .string()
        .when(
          [
            'isStopLoss',
            'ask',
            'bid',
            'side',
            'stopLossType',
            'price',
            'orderType',
            'stopLossPips',
          ],
          (
            [isStopLoss, ask, bid, side, stopLossType, price, orderType],
            schema
          ) => {
            const isPendingOrder = orderType !== 'Market';

            const min = getMinStopLoss(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            );
            const max = getMaxStopLoss(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            );

            const path = stopLossType === 'Rate' ? 'stopLoss' : 'stopLossPips';
            const messagePrefix = stopLossType === 'Pips' ? 'Rate ' : '';

            return schema.test(
              'trading-protection-loss-diff',
              '',
              (value, context) => {
                if (!value) {
                  return context.createError({
                    path,
                    message:
                      messagePrefix + translate('COMMON.ERRORS.REQUIRED_FIELD'),
                  });
                }

                if (isStopLoss) {
                  if (checkOnTickSize(value, tickSize)) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.TICK_SIZE', { tickSize }),
                    });
                  }

                  if (+value > +max) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
                          amount: max,
                        }),
                    });
                  }

                  if (+value < +min) {
                    return context.createError({
                      path,
                      message:
                        messagePrefix +
                        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
                          amount: min,
                        }),
                    });
                  }
                }
                return true;
              }
            );
          }
        )
        .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    });
  }, [actualPipSize, pipSize, stopsLevel]);

  return { protectionSchema };
};
