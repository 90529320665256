import {
  api,
  ICostAndChargesBE,
  IGetCostAndChargesParams,
  IGetExchangeRateInstrumentsBody,
  IIndicators,
  IInstrumentItems,
  IInstrumentSpecificationBE,
  IInstrumentTradersTrendBE,
  IPlaceOrderBody,
  IRequiredOpenCostBE,
  IRequiredOpenCostParams,
} from '@trader/api';
import { returnTypedThis } from '@trader/utils';
import { devLoggerService } from '@trader/services';
import {
  defaultSubCategoryId,
  hasCopierFunctionality,
} from '@trader/constants';
import { EChartLayoutTypes, IInstrumentsParams } from '@trader/types';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { instrumentsSchema } from './schemas';
import { TInstrumentEntity } from './index';
import { IBarFE, TExchangeRateInstrumentsFE } from './models';

export const getInstrumentsAsync = createThunk<
  IInstrumentsParams,
  IInstrumentItems
>(
  ({ shouldClearBeforeMerge = false, pageNumber }) =>
    async function getInstruments(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const isSingleLayout =
        root.pages.trading.layout.layoutType === EChartLayoutTypes.Single;
      const selectedCategory = root.pages.trading.selectedCategory;
      const selectedSubCategory = root.pages.trading.selectedSubCategory;
      const selectedInstrument =
        root.pages.trading.getInstrumentSymbolByLayout();

      const isPopular = selectedCategory?.name === 'Popular';

      const res = await api.Instrument.getInstruments(
        {
          isPopular: selectedCategory?.name === 'Popular',
          isFavourite: selectedCategory?.name === 'Watchlist',
          pageNumber: pageNumber,
          category:
            selectedSubCategory === defaultSubCategoryId
              ? selectedCategory?.id.toString() || ''
              : selectedSubCategory,
        },
        _options
      );

      const instruments = res?.instruments.map(i => {
        if (
          selectedSubCategory &&
          selectedSubCategory !== defaultSubCategoryId
        ) {
          i.subCategoryId = selectedSubCategory;
        }
        if (isPopular) {
          i.isPopular = isPopular;
        }

        return i;
      });

      try {
        root.entities.normalizeMerge(
          instruments,
          instrumentsSchema,
          root.pages.trading.layout.layoutType !== 'single'
            ? false
            : shouldClearBeforeMerge
        );

        const newInstrument =
          root.entities.instruments.getAll<TInstrumentEntity>()[0];

        root.pages.trading.runInAction(() => {
          root.pages.trading.instrumentsAmount = res?.totalCount || 0;
        });

        isSingleLayout &&
          root.pages.trading.layout.selectInstrument(
            selectedInstrument || newInstrument.symbol
          );
      } catch (e) {
        devLoggerService.error('Error in getInstrumentsAsync', e);
      }

      return { totalCount: res?.totalCount || 0, instruments };
    }
);

export const getRelatedInstrumentsAsync = createThunk<
  string,
  IInstrumentItems['instruments']
>(
  symbol =>
    async function getRelatedInstruments(this: unknown, _options, _flow) {
      const res = await api.Instrument.getRelatedInstruments(symbol, _options);
      return res.instruments;
    }
);

interface IGetInstrumentBars {
  symbol: string;
  period: string;
  fromTime: number;
}

export const getInstrumentBarsAsync = createThunk<
  IGetInstrumentBars,
  Array<IBarFE>
>(
  ({ symbol, period, fromTime }) =>
    async function getInstrumentBars(this: unknown, _options, _flow) {
      const response = await api.Historical.getCandleBars(
        symbol,
        period,
        fromTime
      );

      return response.map(bar => ({
        open: bar.o,
        low: bar.l,
        high: bar.h,
        close: bar.c,
        time: bar.t,
      }));
    }
);

export const getInstrumentBarsIndicatorsAsync = createThunk<
  string,
  IIndicators
>(
  symbol =>
    async function getInstrumentBarsIndicators(this: unknown, _options, _flow) {
      return await api.Historical.getIndicators(symbol);
    }
);
export const getInstrumentCostAndChargesAsync = createThunk<
  IGetCostAndChargesParams,
  ICostAndChargesBE
>(
  ({ symbol, quantity, side }) =>
    async function getInstrumentCostAndCharges(this: unknown, _options, _flow) {
      return await api.Trading.getCostAndCharges(
        { symbol, quantity, side },
        _options
      );
    }
);

export const addInstrumentToFavouriteAsync = createThunk<number, void>(
  instrumentId =>
    async function addInstrumentToFavourite(this: unknown, _options, _flow) {
      const that = returnTypedThis<TInstrumentEntity>(this);
      that.runInAction(() => {
        that.isFavorite = true;
      });
      try {
        await api.Instrument.addInstrumentToFavorite(instrumentId, _options);
      } catch {
        that.runInAction(() => {
          that.isFavorite = false;
        });
      }
    }
);

export const removeInstrumentFromFavouriteAsync = createThunk<number, void>(
  instrumentId =>
    async function removeInstrumentFromFavourite(
      this: unknown,
      _options,
      _flow
    ) {
      const that = returnTypedThis<TInstrumentEntity>(this);
      that.runInAction(() => {
        that.isFavorite = false;
      });
      try {
        await api.Instrument.deleteInstrumentFromFavorite(
          instrumentId,
          _options
        );
      } catch {
        that.runInAction(() => {
          that.isFavorite = true;
        });
      }
    }
);

export const getInstrumentSpecificationAsync = createThunk<
  string,
  IInstrumentSpecificationBE
>(
  symbol =>
    async function getInstrumentSpecification(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const isInstrumentExist =
        root.entities?.instruments?.get<TInstrumentEntity>(symbol);

      const res = await api.Instrument.getInstrumentSpecification(
        symbol,
        _options
      );

      try {
        isInstrumentExist
          ? root.entities.instruments.update(symbol, res)
          : root.entities.instruments.add(symbol, res);
      } catch (e) {
        devLoggerService.error('Error in getInstrumentSpecificationAsync', e);
      }

      return res;
    }
);

export const getInstrumentTradersTrendAsync = createThunk<
  string,
  IInstrumentTradersTrendBE
>(
  symbol =>
    async function getInstrumentTradersTrend(this: unknown, _options, _flow) {
      return await api.Instrument.getInstrumentTradersTrend(symbol, _options);
    }
);

export const getInstrumentsSearchAsync = createThunk<string, Array<string>>(
  searchValue =>
    async function getInstrumentsSearch(this: unknown, _options, _flow) {
      const res = await api.Instrument.searchInstruments(searchValue, _options);
      return res?.instruments.map(i => i.symbol) || [];
    }
);

export const getExchangeRateInstrumentsAsync = createThunk<
  IGetExchangeRateInstrumentsBody[],
  TExchangeRateInstrumentsFE
>(
  body =>
    async function getExchangeRateInstruments(this: unknown, _options, _flow) {
      const res = await api.Instrument.getExchangeRateInstruments(body);

      const mappedResp = [] as unknown as TExchangeRateInstrumentsFE;
      for (const [key, value] of Object.entries(res || {})) {
        mappedResp.push({
          rateSymbol: value.symbol,
          positionSymbol: key,
          isReversed: value.isReversed,
        });
      }

      return mappedResp;
    }
);

export const placeOrderAsync = createThunk<IPlaceOrderBody, void>(
  body =>
    async function placeOrder(this: unknown, _options, _flow) {
      if (hasCopierFunctionality) {
        await api.Trading.placeChallengeOrder(body, _options);
      } else {
        await api.Trading.placeOrder(body, _options);
      }
    }
);

export const getRequiredOpenCostAsync = createThunk<
  IRequiredOpenCostParams,
  IRequiredOpenCostBE
>(
  params =>
    async function getRequiredOpenCost(this: unknown, _options, _flow) {
      return await api.Trading.getRequiredOpenCost(params, _options);
    }
);
