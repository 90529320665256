import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import { layoutChildrenRadius } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
  `};
`;

export const Body = styled(Wrapper)`
  ${() => css`
    flex-direction: row;
    width: 100%;
    height: 100%;
  `}
`;

export const Main = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};

    .action_buttons {
      > button {
        height: 44px;
      }

      ${theme.breakpoints.down('xl')} {
        margin-left: 0;

        > button {
          width: 110px;
        }
      }
    }

    .instrument_details_header {
      height: 61px;
    }

    .instrument_details_header_icon {
      width: 44px;
      height: 44px;
    }

    .MuiOutlinedInput-root {
      height: 44px;
    }

    .instrument_details_header_info {
      width: auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      > div {
        width: auto;
        margin-left: 10px;

        > p {
          min-width: 140px;

          ${theme.breakpoints.down('lg')} {
            display: none;
          }
        }
      }
    }
  `}
`;
