import styled, { css } from 'styled-components';

import { layoutChildrenRadius, layoutMargin } from '@trader/constants';
import { Wrapper } from '@trader/components';

export const Shrinked = styled(Wrapper)`
  ${({ theme }) => css`
    width: 35px;
    align-items: center;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    height: inherit;
    flex-shrink: 0;
    margin-right: ${layoutMargin}px;

    svg {
      margin-left: 2px;
    }
  `};
`;

export const Selection = styled(Wrapper)`
  ${() => css`
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;

    div:nth-child(2) {
      padding: 8px 0 0 0;
      margin-bottom: 0;
    }
  `};
`;
