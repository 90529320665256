import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  useDebounce,
  usePrevious,
  useUpdatePricesInRealTime,
  useUpdateStopLossRealTime,
  useUpdateTakeProfitRealTime,
} from '@trader/hooks';
import { useMst } from '@trader/store';
import { oneSecond } from '@trader/constants';
import { IInitialTradingFormValues } from '@trader/types';

interface IRealTimeTriggering {
  children: React.ReactNode;
}

export const RealTimeTriggering: React.FC<IRealTimeTriggering> = observer(
  ({ children }) => {
    const store = useMst();
    const { getValues } = useFormContext<IInitialTradingFormValues>();

    useUpdatePricesInRealTime();
    useUpdateTakeProfitRealTime();
    useUpdateStopLossRealTime();

    const amount = getValues().amount;
    const price = getValues().price;
    const side = getValues().side;

    const prevAmount = usePrevious(amount);
    const prevPrice = usePrevious(price);

    useDebounce(
      () => {
        const isSameInputsAfterFundsErrorAppeared =
          amount === prevAmount &&
          price === prevPrice &&
          store.trading.isRequiredOpenCostError;

        // do not trigger api method if error exists and inputs aren't changed;
        if (!isSameInputsAfterFundsErrorAppeared) {
          store.trading.getRequiredOpenCostAsync.run({
            amount,
            price,
            side,
          });
        }
      },
      [amount, price, side],
      oneSecond
    );

    useEffect(() => {
      store.trading.getRequiredOpenCostAsync.run({
        amount,
        price,
        side,
      });
    }, []);

    return children;
  }
);
