import React from 'react';

import { Button as StyledButton, ButtonText, LoadingIcon } from './styled';
import { Icon } from '../icon';
import { TIconsName } from '@trader/assets';
import { IconSide, TIconSide } from '@trader/themes';
import { ButtonProps } from '@mui/material';

export interface IButton extends ButtonProps {
  isSingleIcon?: boolean;
  iconType?: TIconsName;
  iconSide?: TIconSide;
  loading?: boolean;
  isLabel?: boolean;
}

export const Button: React.FC<IButton> = props => {
  const {
    color = 'primary',
    size = 'large',
    iconSide = 'right',
    variant = 'contained',
    isLabel = false,
    iconType,
    onClick,
    children,
    isSingleIcon,
    ...rest
  } = props;

  const isText = typeof children === 'string';
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const content = isText ? <>{children}</> : children;

  return (
    <StyledButton
      onClick={onClick}
      size={size}
      color={color}
      variant={variant}
      $iconSide={iconSide}
      $isLabel={isLabel}
      $singleIcon={isSingleIcon}
      {...rest}
    >
      {props.children
        ? renderContent({
            content,
            iconSide,
            iconType,
            loading: props.loading,
          })
        : renderSingleIcon(iconType)}
    </StyledButton>
  );
};

const renderSingleIcon = (iconType?: TIconsName) => {
  return iconType && <Icon iconType={iconType} />;
};

const renderIcon = (
  currentPlace: TIconSide,
  iconType?: TIconsName,
  iconSide?: TIconSide
) => {
  if (iconType) {
    return currentPlace === iconSide && <Icon iconType={iconType} />;
  }
  return null;
};

interface IRenderContent {
  content: React.ReactNode;
  iconType?: TIconsName;
  iconSide?: TIconSide;
  loading?: boolean;
}

const renderContent = ({
  content,
  iconType,
  iconSide,
  loading,
}: IRenderContent) => {
  return (
    <>
      {iconType && renderIcon(IconSide.left, iconType, iconSide)}
      <ButtonText>{content}</ButtonText>
      {iconType && renderIcon(IconSide.right, iconType, iconSide)}
      {loading && <LoadingIcon iconType='inProgress' />}
    </>
  );
};
