import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import * as Styled from './styled';

export const ChangingMuliBandsMultiplier: React.FC = observer(() => {
  const store = useMst();
  const muliBands = store.pages.muliBands;

  const topMultiplier = store.ui.modal.options.get('topMultiplier');
  const bottomMultiplier = store.ui.modal.options.get('bottomMultiplier');

  const proceed = async () => {
    await muliBands.deleteMuliBandOrdersAsync.run();
    muliBands.runInAction(() => {
      muliBands.topMultiplier = topMultiplier || muliBands.topMultiplier;
      muliBands.bottomMultiplier =
        bottomMultiplier || muliBands.bottomMultiplier;
    });
    store.ui.modal.close();
  };

  return (
    <Styled.Root>
      <Styled.Content>
        We are going to close all orders if you proceed
      </Styled.Content>
      <Styled.Body>
        <Styled.Cta
          size='medium'
          $isFailed
          onClick={() => store.ui.modal.close()}
        >
          Cancel
        </Styled.Cta>
        <Styled.Cta
          loading={muliBands.deleteMuliBandOrdersAsync.inProgress}
          disabled={muliBands.deleteMuliBandOrdersAsync.inProgress}
          size='medium'
          onClick={proceed}
        >
          Proceed
        </Styled.Cta>
      </Styled.Body>
    </Styled.Root>
  );
});
