import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface IProgress {
  size?: number;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
}

const defaultSize = 40;

export const Progress: React.FC<IProgress> = observer(
  ({ color = 'success', size = defaultSize }) => {
    return (
      <Styled.Root>
        <Styled.Progress size={size} color={color} />
      </Styled.Root>
    );
  }
);
