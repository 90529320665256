import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';

import { INotificationOut, useMst } from '@trader/store';

import { createNotification } from './createSnackbar';

export const NotificationContainer: React.FC = observer(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const store = useMst();

  const notifications = store.notifications.list;
  const isAllNotificationsCompleted = store.notifications.checkIsAllCompleted();

  const removeNotificationFromStore = useCallback(
    (uid: INotificationOut['uid']) => store.notifications.complete(uid),
    [store.notifications]
  );

  const showNotification = useCallback(
    (notification: INotificationOut) => {
      enqueueSnackbar(
        notification.message,
        createNotification({
          notification,
          removeNotificationFromStore,
          closeSnackbar,
        })
      );
    },
    [closeSnackbar, enqueueSnackbar, removeNotificationFromStore]
  );

  useEffect(() => {
    isAllNotificationsCompleted && store.notifications.clearAll();
  }, [isAllNotificationsCompleted]);

  useEffect(() => {
    notifications.length &&
      showNotification(notifications[notifications.length - 1]);
  }, [notifications, notifications.length, showNotification]);

  return null;
});
