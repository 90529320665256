import { types } from 'mobx-state-tree';

// TODO: (av) Set types.optional because of Mobx error.
//            Need to figure out how to avoid it.
//            These fields should not be optional:
//            equity: types.number,
//            balance: types.number,
//            startDayPl: types.number,
//            dailyPl: types.number,
//            pl: types.number,
//            freeMargin: types.number,
//            usedMargin: types.number,
//            availableForWithdrawal: types.number,
//            marginLevel: types.number,
//            maintenanceMargin: types.number.
export const portfolioModel = types.model('portfolioModel', {
  currency: types.optional(types.string, ''),
  currencySymbol: types.optional(types.string, ''),
  equity: types.optional(types.number, 0),
  balance: types.optional(types.number, 0),
  startDayPl: types.optional(types.number, 0),
  dailyPl: types.optional(types.number, 0),
  pl: types.optional(types.number, 0),
  freeMargin: types.optional(types.number, 0),
  usedMargin: types.optional(types.number, 0),
  availableForWithdrawal: types.optional(types.number, 0),
  marginLevel: types.optional(types.number, 0),
  maintenanceMargin: types.optional(types.number, 0),
  credit: types.optional(types.number, 0),
});

export const initialPortfolio = {
  currency: '',
  currencySymbol: '',
  equity: 0,
  balance: 0,
  startDayPl: 0,
  dailyPl: 0,
  pl: 0,
  freeMargin: 0,
  usedMargin: 0,
  availableForWithdrawal: 0,
  marginLevel: 0,
  maintenanceMargin: 0,
  credit: 0,
};

export const portfolio = types.optional(portfolioModel, initialPortfolio);
