/* eslint-disable no-magic-numbers */
import { TPlaceOrderSide } from '@trader/types';
import { getPipSizeNumber } from './pipSize';

const getDigitsAfterComma = (value: string | number) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, decimalPart] = String(value).replace(',', '.').split('.');
  if (decimalPart) {
    return getPipSizeNumber(decimalPart);
  }
  return 1;
};

export const getOrderBasePrice = (
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number
) => (side === 'Buy' ? bidPrice : askPrice);

export const getTradingAmplitude = (stopsLevel: number, pipSize: number) =>
  stopsLevel * +pipSize;

export const getStepBasedOnTickSize = (pipsSize: number, tickSize: number) =>
  tickSize || pipsSize;

export const getAmplitudeBasedOnTickSize = (
  amplitude: number,
  tickSize: number
) => (amplitude < tickSize ? tickSize : amplitude);

export const isValueTimesStep = (
  value: string | number,
  step: string | number
) => {
  const valueMantissa = getDigitsAfterComma(value);
  const stepMantissa = getDigitsAfterComma(step);

  const normalizePow =
    valueMantissa > stepMantissa ? valueMantissa : stepMantissa;
  const normalizeMultiplier = 10 ** normalizePow;

  const integerValue = +value * normalizeMultiplier;
  const integerStep = +step * normalizeMultiplier;

  return integerValue % integerStep === 0;
};
