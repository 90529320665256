import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { usePrefetchInformation, useWindowDimensions } from '@trader/hooks';
import { useMst } from '@trader/store';
import {
  LeftBar,
  Modals,
  TopBar,
  BottomBar,
  RightBar,
} from '@trader/containers';
import { breakpoints } from '@trader/themes';
import { cfdTabs } from '@trader/constants';

import * as Styled from './styled';

export const MainLayout: React.FC = observer(() => {
  const store = useMst();
  const { width } = useWindowDimensions();
  usePrefetchInformation();

  const leftBarWidth = store.ui.sidebar.leftBarWidth;
  const breakpointSm = breakpoints?.values?.sm as number;
  const shouldShowMainContent = width > breakpointSm;
  const shouldShowRightBar =
    !store.ui.sidebar.isRightBarHidden() && store.app.isBetaDesignEnabled();

  return (
    <React.Fragment>
      <Styled.Root>
        <TopBar />
        <Styled.Main $isSidebarOpen>
          <Styled.Body>
            <LeftBar tabsList={cfdTabs} />
            {shouldShowMainContent && (
              <Styled.Content
                $leftBarWidth={leftBarWidth}
                $showRightBar={shouldShowRightBar}
              >
                <Outlet />
              </Styled.Content>
            )}
            {shouldShowRightBar && <RightBar />}
          </Styled.Body>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </React.Fragment>
  );
});
