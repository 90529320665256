import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';

export const Root = styled(Wrapper)<{
  $width: number;
  $minWidth: number;
  $maxWidth: number;
}>`
  ${({ theme, $width, $minWidth, $maxWidth }) => css`
    min-width: ${$minWidth}px;
    max-width: ${$maxWidth}px;
    width: ${$width}px;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    flex-direction: column;
    height: inherit;
    flex-shrink: 0;
    margin-right: ${layoutMargin}px;
    position: relative; // Ensure the resize handle is positioned correctly
  `};
`;

export const ResizeHandler = styled.div`
  width: 5px;
  cursor: ew-resize;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;
